import React, { FunctionComponent } from 'react';
import { CheckBox } from 'react-native-elements';
import CheckBoxProps from "./Checkbox.interface";


const Go2cCheckBox: FunctionComponent<CheckBoxProps> = (props: CheckBoxProps) => {
    return (
        <CheckBox
            checked={props.cheched}
            onPress={() => {
                props.onChange(!props.cheched);
            }}
        />

    )
}

export default Go2cCheckBox