import { observable, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import { validateEmail, validatePassword } from '../components/calendar/utils';
import { ITranslationName } from 'go2c-shared';

export interface IProfileStoreMethods {
    setEmailInput(email: string): void;
    setUsernameInput(username: string): void;
    setPasswordInput(password: string): void;
    setPasswordRepeatInput(password: string): void;
    setValidateEmailFormSent(sent: boolean): void;
    setEmailSentResponse(success: boolean, message: ITranslationName): void;
}

class ProfileStore implements IProfileStoreMethods {
    @persist @observable email_input: string;
    @persist @observable email_validated: boolean;
    @persist @observable username_input: string;
    @observable password_input: string;
    @observable repeat_password_input: string;
    @observable password_validated: boolean;
    @observable passwords_match: boolean;
    @observable validate_email_errors: ITranslationName[] = [];
    @observable validate_password_errors: ITranslationName[] = [];
    @observable validate_repeat_password_errors: ITranslationName[] = [];
    @observable validate_email_sent: boolean = false;
    @observable validate_email_success: boolean = false;
    @observable validate_email_sent_message: ITranslationName;

    @action
    setEmailInput(email: string) {
        this.email_input = email;
        this.email_validated = validateEmail(this.email_input);
        this.validate_email_errors = this.email_validated ? [] : ['invalid_email'];
    }

    @action
    setUsernameInput(username: string) {
        this.username_input = username;
    }

    @action
    setPasswordInput(password: string) {
        this.password_input = password;
        this.validatePassword();
    }
    @action
    setPasswordRepeatInput(password: string) {
        this.repeat_password_input = password;
        this.passwordMatching();
    }
    @action
    validatePassword() {
        this.validate_password_errors = validatePassword(this.password_input);
        this.password_validated = this.validate_password_errors.length === 0;
    }
    @action
    passwordMatching() {
        this.passwords_match = this.password_input === this.repeat_password_input;
        this.validate_repeat_password_errors = this.passwords_match ? [] : ['please_repeat_password_exactly'];
    }

    @action setValidateEmailFormSent(sent: boolean) {
        this.validate_email_sent = sent;
    }

    @action setEmailSentResponse(success: boolean, message: ITranslationName) {
        this.validate_email_success = success;
        this.validate_email_sent_message = message;
        this.setValidateEmailFormSent(success);
    }
    @computed get getValidateEmailFormErrors() {
        return this.validate_email_errors
            .concat(this.validate_password_errors)
            .concat(this.validate_repeat_password_errors);
    }
}

export default new ProfileStore();
