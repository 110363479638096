import { StackNavigationProp } from "@react-navigation/stack";
import { NewItemNavigationParams } from "../../../pages/NewItem.page";
import { RouteProp } from "@react-navigation/native";
import { observer } from 'mobx-react';
import { Stores, useStore } from '../../../stores';
import MKColor from "../../../services/palette.service";
import React, { useState, useEffect, FunctionComponent } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Camera } from 'expo-camera';

const PendingView = () => (
    <View
        style={{
            flex: 1,
            backgroundColor: 'lightgreen',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Text>Waiting</Text>
    </View>
);



export interface TakeVideoProps {

}

type TakeVideoScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'phone_availability'>;

type TakeVideoScreenRouteProp = RouteProp<NewItemNavigationParams, 'phone_availability'>;

interface TakeVideoFormPage {
    navigation: TakeVideoScreenNavigationProp;
    route: TakeVideoScreenRouteProp;
}

const TakeVideo: FunctionComponent<TakeVideoFormPage> = (props: TakeVideoFormPage) => {
    const [hasPermission, setHasPermission] = useState(null);
    const [type, setType] = useState(Camera.Constants.Type.back);

    useEffect(() => {
        (async () => {
            const { status } = await Camera.requestPermissionsAsync();
            setHasPermission(status === 'granted');
        })();
    }, []);

    if (hasPermission === null) {
        return <View />;
    }
    if (hasPermission === false) {
        return <Text>No access to camera</Text>;
    }
    return (
        <View style={styles.container}>
            <Camera style={styles.preview} type={type}>
                <View style={styles.buttonsWrapper}>
                    <TouchableOpacity
                        style={styles.button}
                        onPress={() => {
                            setType(
                                type === Camera.Constants.Type.back
                                    ? Camera.Constants.Type.front
                                    : Camera.Constants.Type.back
                            );
                        }}>
                        <Text style={styles.text}> Flip </Text>
                    </TouchableOpacity>
                </View>
            </Camera>
        </View>
    );


}

export default observer(TakeVideo)

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: 250
    },
    preview: {
        flex: 1,
    },
    capture: {
        backgroundColor: MKColor.Grey,
        alignSelf: 'center',
        margin: 20,
        padding: 15,

    },
    buttonsWrapper: {
        flexDirection: 'row',
    },
    button: {
        backgroundColor: MKColor.Red,
        height: 30,
        width: 30,
    },
    recordButton: {

        borderRadius: 50,
    },
    stopButton: {

    },
    captureWrapper: {
        flex: 1
    },
    text:{

    }
});
