import React, { FunctionComponent } from 'react';
import { StackNavigationProp } from "@react-navigation/stack";
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { RouteProp } from "@react-navigation/native";
import { observer } from 'mobx-react';
import { StyleSheet, ScrollView, View, Text, ImageBackground, TouchableOpacity } from 'react-native';
import { Stores, useStore } from '../../stores';
// @ts-ignore
import { ICountry } from "go2c-shared";
import { getCountryImage } from '../CoutriesImages';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import MKColor from "../../services/palette.service";
import RippleButton from '../forms/RippleButton';


export interface SelectCountryPhoneProps {

}

type SelectCountryPhoneScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'select_country_phone'>;

type SelectCountryPhoneScreenRouteProp = RouteProp<NewItemNavigationParams, 'select_country_phone'>;

interface SelectCountryPhoneFormPage {
    navigation: SelectCountryPhoneScreenNavigationProp;
    route: SelectCountryPhoneScreenRouteProp;
}

const SelectCountryPhone: FunctionComponent<SelectCountryPhoneFormPage> = (props: SelectCountryPhoneFormPage) => {
    const store: Stores = useStore().store;
    return (
        <ScrollView style={styles.container}>
            {
                store.translations.all_countries.map((country: ICountry, i: number) => {
                    return (
                        <TouchableOpacity
                            style={styles.oneCountryContainer}
                            key={i}
                            onPress={() => {
                                store.translations.setUserCountry(country);
                                store.new_apartment.setcountryCode(country.country_code);
                                store.new_apartment.setPhone(store.new_apartment.raw_phone, store.translations.user_country.area_codes);
                                props.navigation.navigate('phone_availability');
                            }}
                        >
                            <View style={styles.checkMarkWrapper}>
                                {
                                    country.name === store.translations.user_country.name &&
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        color={MKColor.Green}
                                        size={30}
                                        style={styles.checkMark}
                                    />
                                }
                            </View>
                            <ImageBackground
                                source={getCountryImage(country.name)}
                                style={styles.countryImage}
                                resizeMode='contain'
                            ></ImageBackground>
                            <Text style={styles.textInLine}>+ {country.phone_code}</Text>
                            <Text style={[styles.textInLine, { flex: 4 }]}>{store.translations.translations[country.name]}</Text>

                        </TouchableOpacity>
                    )
                })
            }
        </ScrollView>
    )
}

export default observer(SelectCountryPhone);


const styles = StyleSheet.create({
    container: {

    },
    countryImage: {
        height: 30,
        width: 30,
        flex: 1,
    },
    oneCountryContainer: {
        flexDirection: 'row',
        height: 50,
        margin: 10,
        borderBottomColor: MKColor.Grey,
        borderBottomWidth: 1,
    },
    checkMark: {
        flex: 1,
    },
    textInLine: {
        // lineHeight: 30,
        // marginStart: 10,
        flex: 1,
        color:'black'

    },
    checkMarkWrapper: {
        flex: 1,
    }
})