import { faHome, faMapMarkerExclamation, faPhone } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { IItem } from 'go2c-shared';
import React, { FunctionComponent } from "react";
import { Image, Text, TouchableOpacity, View } from "react-native";
import { buildImagePath, getFileType } from '../../services/path.service';
import { stores } from "../../stores";
import Price from "../Price";
import styles from "./styles";

interface MarkerCalloutProps {
    open: boolean;
    item: IItem;
    onpress?: () => void;
}

const MarkerCallout: FunctionComponent<MarkerCalloutProps> = (props: MarkerCalloutProps) => {
    const item = props.item;
    return (
        <TouchableOpacity style={[
            styles.callout,
            { display: props.open ? 'flex' : 'none' }
        ]}
            onPress={() => {
                if (props.onpress) {
                    props.onpress()
                }
            }}
        >
            <View style={{
                ...styles.calloutView,
            }}>
                <Text style={styles.calloutTitle}>{item.place.formatted_address}</Text>
                <View style={styles.priceWrapper}>
                    <Text style={styles.priceText}>{stores.translations.translations.price} : </Text>
                    <Price price={item.price} style={styles.priceText} />
                </View>
                <View style={styles.availabilityWrapper}>
                    <Text style={styles.availabilityText}>{stores.translations.translations.availability}</Text>
                    {
                        item.available_for_phone &&
                        <View style={[styles.availabilityIconWrapper, styles.availabilityPhoneWrapper]}>
                            <FontAwesomeIcon
                                icon={faPhone}
                                size={5}
                                color={'#343deb'}
                                style={styles.markerIconSmall}
                            />
                        </View>

                    }
                    {
                        item.available_for_at_the_location &&
                        <View style={[styles.availabilityIconWrapper, styles.availabilityLocationWrapper]}>
                            <FontAwesomeIcon
                                icon={faMapMarkerExclamation}
                                size={5}
                                color={'#d4b804'}
                                style={styles.markerIconSmall}
                            />
                        </View>

                    }
                    {
                        item.available_for_home &&
                        <View style={[styles.availabilityIconWrapper, styles.availabilityHomeWrapper]}>
                            <FontAwesomeIcon
                                icon={faHome}
                                size={5}
                                color={'#04d43f'}
                                style={[styles.markerIconSmall, styles.homeMarkerIcon]}
                            />
                        </View>

                    }
                </View>
                {
                    item.images && item.images.length > 0 &&
                    <View style={styles.imagesWrapperWeb}>
                        {

                            item.images.map((image: string, i: number) => {
                                const isImage = getFileType(image) === 'image';
                                const source = buildImagePath(image, stores.items.images_api, 400, 350) as unknown as string;
                                return (
                                    isImage && i < 2 &&
                                    <Image
                                        key={i}
                                        source={{ uri: source }}
                                        style={styles.imageInCalloutWeb}
                                    />
                                )
                            })
                        }
                    </View>

                }
            </View>
        </TouchableOpacity>
    )
}

export default MarkerCallout;