import React, { useState, FunctionComponent, useEffect } from 'react';
import { observer } from "mobx-react"
import { View, Text, StyleSheet, ScrollView, TouchableOpacity, Platform, Linking } from "react-native";
import { Stores, useStore } from '../../stores';
import SwiperComponent from '../swiper/Swiper';
import { IGetAvailabilitiesResponse, IPhone } from 'go2c-shared';
import Request from '../../services/axios.service';
import Price from '../Price';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPhone, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { CurrentItemNavigationParams } from '../../pages/Map.page';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp, useRoute } from '@react-navigation/native';
import MKColor from "../../services/palette.service";
import RippleButton from '../forms/RippleButton';

type CurrentItemItemNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'item'>;

type CurrentItemItemRouteProp = RouteProp<CurrentItemNavigationParams, 'item'>;


interface CurrentItemItemParams {
    navigation: CurrentItemItemNavigationProp;
    route: CurrentItemItemRouteProp;
}

const Item: FunctionComponent<CurrentItemItemParams> = (props: CurrentItemItemParams) => {
    const stores: Stores = useStore().store;
    const [phone, setPhone] = useState<boolean>(false);
    const route = useRoute();

    const getAvailability = async () => {
        stores.items.setCalendarLoading(true);
        props.navigation.navigate("availability", route.params);

        const fromRequest: IGetAvailabilitiesResponse = await Request({
            method: "GET",
            url: "items/" + stores.items.current_item._id + '/availabilities'
        });
        stores.items.setAvailabilities(fromRequest.availabilities);
        if (fromRequest.has_meeting === true) {
            stores.items.setHasMeetingWithCurrentItem(fromRequest.meeting);
        }
        stores.items.setCalendarLoading(false);
    }

    const getPhone = async () => {
        const phone = await Request({
            method: 'GET',
            url: "items/" + stores.items.current_item._id + '/phone'
        });
        setPhone(true);
        stores.items.setCurrentPhone(phone.phone as IPhone);

    }

    const callItem = async () => {
        const phoneData: IPhone = stores.items.current_phone;
        const realPhone: string = phoneData.phone.indexOf('-') > -1 ? phoneData.phone.split('-')[1] : phoneData.phone;
        const completePhone: string = phoneData.country_code + phoneData.area_code + realPhone;
        Linking.openURL(`tel:${completePhone}`);
    }

    const getItem = async () => {
        const fromRequestItem = await Request({
            method: 'POST',
            url: "items/filter",
            data:{
                _id:route.params.item_id
            }
        });
        if (fromRequestItem.success) {
            stores.items.setCurrentItem(fromRequestItem.items[0])
            
        } else {
            
        }
    }
    useEffect(() => {
        if (!stores.items.current_item || stores.items.current_item._id !== route.params.item_id) {
            getItem();
        }
    }, [stores])

    return (
        <>
            <ScrollView style={styles.itemContainer}>
                {
                    stores.items.current_item &&
                    <>

                        <SwiperComponent
                            slides={stores.items.images}
                        />

                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.location}</Text>
                            <Text style={styles.propValue}>{stores.items.current_item.raw_address}</Text>
                        </View>
                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.price}</Text>
                            <Price
                                price={stores.items.current_item.price}
                                style={styles.priceStyle}
                            />
                        </View>
                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.floor}</Text>
                            <Text style={styles.propValue}>{stores.items.current_item.floor}</Text>
                        </View>
                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.total_floors_in_building}</Text>
                            <Text style={styles.propValue}>{stores.items.current_item.total_floors_in_building}</Text>
                        </View>

                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.description}</Text>
                            <Text style={styles.propValue}>{stores.items.current_item.description}</Text>
                        </View>

                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.furniture}</Text>
                            <Text style={styles.propValue}>{stores.items.current_item.furniture}</Text>
                        </View>

                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.size}</Text>
                            <Price
                                price={stores.items.current_item.size}
                                style={{ ...styles.priceStyle, ...{ marginEnd: 10 } }}
                                endText={stores.translations.translations.square_meters}
                            />
                        </View>
                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.rooms}</Text>
                            <Text style={styles.propValue}>{stores.items.current_item.rooms}</Text>
                        </View>
                        <View style={styles.oneProp}>
                            <Text style={styles.propLabel}>{stores.translations.translations.livingroom}</Text>
                            {
                                stores.items.current_item.has_livingroom ?
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        color={MKColor.Green}
                                        size={30}
                                        style={styles.checkMark}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        color={MKColor.Red}
                                        size={30}
                                        style={styles.checkMark}
                                    />
                            }
                        </View>
                        <View style={{ ...styles.oneProp, ...styles.lastProp }}>
                            <Text style={styles.propLabel}>{stores.translations.translations.air_conditioning}</Text>
                            {
                                stores.items.current_item.has_air_conditioning ?
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        color={MKColor.Green}
                                        size={30}
                                        style={styles.checkMark}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        color={MKColor.Red}
                                        size={30}
                                        style={styles.checkMark}
                                    />
                            }
                        </View>

                        {
                            stores.items.current_item.has_phone === true && stores.items.current_item.available_for_phone && !phone ?

                                <RippleButton
                                    style={styles.getPhoneButton}
                                    onPress={getPhone}
                                >
                                    <Text style={styles.getPhoneText}>{stores.translations.translations.get_phone}</Text>
                                </RippleButton>
                                : phone ?
                                    <RippleButton
                                        style={styles.getPhoneButton}
                                        onPress={callItem}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPhone}
                                            color='white'
                                            style={styles.phoneIcon}
                                        />
                                        <Text style={styles.callText}>{stores.translations.translations.call}</Text>
                                    </RippleButton>
                                    :
                                    <></>
                        }
                    </>
                }
            </ScrollView>
            <TouchableOpacity style={styles.checkAvailability} onPress={getAvailability}>
                <Text style={styles.checkAvailabilityText}>{stores.translations.translations.check_availability}</Text>
            </TouchableOpacity>
        </>
    )
}
export default observer(Item);

const styles = StyleSheet.create({
    carousel: {
        flex: 1
    },
    oneImageContainer: {
        flex: 1
    },
    itemContainer: {
        paddingBottom: 150,
        marginBottom: 50,
        flex: 1
    },
    oneProp: {
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexDirection: "row",
        marginBottom: 15
    },
    propLabel: {
        fontSize: 20,
        alignSelf: 'stretch',
        flex: 1,
    },
    propValue: {
        fontSize: 20,
        flex: 1,
    },
    priceStyle: {

        fontSize: 20,
        alignSelf: 'flex-start'
    },
    checkAvailability: {
        backgroundColor: 'rgba(219, 37, 86, 0.5)',
        alignItems: 'center',
        textAlign: 'center',
        paddingTop: 15,
        paddingBottom: Platform.OS === 'ios' ? 45 : 15,
        height: 50,
        width: '100%',
        position: 'absolute',
        bottom: 0,

    },
    checkAvailabilityText: {
        color: 'white',
        paddingBottom: Platform.OS === 'ios' ? 30 : 0

    },
    getPhoneButton: {
        backgroundColor: MKColor.Green,
        textAlign: 'center',
        height: 80,
        alignItems: 'center',
        width: 80,
        position: 'absolute',
        bottom: 10,
        marginTop: 50,
        borderRadius: 50,
    },
    callButton: {

    },
    getPhoneText: {
        color: 'white',
        lineHeight: 80,
    },
    phoneIcon: {
        marginTop: 25
    },
    callText: {
        color: 'white'
    },
    checkMark: {

    },
    doubleProp: {
        flex: 1,
        flexDirection: 'row'
    },
    lastProp: {
        marginBottom: 100
    }
})