import { RouteProp } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { ITranslationDictionary } from 'go2c-shared';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useState } from 'react';
import { ScrollView, StyleSheet, Switch, Text, View } from 'react-native';
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { Stores, useStore } from '../../stores';
import BulkAvailability from '../calendar/BulkAvailability';
import MonthCalendar from '../calendar/MonthCalendar';
import CheckBox from "../forms/checkbox/CheckBox";
import NextButton from '../forms/NextButton';
import Seperator from '../forms/Seperator';
import TextField from '../forms/text_field/TextField';
import PhonePicker from './PhonePicker';
import { styles as newItremStyles } from './styles';



export interface PhoneAvailabilityProps {

}

type PhoneAvailabilityScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'phone_availability'>;

type PhoneAvailabilityScreenRouteProp = RouteProp<NewItemNavigationParams, 'phone_availability'>;

interface PhoneAvailabilityFormPage {
    navigation: PhoneAvailabilityScreenNavigationProp;
    route: PhoneAvailabilityScreenRouteProp;
}

const PhoneAvailability: FunctionComponent<PhoneAvailabilityFormPage> = (props: PhoneAvailabilityFormPage) => {
    const store: Stores = useStore().store;
    const translations: ITranslationDictionary = store.translations.translations;
    const [canContinue, setCanContinue] = useState<boolean>(false);

    const updateCanContinue = () => {
        if (store.new_apartment.email_valid === true) {
            if (store.new_apartment.do_not_show_phone === true) {
                setCanContinue(true);
            } else if (store.new_apartment.phone_validated === true && store.new_apartment.country_code_selected) {
                setCanContinue(true);
            } else {
                setCanContinue(false);
            }
        } else {
            setCanContinue(false)
        }
    }

    return (
        <ScrollView style={newItremStyles.page}>
            <TextField
                label={translations.email}
                style={styles.emailInput}
                // autoCompleteType="email"
                value={store.new_apartment.email}

                // tintColor={store.new_apartment.email_valid === true ? 'blue' : 'red'}
                onChangeText={(text: string) => {
                    store.new_apartment.setEmail(text);
                    updateCanContinue();
                }}

            />
            <Seperator />
            <Text style={styles.label}>{translations.phone}</Text>
            <Seperator />

            <View style={[styles.checkBoxContainer, { marginBottom: 15 }]}>
                <Text style={styles.checkBoxText}>{translations.do_not_show_my_phone}</Text>
                <Switch
                    style={styles.checkBox}
                    value={store.new_apartment.do_not_show_phone}
                    onValueChange={(val: boolean) => {
                        store.new_apartment.setBooleanFormByKey(val, 'do_not_show_phone');
                        updateCanContinue();
                    }}
                />
            </View>

            <Seperator />
            {
                store.new_apartment.do_not_show_phone === false &&
                <>
                    <PhonePicker
                        onValueChange={(val) => {
                            store.new_apartment.setPhone(val, store.translations.user_country.area_codes);
                            updateCanContinue();
                        }}
                        phone={store.new_apartment.raw_phone}
                    />
                    <Seperator />



                    <View style={styles.checkBoxContainer}>
                        <Text style={styles.checkBoxText}>{translations.always_show_my_phone}</Text>

                        <CheckBox
                            style={styles.checkBox}
                            checked={store.new_apartment.always_show_phone}
                            onChange={() => {
                                store.new_apartment.setBooleanFormByKey(!store.new_apartment.always_show_phone, 'always_show_phone');
                                updateCanContinue();
                            }}
                        />
                    </View>

                    <View style={styles.checkBoxContainer}>
                        <Text style={styles.checkBoxText}>{translations.add_availability_manually}</Text>

                        <CheckBox
                            style={styles.checkBox}
                            checked={!store.new_apartment.always_show_phone}
                            onChange={() => {
                                store.new_apartment.setBooleanFormByKey(!store.new_apartment.always_show_phone, 'always_show_phone');
                                updateCanContinue();

                            }}
                        />
                    </View>
                    {
                        !store.new_apartment.always_show_phone &&
                        <View>

                            <BulkAvailability
                                minimumTime={store.new_apartment.day_starts_at}
                                maximunTime={store.new_apartment.day_ends_at}
                                type={"availability-phone"}
                                title={translations.phone}
                            />
                            <View style={{ height: 550 }}>
                                <MonthCalendar
                                    num_of_days_in_week={7}
                                    selected_date={new Date()}
                                    week_starts_at={store.user.week_starts_at}
                                    onSelect={(date: Date) => {
                                        store.new_apartment.setSelectedDay(date);
                                        props.navigation.navigate('availability_day', { event_types: ['phone'] });
                                    }}
                                    mappedEvents={store.new_apartment.mapped_events}
                                    event_types={['phone']}
                                />
                            </View>
                        </View>
                    }

                </>

            }

            <NextButton
                onNext={() => {
                    if (store.new_apartment.always_show_phone === true) {
                        store.new_apartment.addAlwaysAvailable(100, 'phone', store.translations.translations.phone, store.translations.translations.i_am_available_for_phone);
                    }
                    props.navigation.navigate('new_item');
                }}
                active={canContinue}
                title={translations.continue}
            />
        </ScrollView>
    )
}

export default observer(PhoneAvailability);


const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginBottom: 60
    },
    checkBoxContainer: {
        flex: 1,
        flexDirection: 'row',
        margin: 10,
        justifyContent: 'space-between',
        marginTop: 40,
    },
    checkBoxText: {
        fontSize: 23,
        alignSelf: 'baseline'
    },

    checkBox: {
        height: 30,
        width: 30,
        alignSelf: 'center',

    },
    buttonContainer: {
        position: 'absolute',
        bottom: 0,
    },
    label: {
        fontSize: 20,
        alignSelf: 'stretch',
        flex: 1,
        textAlignVertical: 'center'
    },

    emailInput: {
    },
})