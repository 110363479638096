import React, { FunctionComponent } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { View } from 'react-native';
import Day, { TimeInDay, DayProps } from "./Day";
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { observer } from "mobx-react";
import { Stores, useStore } from "../../stores";


export interface AvailabilityDayParams extends DayProps {
}


type AvailabilityDayNavigationProp = StackNavigationProp<NewItemNavigationParams, 'availability_day'>;

type AvailabilityDayRouteProp = RouteProp<NewItemNavigationParams, 'availability_day'>;

interface AvailabilityDayProps {
    navigation: AvailabilityDayNavigationProp;
    route: AvailabilityDayRouteProp;
}


const AvailabilityDay: FunctionComponent<AvailabilityDayProps> = function (props: AvailabilityDayProps) {
    const store: Stores = useStore().store;

    return (
        <View style={{ flex: 1 }}>
            <Day
                events={store.new_apartment.selected_date_events}
                start={props.route.params.start}
                end={props.route.params.end}
                isBig={true}
                timeClick={(fromClick: TimeInDay) => {
                    store.new_apartment.setManulalAvailabiliyData(fromClick);
                    props.navigation.navigate('add_availability_manual');
                }}
                event_types={props.route.params.event_types}
            />
        </View>
    )
}
export default observer(AvailabilityDay);