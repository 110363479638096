import React, { FunctionComponent, useState, Ref } from 'react';
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import MKColor from "../services/palette.service";
import { INotification } from 'go2c-shared';
import moment from 'moment-timezone';
import Request from '../services/axios.service';
import { observer } from 'mobx-react';
import { Stores, useStore } from '../stores';
import Collapsible from 'react-native-collapsible';
import MonthCalendar from './calendar/MonthCalendar';
import { DayProps } from './calendar/Day';
import { CurrentItemNavigationParams } from '../pages/Map.page';
import RippleButton from './forms/RippleButton';

export interface AcceptMeetingParams extends DayProps {
    meeting?: INotification;

}
type MyCalendarScreenNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'accept_meeting'>;

type MyCalendarScreenRouteProp = RouteProp<CurrentItemNavigationParams, 'accept_meeting'>;

interface AcceptMeetingProps {
    navigation: MyCalendarScreenNavigationProp;
    route: MyCalendarScreenRouteProp;
}

const AcceptMeeting: FunctionComponent<AcceptMeetingProps> = function (props: AcceptMeetingProps) {
    const store: Stores = useStore().store;
    const [calendarCollapsed, setCalendarCollapsed] = useState<boolean>(true);
    const [scrollViewRef, setScrollViewRef] = useState(null);
    const [calendarPosition, setCalendarPosition] = useState(null);


    const approveMeeting = async () => {
        const fromRequest = await Request({
            method: "POST",
            url: 'events/' + props.route.params.meeting.event._id + '/approve/' + props.route.params.meeting._id
        });
        if (fromRequest.success) {
            store.dialog.openPopup(store.translations.translations.invitation_sent, store.translations.translations.invitation_sent_body);
            // @ts-ignore
            props.navigation.navigate('my_calendar', { screen: 'my_calendar_component' });
        }
    }

    const declineMeeting = async () => {
        const fromRequest = await Request({
            method: "POST",
            url: 'events/' + props.route.params.meeting.event._id + '/decline'
        });
        if (fromRequest.success) {
            store.dialog.openPopup(store.translations.translations.invitation_sent, store.translations.translations.invitation_sent_body);

            // @ts-ignore
            props.navigation.navigate('my_calendar', { screen: 'my_calendar_component' });
        }
    }

    const reschduleMeeting = () => {
        setCalendarCollapsed(false);
        setTimeout(() => {
            scrollViewRef.scrollTo({
                y: calendarPosition,
                x: 0,
                animated: true
            })
        }, 1500);
    }

    return (
        <ScrollView
            ref={(ref) => {
                setScrollViewRef(ref);
            }}
        >
            <Text style={styles.text}>{store.translations.translations.approve_meeting_with}</Text>
            <Text style={styles.text}>{props.route.params.meeting.initiator.username || "Guest"}</Text>
            <Text style={styles.text}>{store.translations.translations.at_time}</Text>
            <Text style={styles.text}>{moment(new Date(props.route.params.meeting.event.time_started)).format('YYYY/MM/DD HH:mm')}</Text>
            <View style={styles.buttonsWrapper}>
                <RippleButton
                    style={styles.button}
                    onPress={approveMeeting}

                >
                    <Text style={styles.buttonText}>Approve</Text>
                </RippleButton>
                <RippleButton style={{ ...styles.button, backgroundColor: MKColor.Red }} onPress={declineMeeting}>
                    <Text style={styles.buttonText}>Decline</Text>
                </RippleButton>
                <RippleButton style={{ ...styles.button, backgroundColor: MKColor.Blue }} onPress={reschduleMeeting}>
                    <Text style={styles.buttonText}>Reschdule</Text>
                </RippleButton>
            </View>
            <View
                onLayout={event => {
                    const layout = event.nativeEvent.layout;
                    setCalendarPosition(layout.y);
                }}
            >
                <Collapsible
                    collapsed={calendarCollapsed}
                    style={styles.calendar}
                    duration={300}

                >


                    <MonthCalendar
                        selected_date={new Date(props.route.params.meeting.event.time_started)}
                        onSelect={(fromSelect: any) => {
                            const newEvent = {
                                time_started: String(new Date(fromSelect)),
                                time_ended: String(moment(new Date(fromSelect)).add(1, 'hours').toDate()),
                                title: props.route.params.meeting.event.title,
                                description: props.route.params.meeting.event.description,
                                type: props.route.params.meeting.event.type,
                                item: props.route.params.meeting.event.item,
                            }
                            // @ts-ignore
                            props.navigation.navigate("reschedule_meeting_day", {
                                meeting: store.user.meeting_to_approve,
                                start: 6,
                                end: 24,
                            });
                        }}
                    />

                </Collapsible>
            </View>
        </ScrollView>
    )
}
export default observer(AcceptMeeting);

const styles = StyleSheet.create({
    buttonsWrapper: {
        flexDirection: 'row',
        marginTop: 50,
    },
    button: {
        flex: 1,
        height: 50,
        backgroundColor: MKColor.Green,
        margin: 3,
        borderRadius: 10,
    },
    buttonText: {
        color: 'white',
        flex: 1,
        textAlign: 'center',
        fontSize: 20,
        lineHeight: 30,
        alignSelf: 'center',
    },
    calendar: {
    },
    text: {
        fontSize: 22,
        textAlign: 'center',
        marginTop: 25,
    }
})