import React, { FunctionComponent, useState } from 'react';
import { observer } from "mobx-react"
import { StackNavigationProp } from '@react-navigation/stack';
import { NewItemNavigationParams } from '../../pages/NewItem.page';
import { RouteProp } from '@react-navigation/native';
import { View, StyleSheet, Text, ScrollView, Platform, Switch, TextInput, TouchableOpacity } from 'react-native';
import RNPickerSelect from "../forms/picker/Picker";
import { Stores, useStore } from '../../stores';
// @ts-ignore
import { ITranslationDictionary } from 'go2c-shared';
import TextField from '../forms/text_field/TextField';
import { toJS } from 'mobx';
import CollapsibleTitle from '../CollapsibleTitle';
import { SafeAreaView } from 'react-native-safe-area-context';
import NextButton from '../forms/NextButton';
import { styles as newItemStyles } from './styles';
import MKColor from "../../services/palette.service";
import RangeSlider from '../forms/range_slider/RangeSlider';
type NewItemScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'new_item'>;

type NewItemScreenRouteProp = RouteProp<NewItemNavigationParams, 'new_item'>;

interface INewItemFormPage {
    navigation: NewItemScreenNavigationProp;
    route: NewItemScreenRouteProp;
}

const NewItemForm: FunctionComponent<INewItemFormPage> = (props: INewItemFormPage) => {
    const store: Stores = useStore().store;
    const translations: ITranslationDictionary = toJS(store.translations.translations);
    const rentType = ['yearly', 'monthly', 'weekly', 'daily'];
    const canContinue: boolean =
        store.new_apartment.address_selected === true &&
        store.new_apartment.price !== null &&
        store.new_apartment.size !== null &&
        store.new_apartment.floor !== null;



    const updateForm = (val: string, type: string): void => {
        store.new_apartment.setFormByKey(val, type);
    }
    return (
        <SafeAreaView>
            <ScrollView contentContainerStyle={newItemStyles.page}>

                <View style={styles.textWithLabel}>
                    <Text style={styles.label}>{translations.address}</Text>
                    <TouchableOpacity
                        onPress={() => { props.navigation.navigate('select_address') }}
                        style={{ ...styles.selectAddressButton, backgroundColor: store.new_apartment.address_selected ? "#d5f2d9" : '#dbdbdb' }}
                    >
                        <Text style={styles.selectAddressButtonText}>{store.new_apartment.address_selected ? store.new_apartment.raw_address : translations.select_address + '...'}</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.seperator}></View>



                <View style={styles.textWithLabel}>
                    <View style={styles.priceInput}>
                        <TextField
                            keyboardType="numeric"
                            style={styles.priceInput}

                            label={translations.price}
                            value={store.new_apartment.price}
                            onChangeText={(text: string) => updateForm(text, 'price')}
                        />
                    </View>
                    <Text style={styles.currencySymbol}>{store.user.user.currency.symbol}</Text>

                    {
                        store.new_apartment.deal_type === 'rent' &&
                        <View style={styles.rentTypePicker}>
                            <RNPickerSelect
                                items={rentType.map(x => { return { label: translations[x], value: x } })}
                                value={store.new_apartment.rent_type}
                                placeholder={{ label: translations.deal_type + "...", value: null }}
                                pickerProps={{ mode: 'dropdown' }}
                                onValueChange={(val: string) => { updateForm(val, 'rent_type'); }}
                            // useNativeAndroidPickerStyle={false}

                            />

                        </View>
                    }
                </View>

                <View style={styles.seperator}></View>



                <Text style={styles.roomsLabel}>{translations.rooms}</Text>

                <View style={[styles.textWithLabel, { justifyContent: 'flex-start', alignContent: 'flex-start', alignItems: 'flex-start' }]}>
                    <Text style={styles.text}>{translations.total_rooms}</Text>
                    <Text style={[styles.text, styles.roomNumber]}>{store.new_apartment.rooms}</Text>
                    <Text style={styles.text}>{translations.bedrooms}</Text>
                    <Text style={[styles.text, styles.roomNumber]}>{store.new_apartment.bedrooms}</Text>
                </View>

                <View>
                    <RangeSlider
                        style={styles.roomsSlider}
                        rangeEnabled={false}
                        gravity={'center'}
                        step={1}
                        min={2}
                        initialLowValue={2}
                        max={24}
                        selectionColor={MKColor.Blue}
                        blankColor={MKColor.Orange}
                        onValueChanged={(low: number, high: number, fromUser: boolean) => {
                            store.new_apartment.setRooms(low / 2);
                        }}
                        textFormat={String(store.new_apartment.rooms)}
                    />
                </View>
                <View style={styles.livingromWrapper}>

                    <Text style={styles.livingromText}>{translations.livingroom}</Text>
                    <Switch
                        onValueChange={(checked: boolean) => { store.new_apartment.setHasLivingroom(checked) }}
                        value={store.new_apartment.has_livingroom}
                        style={styles.livingromSwitch}
                    />
                </View>

                <View style={styles.seperator}></View>

                <View style={styles.textWithLabel}>
                    <Text style={styles.label}>{translations.size}</Text>
                </View>
                <View style={styles.textWithLabel}>
                    <View style={{ flex: 1, marginEnd: 10, marginBottom: 16, marginTop:-30 }}>
                        <TextField
                            keyboardType="numeric"
                            label={translations.size_in_square_meters}
                            onChangeText={(text: string) => updateForm(text, 'size')}
                            value={store.new_apartment.size}
                        style={{
                            marginTop:-50,
                        }}
                        />
                    </View>
                    <Text style={styles.label}>{translations.square_meters}</Text>
                </View>


                <View style={styles.seperator}></View>

                <View style={styles.textWithLabel}>
                    <Text style={styles.label}>{translations.floor}</Text>
                </View>

                <View style={{ ...styles.textWithLabel, marginTop: 30 }}>
                    <View style={{ flex: 1, marginEnd: 10 }}>
                        <TextField
                            keyboardType="numeric"
                            label={translations.floor}
                            onChangeText={(text: string) => updateForm(text, 'floor')}
                            value={store.new_apartment.floor}

                        />
                    </View>
                    <View style={{ flex: 1, marginEnd: 10 }}>
                        <TextField
                            keyboardType="numeric"
                            label={translations.total_floors_in_building}
                            value={store.new_apartment.total_floors_in_building}
                            onChangeText={(text: string) => updateForm(text, 'total_floors_in_building')}

                        />
                    </View>
                </View>

                <View style={styles.seperator}></View>

                <CollapsibleTitle title={store.translations.translations.description}>
                    <TextField
                        style={styles.textarea}
                        multiline={true}
                        numberOfLines={4}
                        label={store.translations.translations.description}
                        value={store.new_apartment.description}
                        onChangeText={(val: string) => updateForm(val, 'description')}
                    />
                </CollapsibleTitle>

                <View style={styles.seperator}></View>

                <CollapsibleTitle
                    title={store.translations.translations.furniture}
                    add_seperator
                >
                    <TextField
                        style={styles.textarea}
                        label={store.translations.translations.furniture}
                        value={store.new_apartment.furniture}
                        onChangeText={(val: string) => updateForm(val, 'furniture')}
                    />
                </CollapsibleTitle>

                <View style={styles.seperator}></View>


                <View style={styles.textWithLabel}>
                    <Text style={styles.label}>{translations.air_conditioning}</Text>
                    <Switch
                        onValueChange={(checked: boolean) => { store.new_apartment.setHasAirConditioning(checked) }}
                        value={store.new_apartment.has_air_conditioning}
                        style={styles.livingromSwitch}
                    />

                </View>
                <NextButton
                    onNext={() => {
                        props.navigation.navigate('showing_the_place_availability');
                    }}
                    active={canContinue}
                    title={translations.continue}
                />
            </ScrollView>


        </SafeAreaView>
    )
}

export default observer(NewItemForm);



export const styles = StyleSheet.create({
    container: {
        marginLeft: 15,
        marginRight: 15,
    },
    textWithLabel: {
        flexWrap: "nowrap",
        alignItems: "stretch",
        flexDirection: "row",
        paddingBottom: 15,
    },
    label: {
        fontSize: 20,
        alignSelf: 'stretch',
        flex: 1,
        textAlignVertical: 'center'
    },
    input: {
        fontSize: 20,
        lineHeight: 20,
        flex: 1,
        borderWidth: 1,
        borderColor: "gray",
        borderRadius: 15,
        backgroundColor: 'white',
    },
    pageLabel: {
        fontSize: 24,
        flex: 1,
        textAlign: "center",
        fontWeight: 'bold',
        marginTop: 25,
    },
    footerButtonsWrapper: {
        flex: 1,
        marginTop: Platform.OS === 'ios' ? 40 : 20,
        marginLeft: 10,
        marginRight: 10,
    },
    footerButton: {
        padding: 15,
        backgroundColor: '#5ba7fc',
    },
    footerButtonText: {
        alignSelf: 'center',
        fontSize: 20,
        color: 'white'
    },
    currencySymbol: {
        flex: 1,
        textAlign: 'center',
        fontSize: 18,
        alignSelf: 'center',
        marginTop: 18,
    },
    rentTypePicker: {
        flex: 2,
        alignSelf: 'center',
        marginTop: 16,
    },
    priceInput: {
        flex: 2,
        alignSelf: 'center',
        // marginBottom: 15,
    },
    seperator: {
        flex: 1,
        height: 1,
        backgroundColor: '#bdbdbd',
        marginTop: 15,
        marginBottom: 15,
    },
    emailInput: {
        marginBottom: 35
    },
    roomsSlider: {
        width: 280,
        height: 80,
        marginTop: -20,
        alignSelf: 'center'
    },
    livingromWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
    },
    livingromText: {
        fontSize: 20,
        alignSelf: 'baseline',
        lineHeight: 50,
    },
    livingromSwitch: {
        alignSelf: 'center',
        height: 50
    },
    text: {
        fontSize: 16,
        flex: 1,
    },
    roomsLabel: {
        fontSize: 22,
        marginBottom: 20,
    },
    textarea: {

    },
    roomNumber: {
        textAlign: 'justify',
        fontSize: 24,
        marginTop: -8,
        marginStart: 15,
    },
    selectAddressButton: {
        textAlign: 'center',
        flex: 3,
        margin: 0,
        padding: 10,
        borderRadius: 15,
    },
    selectAddressButtonText: {
        flex: 1,
        textAlign: 'center',
    }
});