import React, { FunctionComponent } from "react";
// @ts-ignore
import { ICalendarEvent, INotification } from "go2c-shared";
import { View, Text, StyleSheet, TouchableOpacity, GestureResponderEvent } from "react-native";
import { toJS } from "mobx";
import moment, { Moment } from "moment";
import { getEventByName, IEventType } from "../../services/events.service";
import { observer } from "mobx-react";
import { useStore, Stores } from "../../stores";
import MKColor from "../../services/palette.service";

// var ReactNativeComponentTree = require('react-native/Libraries/Renderer/shims/');


export interface DayProps {
    events?: ICalendarEvent[];
    start?: number;
    end?: number;
    isBig?: boolean;
    excludeEmptyColumns?: boolean;
    meeting?: INotification;
    timeClick?: (params: TimeInDay) => void;
    event_types?: string[];
}

export interface TimeInDay {
    hours: number;
    minutes: number;
    type: string;
    time_started?: Date;
    time_ended?: Date;
    is_blank?: boolean;
    event: ICalendarEvent;
}


interface HandleBlockClickParams {
    elemStart: number;
    elemEnd: number;
    pressEvent: any;
    type: string;
    isBlank?: boolean;
    event: ICalendarEvent;
}

const Day: FunctionComponent<DayProps> = function (props: DayProps) {
    const store: Stores = useStore().store;
    let targetDimensions = {};
    const types = props.event_types || ["phone", "at_the_location", "home", "meeting_request-location"];
    const handleBlockClick = (params: HandleBlockClickParams) => {
        let tar: any;
        if (!targetDimensions[params.pressEvent.target]) {
            if (targetDimensions[params.pressEvent._targetInst.stateNode._nativeTag]) {
                tar = targetDimensions[params.pressEvent._targetInst.stateNode._nativeTag];
            } else {
                console.log('====================================');
                console.log('Did not find target');
                console.log('====================================');
                return;
            }
        } else {
            tar = targetDimensions[params.pressEvent.target]
        }
        const percentage: number = params.pressEvent.nativeEvent.locationY / tar.height;
        const clickTime = (params.elemEnd - params.elemStart) * percentage + params.elemStart;
        const hours: number = Number(String(clickTime).split('.')[0]);
        const minutes: number = String(clickTime).indexOf('.') > -1 ? Math.floor(60 * Number('0.' + String(clickTime).split('.')[1])) : 0;
        if (props.timeClick) {
            props.timeClick({
                hours,
                minutes,
                type: params.type,
                time_started: tar.time_started,
                time_ended: tar.time_ended,
                is_blank: params.isBlank,
                event: params.event
            });
        }
    }

    const getDayElements = (props: DayProps) => {
        const totalHours: number = props.end - props.start;
        let elems = [];
        if (props.isBig) {
            elems.push(
                <View style={styles.hoursWrapper} key={"hours_column"}>
                    {
                        Array.apply(null, Array(totalHours)).map(function (x, i) {
                            let hour = String(i + (24 - totalHours));
                            if (hour.length < 2) {
                                hour = '0' + hour;
                            }
                            return (
                                <View style={{ flex: 1 }} key={"one_hour" + i}>
                                    <Text>{hour + ':00'}</Text>
                                </View>
                            );
                        })
                    }
                </View>
            )
        }

        for (let ti = 0; ti < types.length; ti++) {
            let toPush = [];
            let totalFlex: number = (props.end - props.start) * 4;
            const evts = toJS(props.events).sort(function (a, b) {
                return Number(a.time_started) - Number(b.time_started);
            });
            let currentFlex: number = 0;
            for (let i = 0; i < evts.length; i++) {
                const ev: ICalendarEvent = evts[i];
                if (ev.time_started && ev.time_ended && ev.type && ev.type.replace("availability-", "") === types[ti]) {
                    const startedHour: number = Number(moment(ev.time_started).format("HH"));
                    const startedMinute: number = Number(moment(ev.time_started).format("mm"));
                    const startedFlex: number = (startedHour - props.start) * 4 + (startedMinute > 0 ? Math.floor(60 / startedMinute) : 0) - currentFlex;
                    const endedHour: number = Number(moment(ev.time_ended).format("HH"));
                    const endedMinute: number = Number(moment(ev.time_ended).format("mm"));
                    const eventFlex: number = (endedHour - startedHour) * 4 + (endedMinute === startedMinute ? 0 : 60 / (endedMinute - startedMinute));
                    const eventType: IEventType = getEventByName(types[ti]);
                    // console.log('startedHour, startedMinute, startedFlex, endedHour, endedMinute, eventFlex, types[ti] is: ', startedHour, startedMinute, startedFlex, endedHour, endedMinute, eventFlex, types[ti]);
                    if (startedFlex > 0) {
                        if (props.isBig) {
                            toPush.push(
                                <TouchableOpacity
                                    key={"blank_block_" + i}
                                    style={{ flex: startedFlex, backgroundColor: "white" }}
                                    onPress={(pressEvent: GestureResponderEvent) => {
                                        handleBlockClick({
                                            pressEvent,
                                            elemStart: props.start,
                                            elemEnd: Number(moment(new Date(ev.time_started)).format('hh')),
                                            type: types[ti],
                                            event: ev,
                                            isBlank: true,
                                        });
                                    }}
                                    onLayout={(event: any) => {
                                        targetDimensions[event.nativeEvent.target] = {
                                            ...event.nativeEvent.layout,
                                            time_started: moment(ev.time_started).set({ hours: props.start, minutes: 0, seconds: 0, milliseconds: 0 }).toDate(),
                                            time_ended: moment(ev.time_started).toDate(),
                                            isBlank: true,
                                            event: ev,
                                        };
                                    }}
                                >
                                </TouchableOpacity>

                            )
                        } else {
                            toPush.push(
                                <View key={"blank_block_" + i} style={{ flex: startedFlex, backgroundColor: "white" }}></View>
                            );

                        }
                    }

                    if (props.isBig) {
                        toPush.push(
                            <TouchableOpacity
                                key={"event_block_" + i}

                                onPress={(pressEvent: GestureResponderEvent) => {
                                    handleBlockClick({
                                        pressEvent,
                                        elemStart: Number(moment(new Date(ev.time_started)).format('HH')),
                                        elemEnd: Number(moment(new Date(ev.time_ended)).format('HH')),
                                        type: types[ti],
                                        event: ev,
                                        isBlank: false,
                                    });
                                }}
                                onLayout={(event: any) => {
                                    targetDimensions[event.nativeEvent.target] = {
                                        ...event.nativeEvent.layout,
                                        time_started: moment(ev.time_started).toDate(),
                                        time_ended: moment(ev.time_ended).toDate(),
                                    };
                                }}
                                style={{
                                    flex: eventFlex,
                                    backgroundColor: eventType.color,
                                    justifyContent: 'center'
                                }}
                            >
                                <View style={styles.inBlockTextWrapper}>
                                    <Text style={styles.inBlockText}>{ev.title}</Text>
                                    <Text style={styles.inBlockText}>{ev.description}</Text>
                                </View>
                            </TouchableOpacity>
                        )
                    } else {

                        toPush.push(

                            <View
                                key={"event_block_" + i}
                                style={{
                                    flex: eventFlex,
                                    backgroundColor: eventType.color,
                                }}>
                            </View>
                        );
                    }
                    currentFlex += startedFlex + eventFlex;
                }
            }
            if (props.isBig) {
                toPush.push(
                    <TouchableOpacity
                        key={"blank_block_end_" + ti}
                        style={{ flex: totalFlex - currentFlex, backgroundColor: "white" }}
                        onPress={(pressEvent: GestureResponderEvent) => {
                            handleBlockClick({
                                pressEvent,
                                elemStart: evts && evts.length > 0 ? Number(moment(new Date(evts[evts.length - 1].time_ended)).format('HH')) : props.start,
                                elemEnd: props.end,
                                type: types[ti],
                                event: null,
                                isBlank: true
                            });
                        }}
                        onLayout={(event: any) => {
                            targetDimensions[event.nativeEvent.target] = {
                                ...event.nativeEvent.layout,
                                time_started: evts && evts.length > 0 ? evts[evts.length - 1].time_ended : props.start,
                                time_ended: evts && evts.length > 0 ? moment(evts[evts.length - 1].time_ended).set({ hours: props.end }).toDate() : props.end,
                                isBlank: true
                            };
                        }}

                    >
                    </TouchableOpacity>
                );
            } else {
                toPush.push(
                    <View key={"blank_block_end_" + ti} style={{ flex: totalFlex - currentFlex, backgroundColor: "white" }}></View>
                );

            }
            elems.push(
                <View style={styles.columnWrapper} key={types[ti]}>{toPush}</View>
            );
        }
        return elems;

    }
    const elems = props.events ? getDayElements(props) : [];
    return (
        <View style={{ flex: 1 }}>
            {
                props.isBig &&
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ width: 50, backgroundColor: MKColor.Grey }}></View>
                    {
                        types.map((type: string, index: number) => {
                            return (
                                <View style={styles.typeTextContainer} key={index}>
                                    <Text style={styles.typeText}>{store.translations.translations[type]}</Text>
                                </View>
                            )
                        })
                    }
                </View>
            }
            <View style={styles.container}>
                {
                    elems
                }
            </View>
        </View>

    )
}
export default observer(Day);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "row",

    },
    columnWrapper: {
        flex: 1,
    },
    hoursWrapper: {
        width: 50
    },
    typeText: {
        fontSize: 20,
        textAlign: 'center'
    },
    typeTextContainer: {
        flex: 1,
        borderColor: MKColor.Grey,
        borderStartWidth: 1,
    },
    inBlockText: {
        fontSize: 20,
        textAlign: 'center'
    },
    inBlockTextWrapper: {
        justifyContent: 'center'
    }
})