import React, { FunctionComponent } from 'react';
import Day, { DayProps } from "./calendar/Day";
import { StackNavigationProp } from "@react-navigation/stack";
import { MyCalendarNavigationParams } from "../pages/MyCalendar.page";
import { RouteProp } from "@react-navigation/native";
import { AcceptMeetingParams } from "./AcceptMeeting";
import { ICalendarEvent, INotification } from 'go2c-shared';
import { View } from 'react-native';
import { observer } from 'mobx-react';
import { useStore, Stores } from '../stores';
import { CurrentItemNavigationParams } from '../pages/Map.page';

export interface RescheduleMeetingDayParams extends AcceptMeetingParams {
    events: ICalendarEvent[];
}


type MyCalendarScreenNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'reschedule_meeting_day'>;

type MyCalendarScreenRouteProp = RouteProp<CurrentItemNavigationParams, 'reschedule_meeting_day'>;

interface IRescheduleMeetingDay {
    navigation: MyCalendarScreenNavigationProp;
    route: MyCalendarScreenRouteProp;
}
const RescheduleMeetingDay: FunctionComponent<IRescheduleMeetingDay> = function (props: IRescheduleMeetingDay) {
    const store: Stores = useStore().store;
    return (
        <View style={{ flex: 1 }}>
            <Day
                events={[props.route.params.meeting.event]}
                start={6}
                end={24}
                excludeEmptyColumns={true}
                isBig={true}
                meeting={props.route.params.meeting}
                timeClick={(fromTimeClick: any) => {
                    const navParams = {
                        ...fromTimeClick,
                        time_started: String(fromTimeClick.time_started),
                        time_ended: String(fromTimeClick.time_ended),
                    };
                    if (props.route.params.meeting) {
                        navParams.meeting = props.route.params.meeting;
                    }

                    props.navigation.navigate("send_invitation", navParams);

                }}

            />
        </View>
    )
}

export default observer(RescheduleMeetingDay);