import { useNavigation } from "@react-navigation/native";
import React, { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { Stores, useStores } from "../../stores";

export interface UserVerfiedPageProps {
    navigateIfNotConnected?: string;
}

type UserVerfiedPagePropsWithChildren = PropsWithChildren<UserVerfiedPageProps>;

const UserVerfiedPage: FunctionComponent<UserVerfiedPagePropsWithChildren> = (props: UserVerfiedPagePropsWithChildren) => {
    const store: Stores = useStores();
    const userVerified = store.user.user?.email_validated;
    const navigation = useNavigation();
    useEffect(() => {
        if (!userVerified) {
            navigation.navigate(props.navigateIfNotConnected || 'profile');
        }
    }, [userVerified]);

    return (<>{props.children}</>)
}

export default UserVerfiedPage;