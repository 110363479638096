import React, { FunctionComponent } from 'react';
import { Text, Dimensions, StyleSheet, View, Image } from 'react-native'
import { Stores, useStore } from '../../stores'
import { getFileType, buildImagePath } from '../../services/path.service'
import ItemImage from '../item/ItemImage'

import Carousel from 'react-native-banner-carousel';
import ItemVideo from '../item/ItemVideo';

interface SwiperComponentProps {
    slides: string[];
}

const SwiperComponent: FunctionComponent<SwiperComponentProps> = (props: SwiperComponentProps) => {
    const stores: Stores = useStore().store;
    const BannerWidth = Dimensions.get('window').width;
    const BannerHeight = 500;

    return (
        <View style={styles.wrapper}>
            <Carousel
                // autoplay
                // autoplayTimeout={5000}
                loop
                index={0}
                pageSize={BannerWidth}

            >
                {props.slides.map((x, i) => {
                    const fileType = getFileType(x);
                    return (

                        fileType === 'image' ?
                            <View key={i} style={{ width: BannerWidth, height: BannerHeight, flex: 1, display: 'flex' }}>
                                <Image style={{ width: BannerWidth, height: BannerHeight, flex: 1, display: 'flex', resizeMode: 'contain' }} source={{ uri: buildImagePath(x, stores.items.images_api, 400, 350) as undefined as string }} />

                            </View>
                            :
                            fileType === 'video' ?
                                <ItemVideo
                                    source={'https://d1br1y80w5qr5e.cloudfront.net/' + x.replace('go2c-images/', '')}
                                    key={i}
                                />

                                :
                                <Text>Invalid image</Text>
                    )
                })}
            </Carousel>
        </View>
    )
}
export default SwiperComponent;


export const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        height: 500
    },
    slide: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black'
    }
})