import { ITranslationName } from 'go2c-shared';
import moment, { Moment } from 'moment';

export const canAddToCalendar = (date: Moment, daysArray: string[]): boolean => {
    return daysArray.indexOf(date.format('ddd').toUpperCase()) > -1;
}

export type IMatrix = Array<IDayInMonth[]>;

export const getDays = () => {
    const toReturn: string[] = [];
    for (let i = 0; i < 7; i++) {
        toReturn.push(moment(new Date()).day(i).format("ddd").toUpperCase())

    }
    return toReturn;
}

export const getWeekDays = (week_start: string, num_of_days_in_week: number): string[] => {
    const days = getDays();
    let toReturn: string[] = [];
    const daysToRemove: number = days.length - num_of_days_in_week;
    const indexInDays: number = days.indexOf(week_start);
    for (let i = indexInDays; i < days.length; i++) {
        toReturn.push(days[i]);
    }
    for (let i = 0; i < indexInDays; i++) {
        toReturn.push(days[i])
    }
    if (daysToRemove > 0) {
        return toReturn.slice(0, daysToRemove * -1);

    } else {
        return toReturn;
    }

}

export interface IDayInMonth {
    date: Moment;
    inMonth: boolean;
}


export function getMonthMatrix(date: Date, weekDaysArray: string[]): IMatrix {
    const matrix: IMatrix = [];
    const firstDay: Moment = moment(moment(date).startOf('month').format());
    const lastDay: Moment = moment(moment(date).endOf('month').format());
    const numOfDays: number = moment(date).daysInMonth();
    let curDay: Moment = moment(date).startOf("month");
    let row: IDayInMonth[] = [];

    for (let i = 0; i < numOfDays; i++) {
        const curDayInWeek: string = curDay.format("ddd").toUpperCase();
        const curDayInMonth: number = Number(curDay.format("DD"));
        if (curDayInMonth === 1 && curDayInWeek !== weekDaysArray[0]) {
            let firstDayDayInWeek: number = weekDaysArray.indexOf(moment(firstDay).format('ddd').toUpperCase()) - 1;
            if (firstDayDayInWeek > 0) {
                for (let j = 0; j < firstDayDayInWeek; j++) {
                    const daysDiff: number = firstDayDayInWeek - j;
                    const afterSubtract = moment(moment(date).startOf('month').subtract(daysDiff, 'days').toDate());
                    if (canAddToCalendar(afterSubtract, weekDaysArray)) {
                        row.push({ date: afterSubtract, inMonth: false });
                    }
                }
            }
        }
        if (canAddToCalendar(curDay, weekDaysArray)) {
            row.push({ date: moment(curDay.toDate()), inMonth: true });
        }
        if ((weekDaysArray.indexOf(curDay.format('ddd').toUpperCase()) === weekDaysArray.length - 1) || (i === (numOfDays - 1) && row.length > 0)) {
            matrix.push(row);
            row = [];
        }
        curDay = curDay.add(1, 'day');
    }
    const lastDayInWeekArray: number = weekDaysArray.indexOf(lastDay.format('ddd').toUpperCase());
    const daysToAddAtTheEnd: number = weekDaysArray.length - 1 - lastDayInWeekArray;
    for (let d = 0; d < daysToAddAtTheEnd; d++) {
        const afterAdd: Moment = moment(lastDay.add(1, 'days').toDate());
        if (canAddToCalendar(afterAdd, weekDaysArray)) {
            matrix[matrix.length - 1].push({ date: afterAdd, inMonth: false });
        }
    }
    return matrix;
}


export const validateEmail = (email: string): boolean => {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(email).toLowerCase())
}

export interface PasswordValidation {
    translation: ITranslationName;
    regex: string;

}

const passwordValidations: PasswordValidation[] = [
    { translation: 'password_should_contain_lower_case', regex: '(?=.*[a-z])' },
    { translation: 'password_should_contain_upper_case', regex: '(?=.*[A-Z])' },
    { translation: 'password_should_contain_numbers', regex: '(?=.*[0-9])' },
    { translation: 'password_should_contain_upper_case', regex: '(?=.{8,})' },
]

export const validatePassword = (password: string): ITranslationName[] => {
    return passwordValidations.filter(validation => !new RegExp(validation.regex).test(password)).map(validation => validation.translation);
}