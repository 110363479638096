import { ICalendarEvent, INotification, IUser } from 'go2c-shared';
import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { I18nManager } from 'react-native';
import { mapEvents, toEventsArray } from '../services/events.service';
import { getEventsForDay } from '../services/items.service';
import Request from '../services/axios.service';
export interface UserStoreMethods {
    setNotifications(notifications: INotification[]): void;
    setUser(user: IUser): void;
    setEvents(events: ICalendarEvent[]): void;
    setCalendarSelectedDate(date: Date): void;
    setCurrentMeeting(meeting: INotification): void;
    setInit(set_time?: boolean): void;
    unInit(): void;
    setFirebaseId(firebase_id: string): void;
    getUserFromServer(): void;
}

export interface IUserStore extends UserStoreMethods {
    user: IUser;
    notifications: INotification[];
    unseen: number;
    mapped_events: any;
    events: ICalendarEvent[];
    calendar_selected_date: Date;
    calendar_selected_date_events: ICalendarEvent[];
    meeting_to_approve: INotification;
    did_init: boolean;
    did_init_time: Date;
    language_direction: 'rtl' | 'ltr';
    firebase_id: string;
    week_starts_at: 'SUN' | 'MON' | 'SAT';
}

class Userstore {
    @persist('map') @observable user: IUser;
    @observable notifications: INotification[] = [];
    @observable unseen: number = 0;
    @observable events: ICalendarEvent[] = [];
    @observable mapped_events: any;
    @observable calendar_selected_date: Date;
    @observable calendar_selected_date_events: ICalendarEvent[];
    @observable meeting_to_approve: INotification;
    @persist @observable did_init: boolean = false;
    @persist('object') @observable did_init_time: Date;

    @observable language_direction: 'rtl' | 'ltr' = 'ltr';
    @observable firebase_id: string;
    @observable week_starts_at: 'SUN' | 'MON' | 'SAT' = 'SUN';
    @observable hydrated: boolean = false;

    @action
    public setNotifications(notifications: INotification[]) {
        this.notifications = notifications;
        this.unseen = 0;
        for (let i = 0; i < this.notifications.length; i++) {
            if (!this.notifications[i].seen) {
                this.unseen++;
            }
        }
    }

    @action
    public setUser(user: IUser) {
        this.user = user;
        this.setLanguageDirection();
    }

    @action
    public async getUserFromServer() {
        const user = await Request({ method: 'GET', url: 'users/me' });
        this.setUser(user.user);
    }

    @action
    public setEvents(events: ICalendarEvent[]) {
        this.mapped_events = mapEvents(events);
        this.events = toEventsArray(this.mapped_events);
    }

    @action
    public setCalendarSelectedDate(date: Date) {
        this.calendar_selected_date = date;
        this.calendar_selected_date_events = getEventsForDay(this.events, date);
    }

    @action
    public setCurrentMeeting(meeting: INotification) {
        this.meeting_to_approve = meeting;
    }

    @action
    public setInit(set_time: boolean) {
        this.did_init = true;
        if (set_time) {
            this.did_init_time = new Date();
        }
    }

    @action
    public unInit() {
        this.did_init = false;
    }

    @action
    public setLanguageDirection() {
        const lang = this.user.language.name;
        this.language_direction = lang === 'hebrew' || lang === 'arabic' ? 'rtl' : 'ltr';
        I18nManager.forceRTL(this.language_direction === 'rtl');
    }

    @action
    public setFirebaseId(firebase_id: string) {
        this.firebase_id = firebase_id;
    }
}

export default new Userstore();
