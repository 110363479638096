import React, { FunctionComponent, useState } from "react";
import { observer } from "mobx-react";
import { StackNavigationProp } from "@react-navigation/stack";
import { CurrentItemNavigationParams } from "../pages/Map.page";
import { RouteProp } from "@react-navigation/native";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { Stores, useStore } from "../stores";
import { removeCommas } from "../services/utils.service";
import MKColor from "../services/palette.service";
import TextField from './forms/text_field/TextField';
import CollapsibleTitle from "./CollapsibleTitle";
import Seperator from "./forms/Seperator";
import RippleButton from "./forms/RippleButton";



export interface SearchFilterParams {

}

type SearchFiltersNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'search_filters'>;

type SearchFiltersRouteProp = RouteProp<CurrentItemNavigationParams, 'search_filters'>;

interface SearchFiltersProps {
    navigation: SearchFiltersNavigationProp;
    route: SearchFiltersRouteProp;
}

const SearchFilters: FunctionComponent<SearchFiltersProps> = function (props: SearchFiltersProps) {
    const store: Stores = useStore().store;

    return (
        <ScrollView style={styles.container}>
            <RippleButton
                onPress={() => {
                    store.items.clearFilters();
                }}
                style={styles.clearSearch}
                rippleColor='black'
            >
                <Text style={styles.clearSearchText}>{store.translations.translations.clear_search}</Text>
            </RippleButton>

            {/*
            <CitiesAutocomplete />
            
            <CollapsibleTitle
                title={'city'}
            >
                <CitiesAutocomplete />

            </CollapsibleTitle>
            */}
            <CollapsibleTitle
                title={store.translations.translations.free_text}
                add_seperator
            >
                <TextField
                    label={store.translations.translations.free_text}
                    value={store.items.free_text_search}
                    style={styles.priceInput}

                    onChangeText={(val: string) => {
                        store.items.setFreeTextSearch(val);
                    }}
                />

                {/*
                <Content>
                    <Form>
                        <Item floatingLabel underline style={{borderColor:MKColor.Blue}} bordered>
                            <Label>{store.translations.translations.free_text}</Label>
                            <Input
                                keyboardType='default'
                                value={store.items.free_text_search}
                                style={styles.priceInput}
                                onChangeText={(val: string) => {
                                    store.items.setFreeTextSearch(val);
                                }}
                            />
                        </Item>
                    </Form>
                </Content>
                            */}
            </CollapsibleTitle>
            <CollapsibleTitle
                title={store.translations.translations.price}
                add_seperator
            >
                <View style={styles.priceInputWrapper}>
                    <TextField
                        label={store.translations.translations.minimum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.price_min || ''}
                        onChangeText={(val: string) => {
                            store.items.setPriceMin(removeCommas(val));
                        }}
                    />
                    <Text style={styles.dashText}>-</Text>
                    <TextField
                        label={store.translations.translations.maximum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.price_max || ''}
                        onChangeText={(val: string) => {
                            store.items.setPriceMax(removeCommas(val));
                        }}
                    />
                </View>
            </CollapsibleTitle>

            <CollapsibleTitle
                title={store.translations.translations.size}
                add_seperator
            >
                <View style={styles.priceInputWrapper}>
                    <TextField
                        label={store.translations.translations.minimum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.size_min ? String(store.items.size_min) : ''}
                        onChangeText={(val: string) => {
                            store.items.setSizeFilter(val, null);
                        }}
                    />
                    <Text style={styles.dashText}>-</Text>
                    <TextField
                        label={store.translations.translations.maximum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.size_max ? String(store.items.size_max) : ''}
                        onChangeText={(val: string) => {
                            store.items.setSizeFilter(null, val);

                        }}
                    />
                </View>
                <Text>{store.translations.translations.square_meters}</Text>
            </CollapsibleTitle>

            <CollapsibleTitle
                title={store.translations.translations.number_of_rooms}
                add_seperator
            >
                <View style={styles.priceInputWrapper}>
                    <TextField
                        label={store.translations.translations.minimum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.num_of_rooms_min ? String(store.items.num_of_rooms_min) : ''}
                        onChangeText={(val: string) => {
                            store.items.setNumOfRoomsMin(Number(val));
                        }}
                    />
                    <Text style={styles.dashText}>-</Text>
                    <TextField
                        label={store.translations.translations.maximum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.num_of_rooms_max ? String(store.items.num_of_rooms_max) : ''}
                        onChangeText={(val: string) => {
                            store.items.setNumOfRoomsMax(Number(val));
                        }}
                    />
                </View>
            </CollapsibleTitle>
            <CollapsibleTitle
                title={store.translations.translations.floor}
                add_seperator
            >
                <Text style={styles.smallTitle}>{store.translations.translations.floor}</Text>
                <View style={styles.priceInputWrapper}>
                    <TextField
                        label={store.translations.translations.minimum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.floor_min ? String(store.items.floor_min) : ''}
                        onChangeText={(val: string) => {
                            store.items.setFloorMinMax(val, null);
                        }}
                    />
                    <Text style={styles.dashText}>-</Text>
                    <TextField
                        label={store.translations.translations.maximum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.floor_max ? String(store.items.floor_max) : ''}
                        onChangeText={(val: string) => {
                            store.items.setFloorMinMax(null, val);
                        }}
                    />
                </View>
                <Seperator />
                <Text style={styles.smallTitle}>{store.translations.translations.total_floors_in_building}</Text>
                <View style={styles.priceInputWrapper}>
                    <TextField
                        label={store.translations.translations.minimum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.total_floors_in_building_min ? String(store.items.total_floors_in_building_min) : ''}
                        onChangeText={(val: string) => {
                            store.items.setTotalFloorsInBuildingMinMax(val, null);
                        }}
                    />
                    <Text style={styles.dashText}>-</Text>
                    <TextField
                        label={store.translations.translations.maximum}
                        keyboardType='number-pad'
                        style={styles.priceInput}
                        value={store.items.total_floors_in_building_max ? String(store.items.total_floors_in_building_max) : ''}
                        onChangeText={(val: string) => {
                            store.items.setTotalFloorsInBuildingMinMax(null, val);
                        }}
                    />
                </View>
            </CollapsibleTitle>
            <RippleButton
                style={styles.submitButton}
                onPress={() => {
                    store.items.setRegion();
                    props.navigation.navigate('map');
                }}
            >
                <Text style={styles.submitButtonText}>{store.translations.translations.search}</Text>
            </RippleButton>
        </ScrollView >
    )
}

export default observer(SearchFilters);


const styles = StyleSheet.create({
    container: {
        margin: 10,
    },
    priceInputWrapper: {
        flexDirection: 'row',
        marginBottom: 15,
    },
    sectionWrapper: {
    },
    priceInput: {
        flex: 3,
        fontSize: 26,
    },
    dashText: {
        flex: 2,
        fontSize: 35,
        textAlign: 'center',
        marginTop: 8,
    },
    labelWrapper: {
        flexDirection: 'row',
        borderBottomColor: 'rgba(207, 204, 204,1)',
        borderBottomWidth: 2,
        marginBottom: 35,
        marginTop: 35,
    },
    plusOpener: {
        backgroundColor: 'rgba(0, 132, 255, 0.5)',
        height: 30,
        width: 30,
        alignContent: 'stretch',
        borderRadius: 15
    },
    labelText: {
        flex: 1,
        fontSize: 26,
    },
    priceWrapper: {

    },
    slider: {
        width: 340,
        height: 80,
        marginStart: 30,
    },
    textInRow: {
        flex: 1,
        fontSize: 20,
    },
    textInRowWrapper: {
        flexDirection: 'row',
        marginBottom: 20
    },
    plusText: {
        alignSelf: 'center',
        lineHeight: 30
    },
    smallTitle: {
        fontSize: 24,
    },
    submitButton: {
        flex: 1,
        height: 80,
        backgroundColor: MKColor.Blue,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10, borderWidth: 1,
        borderColor: '#ddd',
        borderBottomWidth: 0,
        shadowColor: '#000',
        shadowOffset: { width: 15, height: 15 },
        shadowOpacity: 0.8,
        shadowRadius: 10,
        elevation: 5,
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        marginBottom: 10,
    },
    submitButtonText: {
        color: 'white',

    },
    clearSearch: {
        backgroundColor: MKColor.Blue,
        elevation: 5,
        borderRadius: 50,
        height: 50,
        width: 100,
        alignSelf:'flex-end'
    },
    clearSearchText: {
        lineHeight:50,
        color:'white',
        textAlign:'center'
    }

})