import React, { FunctionComponent } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { Icon } from 'react-native-elements';



interface NotificationsProps {
    navigation: any;
}

const SearchBar: FunctionComponent<NotificationsProps> = function (props: NotificationsProps) {

    return (
        <View style={styles.container}>
            <View style={styles.iconContainer}>
                <Icon
                    color="rgba(255, 255, 255, 0.7)"
                    name="search"
                    reverse
                    reverseColor="blue"
                    type="font-awesome"
                    style={styles.icon}

                    onPress={async () => {
                        props.navigation.navigate('search_filters');
                    }}
                />
            </View>
        </View>
    );
}

export default SearchBar;

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        zIndex: 16,
        alignSelf: 'flex-end',
        end: 70,
        width: 200,
        alignItems: 'flex-end',
        marginTop: Platform.select({
            android: 0,
            ios: 25,
        })
    },
    icon: {

    },
    priceInput: {
        flex: 5,
        backgroundColor: "green"
    },
    searchList: {
        // position: 'absolute',
        width: 200,

    },
    textContainer: {
        borderWidth: 1,
        borderColor: 'black',
        width: 200,
        backgroundColor: 'pink',
        // position:'absolute',
        zIndex: 18,
        alignSelf: 'flex-start',

    },
    iconContainer: {
    },
    text: {
        backgroundColor: 'red',
        padding: 2,
        position: 'absolute',
        borderRadius: 10,
        color: 'white'
    },
    slider: {
        width: 340,
        height: 80,

    },
    priceWrapper: {
        flexDirection: 'row',
        color: 'white'
    },
})