import { Platform, StyleSheet } from "react-native";

const styles = StyleSheet.create({
    map: {
        flex: 1,
        zIndex: 1,
        // height: '100vh',
        // width: '100vw',
    },
    calloutView: {
        padding: 0,
        flex: 1,
        width: 150
    },
    calloutTitle: {
        fontSize: 16,
        marginTop: 4,
        fontWeight: 'bold'
    },
    callout: {
        height: 150,
        width: 150,
        padding: 0,
        backgroundColor: 'white',
        marginTop: -200,
        marginStart: -50,

    },
    priceWrapper: {
        flexDirection: 'row',
        marginBottom: 3,
        marginTop: -2,
    },
    priceText: {
        fontSize: 16,
    },
    markerWrapper: {
        width: 30,
        height: 30,
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center'
    },
    markerIcon: {
        backgroundColor: 'white',
        borderRadius: 50,
        padding: Platform.OS === 'web' ? 10 : 0,
    },
    markerIconSmall: {
        padding: 5
    },
    phoneMarkerIcon: {
    },
    markerIconWrapper: {
        padding: 2,
        position: 'absolute',
        top: -5,
        start: -5,
        borderRadius: 50,
    },
    phoneMarkerIconWrapper: {
        backgroundColor: '#e3e4ff',
    },

    locationMarkerIconWrapper: {
        top: 25,
        backgroundColor: '#fffad6',
    },
    homeMarkerIcon: {
        // height:50,
        // width:50,
        // backgroundColor:'white'
    },
    homeMarkerIconWrapper: {
        top: 25,
        start: 25,
        backgroundColor: '#e3ffeb',
    },
    availabilityIconWrapper: {
        padding: 4,
        height: 20,
        marginStart: 2,
        borderRadius: 50,
    },
    availabilityLocationWrapper: {
        backgroundColor: '#fffad6',

    },
    availabilityHomeWrapper: {
        backgroundColor: '#e3ffeb',

    },
    availabilityPhoneWrapper: {
        backgroundColor: '#e3e4ff',

    },
    availabilityText: {
        justifyContent: 'center',
        marginTop: -3,
        marginEnd: 8,
    },
    availabilityWrapper: {
        flexDirection: 'row',
        height: 20,
        alignContent: "center",

    },
    imageInCalloutWeb: {
        height: 50,
        width: 50,
        marginEnd: 7,
        marginStart: 7,
        // alignSelf: 'center',
    },
    imagesWrapperWeb: {
        display: 'flex',
        flexDirection: 'row'
    }
});
export default styles;