import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation } from '@react-navigation/native';
import { Video } from 'expo-av';
import React, { FunctionComponent } from "react";
import { Platform, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import { Stores, useStores } from "../../stores";
import RippleButton from "../forms/RippleButton";



interface ItemVideoProps {
    source: string;
    loading_text?: string;
    show_controls?: boolean;
    style?: StyleProp<ViewStyle>;
}

const ItemVideo: FunctionComponent<ItemVideoProps> = (props: ItemVideoProps) => {
    const store: Stores = useStores();
    const navigation = useNavigation();
    return (
        <View style={styles.slide}>
            {/* <Video
                source={{ uri: props.source }}
                rate={1}
                volume={1.0}
                isMuted
                resizeMode="contain"
                shouldPlay
                isLooping
                usePoster
                style={[styles.video]} 
            />*/}

            <Video
                source={{ uri: props.source }}
                rate={1}
                volume={1.0}
                isMuted
                resizeMode="contain"
                useNativeControls={false}
                shouldPlay={false}

                isLooping
                style={[styles.video]}

            />
            {
                Platform.OS === 'ios' ?
                    <TouchableOpacity
                        onPress={() => {
                            store.media.setCurrentItemVideo({
                                source: props.source,
                                is_playing: false,
                            })
                            navigation.navigate('video');
                        }}
                        style={styles.playButton}
                    >
                        <FontAwesomeIcon
                            icon={faPlay}
                            size={100}
                            style={styles.playIcon}
                        // color={'grey'}
                        />
                    </TouchableOpacity>
                    :

                    <RippleButton
                        onPress={() => {

                            store.media.setCurrentItemVideo({
                                source: props.source,
                                is_playing: false,
                            })

                            navigation.navigate('video', { item_id: store.items.current_item._id, source: props.source });
                        }}
                        style={styles.playButton}
                    >
                        <FontAwesomeIcon
                            icon={faPlay}
                            size={100}
                            style={styles.playIcon}
                        // color={'grey'}
                        />
                    </RippleButton>

            }
            {/* <Button
                onPress={() => {
                    store.media.setCurrentItemVideo({
                        source: props.source,
                        is_playing: false,
                    })
                    navigation.navigate('video');

                }}
                title='play video'
            > 

            </Button>*/}

        </View>
    )
}

export default ItemVideo;

const styles = StyleSheet.create({
    slide: {
        flex: 1,
        justifyContent: 'center',
        // width: '100%',
        height: 500,
        // alignItems: 'center',
        backgroundColor: 'black'

    },
    video: {
        flex: 1,
        // backgroundColor: 'green',
        width: '100%',
        height: '100%',


    },
    playButton: {
        margin: 'auto',
        alignSelf: 'center',
        alignContent: 'center',
        height: 200,
        width: 200,
        position: 'absolute',
        alignItems: 'center',
        borderRadius: 100,
    },
    playIcon: {
        alignSelf: 'center',
        marginTop: 50,
        // marginRight: 75,
        height: 50,
        width: 50,
        display: 'flex',
        color: '#E78BA4',
    },
})

