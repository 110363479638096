import { MainNavigationParams } from "../components/Navigation";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { RouteProp } from "@react-navigation/native";
import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import OpenDrawer from "../components/OpenDrawer";
import { createStackNavigator } from "@react-navigation/stack";
import MyItemsList from "../components/MyItemsList";
import { Stores, useStore } from "../stores";

const Stack = createStackNavigator();


type MyItemsNavigationProp = DrawerNavigationProp<MainNavigationParams, 'my_items'>;

type MyItemsRouteProp = RouteProp<MainNavigationParams, 'my_items'>;

interface MyItemsProps {
    navigation: MyItemsNavigationProp;
    route: MyItemsRouteProp;
}

export interface ItemsListParams {

}

export type MyItemsNavigationParams = {
    my_items_list: ItemsListParams;
}


const MyItems: FunctionComponent<MyItemsProps> = function (props: MyItemsProps) {
    const store: Stores = useStore().store;

    return (
        <Stack.Navigator>
            <Stack.Screen
                name={'my_items_list'}
                component={MyItemsList}
                options={{
                    headerLeft: () => (<OpenDrawer navigation={props.navigation} />),
                    title: store.translations.translations.my_items,
                }}
            />
        </Stack.Navigator>
    )
}
export default observer(MyItems);
