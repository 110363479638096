import { observable, action } from 'mobx';
import { ICalendarEvent } from 'go2c-shared';

export interface IDialogStoreMethods {
    openPopup(title: string, description: string): void;
    closePopup(): void;
    askTakeMeeting(props: ITakeMeetingProps): void;
    takeMeeting(): void;
    closeDialog(): void;
    setDescription(description: string): void;
}

export interface IDialogStore extends IDialogStoreMethods {
    title: string;
    description: string[];
    open: boolean;
    okButton: boolean;
    onOk: (event: ICalendarEvent) => Promise<boolean>;
    takeEvent: ICalendarEvent;
    okFunction: () => Promise<void>;
    okButtonText: string;
    closeFunction: () => void;
}


export interface ITakeMeetingProps {
    onOk: (event: ICalendarEvent) => Promise<boolean>;
    event: ICalendarEvent;
    title: string;
    description: string;
    okButtonText: string;
}



export default class DialogStore {
    @observable title: string = '';
    @observable description: string[] = [''];
    @observable open: boolean = false;
    @observable okButton: boolean = false;
    @observable okButtonText: string;
    @observable onOk: (event: ICalendarEvent) => Promise<boolean>;
    @observable takeEvent: ICalendarEvent;
    @observable okFunction: () => Promise<void>;
    @observable closeFunction: () => void = this.closePopup;

    @action
    public openPopup(title: string, description: string) {
        this.open = true;
        this.title = title;
        // this.description = description.indexOf('\n') > -1 ? description.split('/n') : [description];
        this.setDescription(description);
        this.closeFunction = this.closePopup;
    }

    @action
    public closePopup() {
        this.open = false;
        this.title = null;
        this.description = [];
    }

    @action
    public async askTakeMeeting(props: ITakeMeetingProps) {
        this.okButton = true;
        this.onOk = props.onOk;
        this.takeEvent = props.event;
        // this.openPopup(props.title, props.description);
        this.open = true;
        this.title = props.title;
        // this.description = props.description;
        this.setDescription(props.description);
        this.closeFunction = this.closeDialog;
        this.okFunction = this.takeMeeting;
        this.okButtonText = props.okButtonText;
    }

    @action
    public async takeMeeting() {
        await this.onOk(this.takeEvent);
    }

    @action
    public closeDialog() {
        this.okButton = false;
        this.onOk = null;
        this.takeEvent = null;
        this.open = false;
        this.okFunction = null;
        this.title = null;
        this.description = [];
        this.okButtonText = null;
    }

    @action
    public setDescription(description: string) {
        this.description = description.indexOf('\n') > -1 ? description.split('\n') : [description];
    }

}