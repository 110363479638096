import { create } from 'mobx-persist';
import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';
import { getStorage } from '../services/utils.service';
import DialogStore, { IDialogStore } from './dialog.store';
import ItemsStore, { IItemsStore } from './items.store';
import MediaStore, { IMediaStore } from './media.store';
import MyItemsStore, { IMyItemsStore } from './my.items.store';
import NewApartmentStore, { INewApartmentsStore } from './newapartment.store';
import profileStore from './profile.store';
import TranslationStore, { ITranslationsStore } from './translations.store';
import UserStore, { IUserStore } from './user.store';


export interface Stores {
    items: IItemsStore;
    new_apartment: INewApartmentsStore;
    translations: ITranslationsStore;
    dialog: IDialogStore;
    my_items: IMyItemsStore;
    user: IUserStore;
    media: IMediaStore;
    profile: typeof profileStore;
}


export const stores: Stores = {
    items: ItemsStore,
    new_apartment: NewApartmentStore,
    translations: TranslationStore,
    dialog: new DialogStore(),
    my_items: MyItemsStore,
    user: UserStore,
    media: MediaStore,
    profile:profileStore,
}

export const hydrate = create({
    storage: getStorage(),
    jsonify: true,
});


export const hydrateState = async () => {
    await hydrate('user', UserStore, UserStore);
    // await hydrate('media', MediaStore, MediaStore);
    await hydrate('items', ItemsStore, ItemsStore);
    // await hydrate('new_apartment', NewApartmentStore, NewApartmentStore);
    await hydrate('tranlations', TranslationStore, TranslationStore);
    await hydrate('profile', profileStore, profileStore);
    // await hydrate('my_items', MyItemsStore, MyItemsStore);

}



export function useStores():Stores {
    return useStore().store;
}

export const useStore = () => useContext(MobXProviderContext);