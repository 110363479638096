import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Stores, useStores } from '../../stores';
import OneError from '../forms/errors/OneError';
import NextButton from '../forms/NextButton';
import { EmailConfirmedProps } from './Profile.interface';
import { validateToken } from './ValidateEmail.service';
import profileStyles from './Profile.styles';

const EmailConfirmed: FunctionComponent<EmailConfirmedProps> = function (params: EmailConfirmedProps) {
    const store: Stores = useStores();
    const navigation = useNavigation();
    const [confirming, setConfirming] = useState<boolean>(true);
    const tokenValidation = async () => {
        const fromValidation = await validateToken(params.route.params.token);
        store.user.setUser(fromValidation);
        setConfirming(false);
    };
    useEffect(() => {
        tokenValidation();
    }, [validateToken]);
    return (
        <View style={profileStyles.emailValidationWrapper}>
            {confirming ? (
                <ActivityIndicator size='large' />
            ) : (
                <View>
                    <View style={profileStyles.emailConfirmedMessageWrapper}>
                        <OneError text={store.translations.translations.email_is_confirmed} isOk />
                    </View>
                    <NextButton
                        title={store.translations.translations.profile}
                        onNext={() => {
                            navigation.navigate('profile_page');
                        }}
                        active={true}
                    />
                </View>
            )}
        </View>
    );
};

export default observer(EmailConfirmed);
