import momentTz from 'moment-timezone';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/ar';
import 'moment/locale/he';
import 'moment/locale/de';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/ug-cn';
import 'moment/locale/en-gb';
// import 'moment/locale/';



export function setMomentLocale(locale: string) {
    moment.locale(locale);
}

export function setMomentTimeZone() {
    momentTz.tz(momentTz.tz.guess()).zoneAbbr();
}