import React, { FunctionComponent } from 'react';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import RippleButton from './forms/RippleButton';

interface NumericInputProps {
    onchange(val: number): void;
    value: number;
    flex?: number;
    extraButtons?: number[];
}

const NumericInput: FunctionComponent<NumericInputProps> = function (props: NumericInputProps) {
    let buttonNumbers = [1];
    if (props.extraButtons) {
        buttonNumbers = buttonNumbers.concat(props.extraButtons);
    }
    return (
        <View style={[styles.container, { flex: props.flex || 1 }]}>
            {
                buttonNumbers.slice().reverse().map((num: number, i: number) => (
                    <RippleButton
                        key={i}
                        onPress={() => props.onchange(props.value - num)} style={styles.buttonWrapper}
                    >
                        <Text style={[styles.textInButton, { backgroundColor: "rgba(194, 0, 0,0.2)",color:"rgba(194, 0, 0,1)" }]}>+{num.toString()}</Text>
                    </RippleButton>
                ))
            }
            <View style={styles.inputWrapper}>
                <TextInput
                    keyboardType="numeric"
                    onChangeText={(val: string) => props.onchange(Number(val))} value={String(props.value)}
                    style={styles.input}
                />

            </View>
            {
                buttonNumbers.map((num: number, i: number) => (
                    <RippleButton key={i} onPress={() => props.onchange(props.value + num)} style={styles.buttonWrapper}>
                        <Text style={styles.textInButton}>+{num.toString()}</Text>
                    </RippleButton>
                ))
            }

        </View>
    )
}
export default NumericInput;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
    },

    textInButton: {
        fontSize: 14,
        lineHeight: 33,
        height: 33,
        width: 33,
        backgroundColor: "rgba(0, 179, 24,0.2)",
        color: "rgba(0, 179, 24,1)",
        borderRadius: 50,
        textAlign: "center",
    },
    buttonWrapper: {
        flex: 1,
    },
    input: {
        fontSize: 20,
        width: 40,
        marginTop: -8,
        textAlign:'center'
    },
    inputWrapper: {
        flex: 2,
        alignItems: "center"
    }
})