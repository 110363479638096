import React, { FunctionComponent } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp, useRoute } from "@react-navigation/native";
import { CurrentItemNavigationParams, SendInvitationProps } from "../../pages/Map.page";
import { View } from 'react-native';
import Day, { TimeInDay } from "./Day";
import { observer } from "mobx-react";
import { Stores, useStore } from "../../stores";
import { getEventsForDay } from "../../services/items.service";
import { toEventsArray, mapEvents } from "../../services/events.service";



type BigDayNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'day'>;

type BigDayRouteProp = RouteProp<CurrentItemNavigationParams, 'day'>;


interface BigDayProps {
    navigation: BigDayNavigationProp;
    route: BigDayRouteProp;
}


const BigDay: FunctionComponent<BigDayProps> = function (props: BigDayProps) {
    const store: Stores = useStore().store;
    const route = useRoute();
    return (
        <View style={{ flex: 1 }}>
            <Day
                events={getEventsForDay(toEventsArray(mapEvents(store.items.current_item_availabilities)), store.items.current_day)}
                start={props.route.params.start}
                end={props.route.params.end}
                isBig={true}
                timeClick={(fromClick: TimeInDay) => {
                    const navParams = {
                        ...fromClick,
                        time_started: String(fromClick.time_started),
                        time_ended: String(fromClick.time_ended),
                        item_id: route.params.item_id
                    };
                    if (!fromClick.is_blank && fromClick.type === 'at_the_location') {
                        store.items.setCurrentAvailabilityBlock(fromClick.event);
                        props.navigation.navigate('schedule_at_the_location', {
                            time_started: String(fromClick.time_started),
                            time_ended: String(fromClick.time_ended),
                            hours: fromClick.hours,
                            minutes: fromClick.minutes,
                            type: fromClick.type,
                            item_id: route.params.item_id

                        });
                    } else {
                        props.navigation.navigate("send_invitation", navParams);
                    }
                }}
                event_types={["phone", "at_the_location"]}
            />
        </View>
    )
}
export default observer(BigDay);