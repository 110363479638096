import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Platform } from 'react-native';

// export const baseURL = Platform.OS === 'web' ? 'http://localhost:10002/' : 'http://192.168.1.13:10002/';
// export const baseURL = Platform.OS === 'web' ? 'http://localhost:10002/' : 'http://10.0.2.2:10002/';
// export const baseURL = Platform.OS === 'web' ? 'http://localhost:9095/' : 'http://10.0.2.2:9095/';
export const baseURL = 'https://api.go2c.io/';
// export const baseURL = 'http://192.168.1.13:10002/';
// export const baseURL = 'http://localhost:10002/';
// export const baseURL = 'http://10.0.2.2:10002/';
// export const baseURL = 'https://appartment.adiluz.com/';

export function init() {
    axios.defaults.baseURL = baseURL;
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return {
                success: false,
                err: error,
                message:
                    error.response && error.response.data
                        ? error.response.data.message
                        : error.response && error.response.statusText
                        ? error.response.statusText
                        : 'Unexpected error',
            };
        }
    );
}

const Request = async (params: AxiosRequestConfig): Promise<any> => {
    if (params.url && params.url.indexOf(baseURL) === -1) {
        params.url = baseURL + params.url;
    }
    try {
        const res: AxiosResponse = await axios({ ...params, withCredentials: true });
        // TODO add validation for status codes
        if (res.data) {
            return res.data;
        }
        return res;
    } catch (e) {
        alert(JSON.stringify(e));
        throw e;
    }
};

export default Request;
