import { RouteProp } from "@react-navigation/native";
import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { View, Text, StyleSheet, ScrollView } from "react-native";
import { StackNavigationProp } from "@react-navigation/stack";
import { MyItemsNavigationParams } from "../pages/MyItems.page";
import { Stores, useStore } from "../stores";
import { IItem } from "go2c-shared";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faHome, faMoneyBill, faClock } from "@fortawesome/pro-regular-svg-icons";
import moment from "moment";
import RippleButton from "./forms/RippleButton";
import MKColor from "../services/palette.service";



type ItemsListNavigationProp = StackNavigationProp<MyItemsNavigationParams, 'my_items_list'>;

type ItemsListRouteProp = RouteProp<MyItemsNavigationParams, 'my_items_list'>;

interface ItemsListProps {
    navigation: ItemsListNavigationProp;
    route: ItemsListRouteProp;
}




const ItemsList: FunctionComponent<ItemsListProps> = function (props: ItemsListProps) {
    const store: Stores = useStore().store;
    return (
        <ScrollView>
            {
                store.items.items && store.items.items.length > 0 ?
                    store.my_items.items.map((item: IItem, i: number) => {
                        return (
                            <RippleButton
                                key={i}
                                style={styles.oneItem}
                                onPress={() => {
                                    // console.log('item pressed ', item._id);

                                }}
                            >

                                <View style={[styles.oneDataWrapper]}>
                                    <FontAwesomeIcon icon={faHome} style={[styles.icon]} />
                                    <Text>{item.raw_address}</Text>
                                </View>
                                <View style={[styles.oneDataWrapper]}>
                                    <FontAwesomeIcon icon={faMoneyBill} style={[styles.icon]} />
                                    <Text>{item.price}</Text>
                                </View>
                                <View style={[styles.oneDataWrapper]}>
                                    <FontAwesomeIcon icon={faClock} style={[styles.icon]} />
                                    <Text>{moment(new Date(item.created_at)).format('LLLL')}</Text>
                                </View>
                                <View style={[styles.oneDataWrapper]}>
                                    <Text style={[styles.icon]}>{store.translations.translations.type}</Text>
                                    <Text>{store.translations.translations[item.type]}</Text>
                                </View>
                            </RippleButton>
                        )
                    })
                    :
                    <View style={[styles.youHaveNoItemsWrapper]}>
                        <Text style={[styles.youHaveNoItemsText]}>{store.translations.translations.you_havent_posted_any_items_yet}</Text>
                        <Text style={[styles.createNewItemExplanation]}>{store.translations.translations.please_post_new_items}</Text>


                    </View>
            }

        </ScrollView>
    )
}
export default observer(ItemsList);


const styles = StyleSheet.create({
    oneItem: {
        flex: 1,
        borderRadius: 8,
        padding: 15,
        marginBottom: 7,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 3.84,
        margin: 15,
        elevation: 3,
        backgroundColor: "#f2f2f2"

    },
    oneDataWrapper: {
        flexDirection: 'row',
        flex: 1,
    },
    icon: {
        marginEnd: 15,
    },
    youHaveNoItemsWrapper: {
        height: '100%',
    },
    youHaveNoItemsText: {
        fontSize: 22,
        height: 150,
        lineHeight: 150,
        textAlign: 'center',
        color: MKColor.Grey
    },
    createNewItemExplanation: {
        flex: 3,
        textAlign: 'center',
        fontSize: 20,
        color: MKColor.Grey,
        marginTop: 100,
    },
})
