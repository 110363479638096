import React, { FunctionComponent, useState } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import moment from 'moment-timezone';
import MonthCalendar from './MonthCalendar';
import { Icon } from 'react-native-elements';
import TouchableView from '../TouchableView';
import { StackNavigationProp } from '@react-navigation/stack';
import { NewItemNavigationParams } from '../../pages/NewItem.page';
import { RouteProp } from '@react-navigation/native';
import BulkAvailability from './BulkAvailability';
import { observer } from 'mobx-react';
import { Stores, useStore } from '../../stores';
import MKColor from "../../services/palette.service";


export interface CalendarProps {
    navigation: any;
    num_of_days_in_week?: number;
    selected_date?: Date;
    week_starts_at?: string;
    months_to_load?: number;
}

type NewItemScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'availability'>;

type NewItemScreenRouteProp = RouteProp<NewItemNavigationParams, 'availability'>;

interface INewItemFormPage {
    navigation: NewItemScreenNavigationProp;
    route: NewItemScreenRouteProp;
}
const Calendar: FunctionComponent<INewItemFormPage> = function (props: INewItemFormPage) {
    const store: Stores = useStore().store;

    const num_of_days_in_week: number = props.route.params.num_of_days_in_week || 7;
    const week_starts_at: string = props.route.params.week_starts_at || "MON";
    const selected_date: Date = props.route.params.selected_date || new Date();
    const [selectedDate, setSelectedDate] = useState(selected_date);
    const [availability, setAvailability] = useState(store.translations.translations.phone);

    return (
        <ScrollView style={styles.container}>
            <View style={styles.buttonsWrapper}>
                <TouchableView
                    style={{ ...styles.buttons, backgroundColor: availability === store.translations.translations.phone ? MKColor.Blue : MKColor.Grey }}
                    onPress={() => { setAvailability(store.translations.translations.phone) }}
                >
                    <View style={styles.innerButtonsContainer}>

                        <Icon
                            color="white"
                            name="phone"
                            reverseColor="blue"
                            type="font-awesome"
                            style={styles.icon}
                            size={availability === store.translations.translations.at_the_location ? 30 : 50}
                            
                        ></Icon>
                    </View>
                </TouchableView>
                <TouchableView
                    style={{ ...styles.buttons, backgroundColor: availability === store.translations.translations.at_the_location ? MKColor.Blue : MKColor.Grey }}

                    onPress={() => { setAvailability(store.translations.translations.at_the_location) }}
                >
                    <View style={styles.innerButtonsContainer}>
                        <Icon
                            color="white"
                            name="map-marker"
                            reverseColor="blue"
                            style={styles.icon}
                            type="font-awesome"
                        ></Icon>
                    </View>
                </TouchableView>
                <TouchableView
                    style={{ ...styles.buttons, backgroundColor: availability === store.translations.translations.home ? MKColor.Blue : MKColor.Grey }}
                    onPress={() => { setAvailability(store.translations.translations.home) }}
                >
                    <View style={styles.innerButtonsContainer}>
                        <Icon
                            color="white"
                            name="home"
                            style={styles.icon}
                            reverseColor="blue"
                            type="font-awesome"
                        ></Icon>
                    </View>
                </TouchableView>
            </View>
            <View style={styles.oneView}>
                {
                    availability === store.translations.translations.phone ?
                        <BulkAvailability
                            minimumTime={store.new_apartment.day_starts_at}
                            maximunTime={store.new_apartment.day_ends_at}
                            type={"availability-phone"}
                            title={store.translations.translations.phone}
                        />

                        : availability === store.translations.translations.at_the_location ?
                            <BulkAvailability
                                minimumTime={store.new_apartment.day_starts_at}
                                maximunTime={store.new_apartment.day_ends_at}
                                type={"availability-at_the_location"}
                                title={store.translations.translations.at_the_location}
                            />

                            :

                            <BulkAvailability
                                minimumTime={store.new_apartment.day_starts_at}
                                maximunTime={store.new_apartment.day_ends_at}
                                type={"availability-home"}
                                title={store.translations.translations.home}
                            />
                }
                <MonthCalendar
                    num_of_days_in_week={num_of_days_in_week}
                    selected_date={selected_date}
                    week_starts_at={week_starts_at}
                    onSelect={(date: Date) => {
                        setSelectedDate(date);
                        store.new_apartment.setSelectedDay(date);
                        props.navigation.navigate('availability_day');
                    }}
                    mappedEvents={store.new_apartment.mapped_events}
                />
            </View>

            <View style={styles.oneView}>
                <Text>{moment(selectedDate).format('YYYY/MM/DD')}</Text>

            </View>
        </ScrollView>
    );
}
export default observer(Calendar);

const styles = StyleSheet.create({
    container: {

    },
    buttons: {
        flex: 1,
        height: 50,
        padding: 2,
        lineHeight: 50,
        alignItems: 'center',
        alignContent: 'center',
    },
    oneView: {
        flex: 1,
    },
    buttonsWrapper: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
        flex: 1,
        margin: 10,
    },
    icon: {
        flex: 1,
        alignSelf: 'center',
        lineHeight: 50,
        fontSize: 50,
        // textAlignVertical:'bottom',
    },
    innerButtonsContainer: {
        height: 50,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        lineHeight: 50,
    }
});