import { ICalendarEvent } from "go2c-shared";
import { toJS } from "mobx";
import moment from "moment";
import MKColor from "../services/palette.service";


const isAfterOrEqual = (date1: Date, date2: Date) => {
    const isAfter: boolean = moment(date1).isAfter(moment(date2));
    const isEqual: boolean = moment(date1).diff(moment(date2), 'minutes') > 1;
    return isAfter || isEqual;
}

export const canBeMerged = (event1: ICalendarEvent, event2: ICalendarEvent): boolean => {
    return (
        (event1.type === event2.type) &&
        (
            (isAfterOrEqual(event1.time_started, event2.time_started) && isAfterOrEqual(event2.time_ended, event1.time_started)) ||
            (isAfterOrEqual(event1.time_ended, event2.time_started) && isAfterOrEqual(event2.time_ended, event1.time_ended))
        )
    )
}


const mergeTwoEvents = (event1: ICalendarEvent, event2: ICalendarEvent): ICalendarEvent => {
    const startTime: Date = isAfterOrEqual(event1.time_started, event2.time_started) ? event2.time_started : event1.time_started;
    const endTime: Date = isAfterOrEqual(event1.time_ended, event2.time_ended) ? event1.time_ended : event2.time_ended;
    const merged: ICalendarEvent[] = [];
    if (event2.merged && event2.merged.length > 0) {
        for (let i = 0; i < event2.merged.length; i++) {
            merged.push(event2.merged[i])
        }
    }
    return {
        ...event1,
        time_started: startTime,
        time_ended: endTime,
        merged
    }
}

export const canBeMergedNew = (event1: ICalendarEvent, event2: ICalendarEvent): boolean => {
    const dayEventStarted: number = new Date(event1.time_started).getTime();
    const dayEventEnded: number = new Date(event1.time_ended).getTime();
    const eventStarted: number = new Date(event2.time_started).getTime();
    const eventEnded: number = new Date(event2.time_ended).getTime();
    return (
        event1.type === event2.type &&
        (
            (dayEventStarted >= eventStarted && dayEventStarted <= eventEnded) ||
            (dayEventEnded >= eventStarted && dayEventEnded <= eventEnded) ||
            (dayEventStarted >= eventStarted && dayEventEnded <= eventEnded) ||
            (eventStarted >= dayEventStarted && eventEnded <= dayEventEnded)
        )
    );

}

export const mergeTwoEventsNew = (event1: ICalendarEvent, event2: ICalendarEvent): ICalendarEvent => {
    const dayEventStarted: number = new Date(event1.time_started).getTime();
    const dayEventEnded: number = new Date(event1.time_ended).getTime();
    const eventStarted: number = new Date(event2.time_started).getTime();
    const eventEnded: number = new Date(event2.time_ended).getTime();
    const startTime: number = dayEventStarted < eventStarted ? dayEventStarted : eventStarted;
    const startEnded: number = dayEventEnded > eventEnded ? dayEventEnded : eventEnded;
    let merged: ICalendarEvent[] = [];
    if (event2.is_merged_event === true) {
        merged = merged.concat(event2.merged);
    } else {
        merged.push(event2);
    }
    if (event1.is_merged_event === true) {
        merged = merged.concat(event1.merged);
    } else {
        merged.push(event1);
    }
    let toReturn: ICalendarEvent = {
        ...event1,
        time_started: new Date(startTime),
        time_ended: new Date(startEnded),
        merged,
        is_merged_event: true
    }
    return toReturn

}

export const canMergeEventsArray = (events: ICalendarEvent[]): boolean => {
    let toReturn: boolean = false;
    for (let i = 0; i < events.length; i++) {
        const oneEvent: ICalendarEvent = events[i];
        let breakLoop: boolean = false;
        for (let j = 0; j < events.length; j++) {
            const secondEvent: ICalendarEvent = events[j];
            if (oneEvent._id && secondEvent._id && oneEvent._id !== secondEvent._id && canBeMergedNew(oneEvent, secondEvent) === true) {
                toReturn = true;
                breakLoop = true;
                break;
            }
        }
        if (breakLoop === true) {
            break;
        }
    }
    return toReturn;
}


export const mergeEventsArray = (events: ICalendarEvent[]): ICalendarEvent[] => {
    let toReturn: ICalendarEvent[] = [];
    let mergedCompletely: boolean = true;
    for (let i = 0; i < events.length; i++) {
        let merged: boolean = false;
        for (let j = 0; j < toReturn.length; j++) {
            if (canBeMergedNew(toReturn[j], events[i])) {
                toReturn[j] = mergeTwoEventsNew(toReturn[j], events[i]);
                merged = true;
                mergedCompletely = false;
                break;
            }
        }
        if (!merged) {
            toReturn.push(events[i]);
        }
        else {
            toReturn = mergeEventsArray(toReturn);
        }
    }
    // if (canMergeEventsArray(events) === true) {
    //     console.log('not merged completely', new Date());

    //     toReturn = mergeEventsArray(toReturn);
    // }
    // console.log("toReturn is: ", toReturn);
    return toReturn;
}


// export const mergeEventsArray = (events: ICalendarEvent[]): ICalendarEvent[] => {
//     const arranged: ICalendarEvent[] = [];
//     events = events.sort((a: ICalendarEvent, b: ICalendarEvent) => {
//         return new Date(a.time_started).getTime() - new Date(b.time_started).getTime();
//     })
//     for (let i = 0; i < events.length; i++) {
//         const ev: ICalendarEvent = events[i];
//         let didMerge: boolean = false;
//         console.log("i is: ", i);
//         for (let j = 0; j < arranged.length; j++) {
//             const oneArranged: ICalendarEvent = arranged[j];
//             if (canBeMerged(oneArranged, ev) === true) {
//                 arranged[j] = mergeTwoEvents(oneArranged, ev);
//                 didMerge = true;
//                 break;
//             }
//             // else {
//             //     arranged.push(ev);
//             // }

//         }
//         if (!didMerge) {
//             arranged.push(ev);
//         }
//     }
//     return arranged;
// }


export const mergeMappedEvents = (mapped: any) => {
    for (const year in mapped) {
        if (Object.prototype.hasOwnProperty.call(mapped, year)) {
            for (const month in mapped[year]) {
                if (Object.prototype.hasOwnProperty.call(mapped[year], month)) {
                    for (const day in mapped[year][month]) {
                        if (Object.prototype.hasOwnProperty.call(mapped[year][month], day)) {
                            // mapped[year][month][day] = mergeEventsArray(mapped[year][month][day]);
                            mapped[year][month][day] = mergeEventsArray(mapped[year][month][day]);

                            // let idx: number = 0;
                            // while (canMergeEventsArray(mapped[year][month][day]) === true) {
                            //     idx++;
                            //     console.log("idx is: ", idx);
                            //     mapped[year][month][day] = mergeEventsArray(mapped[year][month][day]);
                            // }
                        }
                    }
                }
            }

        }
    }
    return mapped;
}

export const indexEvents = (events: ICalendarEvent[]) => {
    let toReturn = {};
    for (let i = 0; i < events.length; i++) {
        let ev: ICalendarEvent = toJS(events[i]);
        let year: string = moment(ev.time_started).format('YYYY');
        let month: string = moment(ev.time_started).format('MM');
        let day: string = moment(ev.time_started).format('DD');
        if (!toReturn[year]) {
            toReturn[year] = {};
        }
        if (!toReturn[year][month]) {
            toReturn[year][month] = {};
        }
        if (!toReturn[year][month][day]) {
            toReturn[year][month][day] = [];
        }
        toReturn[year][month][day].push(ev);
    }
    return toReturn;
}

export const mapEvents = (events: ICalendarEvent[]) => {
    let indexedEvents = indexEvents(events);
    let mapped = mergeMappedEvents(indexedEvents);
    return mapped;

}

export function mapEventsOld(events: ICalendarEvent[]) {
    let toReturn = {};
    for (let i = 0; i < events.length; i++) {
        let ev: ICalendarEvent = toJS(events[i]);
        let year: string = moment(ev.time_started).format('YYYY');
        let month: string = moment(ev.time_started).format('MM');
        let day: string = moment(ev.time_started).format('DD');
        let merged: boolean = false;

        if (!toReturn[year]) {
            toReturn[year] = {};
        }
        if (!toReturn[year][month]) {
            toReturn[year][month] = {};
        }
        if (!toReturn[year][month][day]) {
            toReturn[year][month][day] = [];
        }
        // toReturn[year][month][day].push(ev);
        else {
            for (let j = 0; j < toReturn[year][month][day].length; j++) {
                let dayEvent: ICalendarEvent = toJS(toReturn[year][month][day][j]);
                const dayEventStarted: number = new Date(dayEvent.time_started).getTime();
                const dayEventEnded: number = new Date(dayEvent.time_ended).getTime();
                const eventStarted: number = new Date(ev.time_started).getTime();
                const eventEnded: number = new Date(ev.time_ended).getTime();
                if (
                    dayEvent.type === ev.type &&
                    (
                        (dayEventStarted >= eventStarted && dayEventStarted <= eventEnded) ||
                        (dayEventEnded >= eventStarted && dayEventEnded <= eventEnded)
                    )
                ) {
                    const startTime: number = dayEventStarted < eventStarted ? dayEventStarted : eventStarted;
                    const startEnded: number = dayEventEnded > eventEnded ? dayEventEnded : eventEnded;
                    toReturn[year][month][day][j] = {
                        ...toReturn[year][month][day][j],
                        time_started: new Date(startTime),
                        time_ended: new Date(startEnded),
                    };
                    if (!toReturn[year][month][day][j].merged) {
                        toReturn[year][month][day][j].merged = [dayEvent, ev];
                        if (dayEvent.merged) {
                            toReturn[year][month][day][j].merged = toReturn[year][month][day][j].merged.concat(dayEvent.merged);
                        }
                    } else {
                        toReturn[year][month][day][j].merged.push(ev);
                    }
                    for (let x = 0; x < toReturn[year][month][day].length; x++) {
                        const pastDay: ICalendarEvent = toReturn[year][month][day][x];
                        if (toReturn[year][month][day][j] && pastDay && canBeMerged(toReturn[year][month][day][j], pastDay)) {

                            toReturn[year][month][day][j] = mergeTwoEvents(toReturn[year][month][day][j], pastDay);
                            // toReturn[year][month][day][j].merged.push(toReturn[year][month][day][x]);
                            toReturn[year][month][day].splice(x, 1);
                        }
                    }
                    merged = true;
                }
            }
        }
        if (!merged) {
            toReturn[year][month][day].push(ev);
        }
    }
    // const arrangedEvents = mergeMappedEvents(toReturn);
    // return arrangedEvents;
    return toReturn;
}


export function toEventsArray(data: any, unmerge?: boolean): ICalendarEvent[] {
    let toReturn: ICalendarEvent[] = [];
    for (const year in data) {
        if (data.hasOwnProperty(year)) {
            const yearObject = data[year];
            for (const month in yearObject) {
                if (yearObject.hasOwnProperty(month)) {
                    const monthObject = yearObject[month];
                    for (const day in monthObject) {
                        if (monthObject.hasOwnProperty(day)) {
                            const dayArray = monthObject[day];
                            for (let dayIndex = 0; dayIndex < dayArray.length; dayIndex++) {
                                toReturn.push(dayArray[dayIndex]);
                            }
                        }
                    }
                }
            }
        }
    }
    return toReturn;
}

export interface IEventType {
    name: string;
    color: string;
}

export const eventTypes: IEventType[] = [
    {
        name: "phone",
        color: MKColor.Cyan,

    },
    {
        name: "at_the_location",
        color: MKColor.DeepPurple,

    },
    {
        name: "home",
        color: MKColor.LightGreen,

    },
    {
        name: "meeting_request-at_the_location",
        color: MKColor.Yellow,
    },
];


export function getEventByName(name: string): IEventType {
    let toReturn: IEventType;
    for (let i = 0; i < eventTypes.length; i++) {
        const evType: IEventType = eventTypes[i];
        if (evType.name === name) {
            toReturn = evType;
        }

    }
    return toReturn;
}


