import 'react-native-gesture-handler';
import React, { useEffect } from 'react';
import { Provider } from 'mobx-react';
import { stores } from './stores';
import Navigation from './components/Navigation';
import Dialog from './components/dialog/Dialog';
import '@expo/match-media';
import { initApp } from './services/init.service';





export default function App() {
    useEffect(() => { initApp(stores); });
    return (
        <Provider store={stores}>
            <Dialog />
            <Navigation />
        </Provider>
    );
}
