import React, { FunctionComponent } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { RouteProp } from "@react-navigation/native";
import { observer } from 'mobx-react';
import MonthCalendar from "../calendar/MonthCalendar";
import { Stores, useStore } from "../../stores";
import { Text, StyleSheet, ScrollView } from "react-native";
import NextButton from "../forms/NextButton";
import SelectTimeFrame from "./SelectTimeFrame";
import Seperator from "../forms/Seperator";
import moment from "moment";




export interface ShowingThePlaceAvailabilityProps {

}

type ShowingThePlaceAvailabilityScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'showing_the_place_availability'>;

type ShowingThePlaceAvailabilityScreenRouteProp = RouteProp<NewItemNavigationParams, 'showing_the_place_availability'>;

interface ShowingThePlaceAvailability {
    navigation: ShowingThePlaceAvailabilityScreenNavigationProp;
    route: ShowingThePlaceAvailabilityScreenRouteProp;
}

const ShowingThePlaceAvailability: FunctionComponent<ShowingThePlaceAvailability> = (props: ShowingThePlaceAvailability) => {
    const store: Stores = useStore().store;

    return (
        <ScrollView style={styles.container}>
            <Text style={styles.label}>{store.translations.translations.at_the_location}</Text>
            <Text style={styles.text}>{store.translations.translations.showing_the_place_explanation}</Text>
            <Seperator />
            <SelectTimeFrame />
            <Seperator />
            <MonthCalendar
                num_of_days_in_week={7}
                selected_date={new Date()}
                week_starts_at={store.user.week_starts_at}
                onSelect={(date: Date) => {
                    store.new_apartment.setSelectedDay(moment(date).set({ hours: 17 }).toDate());
                    store.new_apartment.setManulalAvailabiliyData({
                        time_started: moment(date).set({ hours: 17 }).toDate(),
                        time_ended: moment(date).set({ hours: 20 }).toDate(),
                        hours: 17,
                        minutes: 0,
                        type: 'at_the_location',
                        event: null,
                    });

                    props.navigation.navigate('add_availability_manual');

                }}
                mappedEvents={store.new_apartment.mapped_events}
                event_types={['phone', 'at_the_location']}
                click_only_future={true}
            />
            <NextButton
                onNext={() => { props.navigation.navigate('add_media'); }}
                title={store.translations.translations.continue}
                active={true}
            />
        </ScrollView>
    );
}
export default observer(ShowingThePlaceAvailability);

const styles = StyleSheet.create({
    container: {
    },
    label: {
        fontSize: 24,
        textAlign: 'center',
    },
    text: {
        textAlign: 'center'
    }
})