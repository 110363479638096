import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { Stores, useStore } from "../../stores";
import { View, Text, StyleSheet } from "react-native";
import RNPickerSelect from "../forms/picker/Picker";


const SelectTimeFrame: FunctionComponent = () => {
    const store: Stores = useStore().store;
    const timeFrames = [
        { label: '10', value: 10 },
        { label: '15', value: 15 },
        { label: '20', value: 20 },
        { label: '25', value: 25 },
        { label: '30', value: 30 },
    ];

    return (
        <View>
            <Text style={styles.mainText}>{store.translations.translations.select_time_frame}</Text>
            <Text style={styles.explanationText}>{store.translations.translations.select_time_frame_explanation}</Text>
            <RNPickerSelect
                items={timeFrames}
                style={styles.picker}
                // useNativeAndroidPickerStyle={true}
                value={store.new_apartment.showing_the_place_time_frame}
                pickerProps={{ mode: 'dropdown' }}
                onValueChange={(itemValue) => { store.new_apartment.setShowingThePlaceTimeFrame(Number(itemValue)); }}
            />
        </View>
    )
}

export default observer(SelectTimeFrame);

const styles = StyleSheet.create({
    container: {

    },
    picker: {
        borderColor: 'black',
        borderWidth: 1,
        borderStyle:'solid',
        borderRadius:10,
    },
    mainText: {
        fontSize: 20,
        textAlign: 'center'
    },
    explanationText: {
        textAlign: 'center'
    }
})