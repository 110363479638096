import { createStackNavigator } from "@react-navigation/stack";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import OpenDrawer from "../components/OpenDrawer";
import EmailConfirmed from "../components/profile/EmailConfirmed";
import { ProfileProps } from "../components/profile/Profile.interface";
import ProfilePageInner from '../components/profile/ProfilePage';
import { useStores } from "../stores";


const Stack = createStackNavigator();

const ProfilePage: FunctionComponent<ProfileProps> = function (props: ProfileProps) {
    const store = useStores();
    return (
        <Stack.Navigator>
            <Stack.Screen
                component={ProfilePageInner}
                name="profile_page"
                options={{
                    headerTitle: store.translations.translations.profile,
                    headerLeft: () => (<OpenDrawer navigation={props.navigation} />)
                }}
            />
            <Stack.Screen
                component={EmailConfirmed}
                name="email_confirmation"
                options={{
                    headerTitle: store.translations.translations.email,
                    headerLeft: () => (<OpenDrawer navigation={props.navigation} />)
                }}
            />
        </Stack.Navigator>
    )
}
export default observer(ProfilePage);
