import React from 'react';
import { View, StyleSheet, TouchableOpacity, Platform } from "react-native";
import { Icon } from 'react-native-elements';
import { observer } from "mobx-react";
import { DrawerActions } from '@react-navigation/native';
// import { faBars } from "@fortawesome/pro-regular-svg-icons";

export interface OpenDrawerParams {

}

const OpenDrawer = ({ navigation }) => {
    return (
        <View style={styles.openMapView}>
            <TouchableOpacity>

                <Icon
                    color="rgba(255, 255, 255, 0.7)"
                    name="bars"
                    reverse
                    reverseColor="blue"
                    type="font-awesome"
                    style={styles.openMenuButton}
                    onPress={() => {
                        navigation.dispatch(DrawerActions.openDrawer());
                    }}
                />
            </TouchableOpacity>

        </View>

    )
}

const styles = StyleSheet.create({
    openMapView: {
        position: "absolute",
        zIndex: 15,
        flex: 1,
        marginTop: Platform.select({
            android: 0,
            ios: 25,
        })
    },
    openMenuButton: {
        position: "absolute",
        zIndex: 16
    },
});


export default observer(OpenDrawer);