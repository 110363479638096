import React, { FunctionComponent } from 'react'
import { StyleSheet, Text, ImageBackground } from 'react-native';
import { Stores, useStore } from '../../stores';

import { getCountryImage } from '../CoutriesImages';
import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react';
import RippleButton from './RippleButton';


export interface CountryPickerProps {
    value: string;
}

const CountryPicker: FunctionComponent<CountryPickerProps> = (props: CountryPickerProps) => {
    const store: Stores = useStore().store;
    const navigation = useNavigation();
    return (
        <RippleButton
            style={styles.container}
            onPress={() => {
                navigation.navigate('select_country_phone');
            }}
        >
            <ImageBackground
                source={getCountryImage(store.translations.user_country?.name)}
                style={styles.countryImage}
            ></ImageBackground>
            <Text>+ {store.translations.user_country?.phone_code}</Text>
        </RippleButton>
    )
}

export default observer(CountryPicker);




const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ecf0f1',
        flexDirection: 'row',
        borderRadius: 15,
        // marginTop:16,
    },
    countryImage: {
        width: 22,
        height: 22,
    },
    instructions: {},
    data: {},
})