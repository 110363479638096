import React, { FunctionComponent, useState } from 'react';
import Swiper from 'react-native-swiper';
import { View, StyleSheet, Text, Dimensions } from 'react-native';
import { IMatrix, IDayInMonth, getWeekDays, getMonthMatrix } from './utils';
import TouchableView from '../TouchableView';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { Stores, useStore } from '../../stores';
import Day from './Day';
// @ts-ignore
import { ICalendarEvent } from 'go2c-shared';
import MKColor from "../../services/palette.service";

import { MomentInput } from 'moment';
// import 'moment/locale/fr';

interface CalendarProps {
    num_of_days_in_week?: number;
    selected_date: Date;
    week_starts_at?: string;
    months_to_load?: number;
    mappedEvents?: any;
    onSelect(day: Date): void;
    showPastMonths?: boolean;
    enableLoop?: boolean;
    day_event_types?: string[];
    event_types?: string[];
    click_only_future?: boolean;
}




const MonthCalendar: FunctionComponent<CalendarProps> = function (props: CalendarProps) {
    const store: Stores = useStore().store;
    const mappedEvents = props.mappedEvents;

    const [selectedDate, setSelectedDate] = useState(props.selected_date || new Date());
    const num_of_days_in_week: number = props.num_of_days_in_week || 7;
    const weekDaysArray: string[] = getWeekDays(props.week_starts_at, num_of_days_in_week);
    const months_to_load: number = props.months_to_load || 2;
    let pastMonths: IMatrix[] = [];
    let futureMonths: IMatrix[] = [];
    for (let i = 0; i < months_to_load; i++) {
        if (props.showPastMonths) {
            pastMonths.unshift(getMonthMatrix(moment(props.selected_date).subtract(i + 1, 'month').toDate(), weekDaysArray));
        }
        futureMonths.push(getMonthMatrix(moment(props.selected_date).add(i + 1, 'month').toDate(), weekDaysArray));
    }
    const presentMonth = getMonthMatrix(props.selected_date, weekDaysArray);
    let allMonths: IMatrix[] = [presentMonth].concat(futureMonths);
    if (props.showPastMonths) {
        allMonths = pastMonths.concat(allMonths);
    }
    return (
        <Swiper
            style={styles.monthsContainer}
            showsHorizontalScrollIndicator={true}
            showsPagination={false}
            showsButtons={false}
            loop={props.enableLoop || false}
            index={props.showPastMonths ? months_to_load : store.user.language_direction === 'ltr' ? 0 : allMonths.length - 1}
        >
            {allMonths.map((oneMonth: IMatrix, monthIndex: number) => (
                <View style={styles.month} key={"month_in_calendar_" + monthIndex.toString()}>
                    <Text>{moment(oneMonth[1][0].date as MomentInput as unknown).add(1, 'hour').format("MMMM YYYY")}</Text>

                    <View style={styles.dayHeader} key={"month_header_container_" + monthIndex.toString()}>

                        {weekDaysArray.map((day: string) => (
                            day &&
                            <View style={styles.day} key={"month_header_" + monthIndex.toString() + '_' + day}>
                                <Text>{day}</Text>
                            </View>
                        ))}
                    </View>
                    {oneMonth.map((row: IDayInMonth[], rowIndex: number) => (
                        <View style={styles.monthRow} key={rowIndex}>
                            {row.map((day: IDayInMonth, dayIndex: number) => {
                                let innerStyles = styles.day;
                                let textStyle = {
                                    backgroundColor: 'white',
                                    color: MKColor.BlueGrey
                                };
                                let hasEvents: boolean = false;
                                let events: ICalendarEvent[];
                                if (day.inMonth === true) {
                                    innerStyles = { ...innerStyles, ...styles.dayInMonth };
                                } else {
                                    innerStyles = { ...innerStyles, ...styles.dayNotInMonth };
                                }
                                if (day.date.isSame(selectedDate, "day")) {
                                    innerStyles = { ...innerStyles, ...styles.selectedDay }
                                }
                                if (
                                    mappedEvents &&
                                    mappedEvents[moment(day.date as MomentInput as unknown).format('YYYY')] &&
                                    mappedEvents[moment(day.date as MomentInput as unknown).format('YYYY')][moment(day.date as MomentInput as unknown).format('MM')] &&
                                    mappedEvents[moment(day.date as MomentInput as unknown).format('YYYY')][moment(day.date as MomentInput as unknown).format('MM')][moment(day.date as MomentInput as unknown).format('DD')]
                                ) {
                                    innerStyles = { ...innerStyles, ...styles.selectedDay };
                                    hasEvents = true;
                                    events = mappedEvents[moment(day.date as MomentInput as unknown).format('YYYY')][moment(day.date as MomentInput as unknown).format('MM')][moment(day.date as MomentInput as unknown).format('DD')];
                                }
                                const canClickDay: boolean = props.click_only_future === true && day.date.isBefore(new Date());
                                if (canClickDay === true) {
                                    textStyle.backgroundColor = '#e4ebe5'
                                }

                                return (
                                    <TouchableView
                                        style={{ ...innerStyles, backgroundColor: canClickDay ? MKColor.Grey : 'white', flex: 1 }}
                                        key={rowIndex + '_' + dayIndex}
                                        onPress={() => {
                                            props.onSelect(day.date.toDate());
                                            setSelectedDate(day.date.toDate());
                                        }}
                                        disabled={canClickDay}
                                    >
                                        <Text style={textStyle}>{day.date.format("DD")}</Text>
                                        <Day
                                            events={events}
                                            start={6}
                                            end={24}
                                            event_types={props.event_types}
                                        />
                                    </TouchableView>
                                )

                            })}
                        </View>

                    ))}
                </View>
            ))}
        </Swiper>

    )
}

export default observer(MonthCalendar);


const styles = StyleSheet.create({
    container: {
    },
    monthRow: {
        flexDirection: "row",
        flex: 1,
        width: Dimensions.get('window').width

    },
    dayInMonth: {
        backgroundColor: 'white',
        borderColor: MKColor.Grey,
        borderWidth: 1,

    },
    dayNotInMonth: {
        backgroundColor: '#b1bdb4',

    },
    day: {
        flex: 1,
        // flexWrap: "wrap",
        backgroundColor: MKColor.LightBlue,
    },
    dayHeader: {
        height: 25,
        display: 'flex',
        flexDirection: 'row',
        width: Dimensions.get('window').width
    },
    dayNumber: {
        backgroundColor: '#e4ebe5',
        color: MKColor.BlueGrey
    },
    oneView: {
    },
    month: {
        flex: 1,
        alignItems: "center",
    },
    monthsContainer: {
        flexWrap: "wrap",
        alignItems: "stretch",
        flexDirection: "row",

    },
    selectedDay: {
        backgroundColor: MKColor.Green
    },
    backDay: {
        backgroundColor: MKColor.Grey,
    },
    futureDay: {
        backgroundColor: 'white'
    }
});
