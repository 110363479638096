import React, { useEffect } from 'react';

import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp, EventArg, useRoute } from "@react-navigation/native";
import { CurrentItemNavigationParams } from "../../pages/Map.page";
import MonthCalendar from '../calendar/MonthCalendar';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Stores, useStore } from '../../stores';
import { mapEvents } from '../../services/events.service';
import { ActivityIndicator, View, Text, StyleSheet } from 'react-native';
import { IGetAvailabilitiesResponse, IPhone } from 'go2c-shared';
import Request from "../../services/axios.service";
import { useState } from 'react';

type CurrentItemCalendarNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'availability'>;

type CurrentItemCalendarRouteProp = RouteProp<CurrentItemNavigationParams, 'availability'>;

interface CurrentItemCalendarParams {
    navigation: CurrentItemCalendarNavigationProp;
    route: CurrentItemCalendarRouteProp;
}

const CurrentItemCalendar: React.FC<CurrentItemCalendarParams> = (props: CurrentItemCalendarParams) => {
    const store: Stores = useStore().store;
    const route = useRoute();
    const mappedEvents = mapEvents(toJS(store.items.current_item_availabilities));
    const [didInit, setDidInit] = useState(false)
    const getAvailability = async () => {
        store.items.setCalendarLoading(true);
        props.navigation.navigate("availability", route.params);
        const fromRequest: IGetAvailabilitiesResponse = await Request({
            method: "GET",
            url: "items/" + route.params.item_id + '/availabilities'
        });
        if(fromRequest?.availabilities) {

            store.items.setAvailabilities(fromRequest.availabilities);
            if (fromRequest.has_meeting === true) {
                store.items.setHasMeetingWithCurrentItem(fromRequest.meeting);
            }
        } else {
            props.navigation.navigate('map')
        }
        store.items.setCalendarLoading(false);

    }

    useEffect(() => {
        if (!didInit) {
            getAvailability();
            setDidInit(true)
        }
    }, [store, getAvailability, didInit, setDidInit]);
    return (
        <>
            {
                store.items.calendar_loading === false && store.items.current_item_availabilities ?

                    <MonthCalendar
                        selected_date={new Date()}
                        onSelect={(fromSelect: Date) => {
                            store.items.setCurrentDay(fromSelect);
                            props.navigation.navigate('day', { item_id: route.params.item_id as string, event_types: ["phone", "at_the_location"] });
                        }}
                        // mappedEvents={mappedEvents}
                        mappedEvents={mapEvents(toJS(store.items.current_item_availabilities))}

                        event_types={["phone", "at_the_location"]}
                    />
                    :
                    <View style={{ height: 350 }}>
                        <ActivityIndicator style={styles.spinner} size="large" color="#5ba7fc" />
                        <Text style={styles.lodingText}>{store.translations.translations.loading_availabilities}</Text>
                    </View>

            }
        </>
    )
}

export default observer(CurrentItemCalendar);

const styles = StyleSheet.create({
    spinner: {
        flex: 1,
        padding: 150,
        height: 250
    },
    lodingText: {
        fontSize: 26,
        textAlign: 'center',
    }
})