import React, { FunctionComponent, useEffect, useState } from 'react';
import { Animated, Platform, StyleSheet, TextInput, View } from 'react-native';
import FloatLabelTextInput from 'react-native-floating-label-text-input';
import MKColor from '../../../services/palette.service';
import { TextFieldProps } from './TextField.interface';

const TextField: FunctionComponent<TextFieldProps> = (props: TextFieldProps) => {
    const [titlePosition, setTitlePosition] = useState(new Animated.Value(0));
    const [undelineWidth, setUnderlineWidth] = useState(new Animated.Value(0));
    const [undelineColor, setUnderlineColor] = useState(new Animated.Value(0));
    const [titleFontSize, setTitleFontSize] = useState(new Animated.Value(0));
    const [value, setValue] = useState<string>(props.value);
    const animationTime = 200;

    const titlePositionInterpolation = titlePosition.interpolate({
        inputRange: [0, 1],
        outputRange: [
            Platform.select({
                android: 15,
                web: 0,
                ios: 0,
            }),
            Platform.select({
                android: -10,
                web: -15,
                ios: -20,
            }),
        ],
    });

    const underlineWidthInterpolation = undelineWidth.interpolate({
        inputRange: [0, 1],
        outputRange: ['0%', '100%'],
    });

    const underlineColorInterpolation = undelineWidth.interpolate({
        inputRange: [0, 1],
        outputRange: ['grey', 'blue'],
    });
    const titleFonSizeInterpolation = titleFontSize.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 12],
    });

    const handleAnimation = () => {
        Animated.timing(titlePosition, {
            toValue: 1,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
        // setTitlePosition(new Animated.Value(1));
        Animated.timing(undelineWidth, {
            toValue: 1,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
        // setUnderlineWidth(new Animated.Value(1));

        Animated.timing(undelineColor, {
            toValue: 1,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
        // setUnderlineColor(new Animated.Value(1));

        Animated.timing(titleFontSize, {
            toValue: 1,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
        // setTitleFontSize(new Animated.Value(1));
    };

    const closeAnimation = () => {
        Animated.timing(titlePosition, {
            toValue: 0,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
        Animated.timing(undelineWidth, {
            toValue: 0,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
        Animated.timing(undelineColor, {
            toValue: 0,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
        Animated.timing(titleFontSize, {
            toValue: 0,
            duration: animationTime,
            useNativeDriver: false,
        }).start();
    };
    useEffect(() => {
        if (value === '' || !value) {
            closeAnimation();
        } else {
            handleAnimation();
        }
    }, [value]);
    return (
        <View style={[styles.container, props.style]}>
            {/* <FloatLabelTextInput
                placeholder={props.label}
                onChangeTextValue={props.onChangeText}
                value={props.value as string}
                secureTextEntry={props.password}
                selectionColor={'red'}
                noBorder={false}
                style={{ outlineWidth: 0 }}
            /> */}

            {/* <Input
                label="aaaa"
                placeholder="bbb"
            /> */}
            <Animated.Text
                style={[
                    styles.label,
                    {
                        top: titlePositionInterpolation,
                        color: underlineColorInterpolation,
                        fontSize: titleFonSizeInterpolation,
                    },
                ]}>
                {props.label}
            </Animated.Text>
            <TextInput
                value={value}
                multiline={props.multiline}
                numberOfLines={props.numberOfLines}
                onChangeText={(text: string) => {
                    setValue(text);
                    props.onChangeText(text);
                }}
                keyboardType={props.keyboardType || 'default'}
                autoCompleteType={props.autoCompleteType}
                secureTextEntry={props.password || false}
                onFocus={handleAnimation}
                style={styles.input}
                onBlur={() => {
                    if (value === '' || !value) {
                        closeAnimation();
                    }
                }}
            />
            <View style={styles.underlineContainer}>
                <Animated.View
                    style={[
                        styles.underline,
                        { width: underlineWidthInterpolation, backgroundColor: underlineColorInterpolation },
                    ]}></Animated.View>
            </View>
        </View>
    );
};
export default TextField;

const styles = StyleSheet.create({
    container: {
        // marginTop: 40,
    },
    label: {
        color: 'blue',
        position: 'absolute',
    },
    underline: {
        backgroundColor: MKColor.Grey,
        height: 1,
        alignSelf: 'center',
    },
    input: {
        marginBottom: -8,
        outlineWidth: 0,
    },
    underlineContainer: {
        width: '100%',
        height: 1,
        backgroundColor: MKColor.Grey,
        marginTop: Platform.select({
            ios: 15,
            android: 0,
            web: 10,
        }),
    },
});
