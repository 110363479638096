import React, { FunctionComponent, useState } from "react";
import { Text, View, StyleSheet } from "react-native";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import { Stores, useStore } from "../../stores";
import NumericInput from "../NumericInput";
// @ts-ignore
import { ICalendarEvent } from "go2c-shared";
import AvailabilitySlider from "./AvailabilitySlider";
import MKColor from "../../services/palette.service";
import RippleButton from "../forms/RippleButton";


interface BulkAvailabilityProps {
    minimumTime: number;
    maximunTime: number;
    type: string;
    title: string

}

const BulkAvailability: FunctionComponent<BulkAvailabilityProps> = function (props: BulkAvailabilityProps) {
    const store: Stores = useStore().store;

    const [daysAhead, setDaysAhead] = useState(100);

    const [minWeekDays, setMinWeekDays] = useState("09:00");
    const [maxWeekDays, setMaxWeekDays] = useState("22:00");
    const [availableOnWeekDays, setAvailableOnWeekDays] = useState<boolean>(true);

    const [minWeekendEve, setMinWeekendEve] = useState("09:00");
    const [maxWeekendEve, setMaxWeekendEve] = useState("15:30");
    const [availableOnWeekendEve, setAvailableOnWeekendEve] = useState<boolean>(true);

    const [minWeekend, setMinWeekend] = useState("20:00");
    const [maxWeekend, setMaxWeekend] = useState("23:30");
    const [availableOnWeekend, setAvailableOnWeekend] = useState<boolean>(true);

    const setDays = (val: number) => {
        setDaysAhead(val < 0 ? 0 : val);
    }

    const availableAtDay = (isWeekend: boolean, isWeekendEve: boolean): boolean => {
        if (
            (availableOnWeekend && isWeekend) ||
            (availableOnWeekendEve && isWeekendEve) ||
            (availableOnWeekDays && !isWeekendEve && !isWeekend)
        ) {
            return true;
        } else {
            return false;
        }
    }

    const addBulk = () => {
        const fromTimeHours: number = Number(minWeekDays.split(':')[0]);
        const fromTimeMinutes: number = Number(minWeekDays.split(':')[1]);
        const toTimeHours: number = Number(maxWeekDays.split(':')[0]);
        const toTimeMinutes: number = Number(maxWeekDays.split(':')[1]);
        let events: ICalendarEvent[] = [];
        for (let i = 0; i < daysAhead; i++) {
            let day: Moment = moment(new Date()).add(i, 'days');
            const isWeekend: boolean = day.weekday() === 6;
            const isWeekendEve: boolean = day.weekday() === 5;
            if (availableAtDay(isWeekend, isWeekendEve)) {

                let started: Moment = moment(new Date()).add(i, 'days').set({ hours: fromTimeHours, minutes: fromTimeMinutes });
                let ended: Moment = moment(new Date()).add(i, 'days').set({ hours: toTimeHours, minutes: toTimeMinutes });

                if (isWeekendEve) {
                    started.set({ hours: Number(minWeekendEve.split(':')[0]), minutes: Number(minWeekendEve.split(':')[1]) });
                    ended.set({ hours: Number(maxWeekendEve.split(':')[0]), minutes: Number(maxWeekendEve.split(':')[1]) });

                }
                if (isWeekend) {
                    started.set({ hours: Number(minWeekend.split(':')[0]), minutes: Number(minWeekend.split(':')[1]) });
                    ended.set({ hours: Number(maxWeekend.split(':')[0]), minutes: Number(maxWeekend.split(':')[1]) });
                }
                if (started.isAfter(ended)) {
                    ended.set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
                }
                events.push({
                    type: props.type,
                    time_started: started.toDate(),
                    time_ended: ended.toDate(),
                    title: props.title,
                    description: "I am available for a " + props.type,
                    item: null
                });
            }
        }
        store.new_apartment.addEvents(events);

    }
    return (
        <View style={styles.container}>
            <Text style={styles.title}>{props.title}</Text>
            <AvailabilitySlider
                available={availableOnWeekDays}
                setAvailable={(available: boolean) => { setAvailableOnWeekDays(available); }}
                minVal={minWeekDays}
                setMinVal={setMinWeekDays}
                maxVal={maxWeekDays}
                setMaxVal={setMaxWeekDays}
                title={store.translations.translations.week_days}
            />
            <AvailabilitySlider
                available={availableOnWeekendEve}
                setAvailable={(available: boolean) => { setAvailableOnWeekendEve(available); }}
                minVal={minWeekendEve}
                setMinVal={setMinWeekendEve}
                maxVal={maxWeekendEve}
                setMaxVal={setMaxWeekendEve}
                title={moment(new Date()).set({ weekday: 5 }).format('dddd')}
            />
            <AvailabilitySlider
                available={availableOnWeekend}
                setAvailable={(available: boolean) => { setAvailableOnWeekend(available); }}
                minVal={minWeekend}
                setMinVal={setMinWeekend}
                maxVal={maxWeekend}
                setMaxVal={setMaxWeekend}
                title={moment(new Date()).set({ weekday: 6 }).format('dddd')}
            />
            <View style={styles.daysAheadContainer}>
                <Text style={styles.forDaysText}>{store.translations.translations.for}</Text>
                <View style={{ flex: 5 }}>
                    <NumericInput onchange={setDays} value={daysAhead} flex={2} extraButtons={[7, 30]} />
                </View>
                <Text style={styles.forDaysText}>{store.translations.translations.days}</Text>
            </View>

            {/*
            */}
            <RippleButton
                onPress={() => { addBulk() }}
                style={styles.addBulkButton}
            >
                <Text style={styles.addBulkButtonText}>{store.translations.translations.add}</Text>
            </RippleButton>
        </View>
    )
}

export default observer(BulkAvailability);

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    slider: {
        width: 340,
        height: 80,
        marginTop: -20

    },
    sliderContainer: {
        flex: 1,
        alignContent: "center",
        alignItems: "center"
    },
    input: {
        fontSize: 20,
        flex: 1,
        borderWidth: 1,
        borderColor: "gray"
    },
    pickerContainer: {
        flex: 1,
        paddingTop: 40,
        alignItems: "center"
    },
    fromToWrapper: {
        flexDirection: "row",
    },
    fromToText: {
        marginRight: 10
    },
    typeOfDaysHeader: {
        fontWeight: "bold",
        fontSize: 24
    },
    iAmAvailableBetween: {
        fontSize: 22
    },
    daysAheadContainer: {
        flexDirection: "row",
        flex: 1,

        alignItems: "stretch"
    },
    forDaysText: {
        flex: 1,
        fontSize: 22,
    },
    title: {
        flex: 1,
        textAlign: "center",
        fontSize: 26,
        fontWeight: "bold"
    },
    addBulkButton: {
        backgroundColor: MKColor.Blue,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        margin: 10,
    },
    addBulkButtonText: {
        color: "white",
        fontSize: 25,
    },
})