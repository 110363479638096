import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import { Stores, useStore } from '../../stores';
import { View, StyleSheet, Text, Switch } from 'react-native';
// import RangeSlider from 'rn-range-slider';
import moment from 'moment-timezone';
import MKColor from "../../services/palette.service";

import Collapsible from 'react-native-collapsible';
import RangeSlider from '../forms/range_slider/RangeSlider';

interface AvailabilitySliderProps {
    minVal: string;
    setMinVal: (val: string) => void;
    maxVal: string;
    setMaxVal: (val: string) => void;
    title: string;
    available: boolean;
    setAvailable: (val: boolean) => void;
}

const AvailabilitySlider: FunctionComponent<AvailabilitySliderProps> = function (props: AvailabilitySliderProps) {
    const store: Stores = useStore().store;
    const [collaped, setCollaped] = useState<boolean>(false);
    const toggleSwitch = (value:boolean) => {
        setCollaped(!collaped);
        props.setAvailable(value);
    }
    return (
        <View>
            <View style={styles.headerWrapper}>
                <Text style={styles.typeOfDaysHeader}>{props.title}</Text>
                <Switch
                    trackColor={{ false: "#767577", true: "#81b0ff" }}
                    thumbColor={collaped ? "#f5dd4b" : "#f4f3f4"}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={toggleSwitch}
                    value={collaped}
                // onColor="rgba(255,152,0,.3)"
                // thumbOnColor={MKColor.Orange}
                // rippleColor="rgba(255,152,0,.2)"
                // checked={!collaped}
                // onCheckedChange={(e) => {
                //     setCollaped(!collaped);
                //     props.setAvailable(e.checked);
                // }}
                />
            </View>
            <Collapsible collapsed={collaped}>
                <Text style={styles.iAmAvailableBetween}>{store.translations.translations.i_am_available_between}</Text>
                <View style={styles.fromToWrapper}>
                    <Text style={styles.fromToText}>{store.translations.translations.from_an_hour}</Text>
                    <Text style={styles.fromToText}>{props.minVal}</Text>
                    <Text style={styles.fromToText}>{store.translations.translations.to_an_hour}</Text>
                    <Text style={styles.fromToText}>{props.maxVal}</Text>
                </View>
                <View style={[
                    styles.sliderContainer,
                    {
                        flexDirection: store.user.user.language.name === 'hebrew' || store.user.user.language.name === 'arabic' ? 'row-reverse' : 'row',
                        marginStart: store.user.user.language.name === 'hebrew' || store.user.user.language.name === 'arabic' ? 0 : 65,
                        marginEnd: 65
                    }
                ]
                }>
                    <Text
                        style={[styles.timeDescription, styles.startTimeDescription]}
                    >
                        {moment(new Date()).set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }).format('HH:mm')}
                    </Text>

                    <RangeSlider
                        // @ts-ignore
                        style={styles.slider}
                        gravity={'center'}
                        step={1000 * 60 * 60 / 4}
                        valueType={"time"}
                        min={moment(new Date()).set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }).toDate().getTime()}
                        initialLowValue={moment(new Date()).set({ hours: Number(props.minVal.split(':')[0]), minutes: Number(props.minVal.split(':')[1]), seconds: 0, milliseconds: 0 }).toDate().getTime()}
                        max={moment(new Date()).set({ hours: 24, minutes: 0, seconds: 0, milliseconds: 0 }).toDate().getTime()}
                        initialHighValue={moment(new Date()).set({ hours: Number(props.maxVal.split(':')[0]), minutes: Number(props.maxVal.split(':')[1]), seconds: 0, milliseconds: 0 }).toDate().getTime()}
                        selectionColor={MKColor.Blue}
                        blankColor={MKColor.Orange}
                        onValueChanged={(low: number, high: number, fromUser: boolean) => {
                            props.setMinVal(moment(low).format("HH:mm"));
                            props.setMaxVal(moment(high).format("HH:mm"));
                        }}
                        textFormat="HH : mm"
                    />
                    <Text
                        style={[styles.timeDescription, styles.endTimeDescription]}>
                        {moment(new Date()).set({ hours: 24, minutes: 0, seconds: 0, milliseconds: 0 }).format('HH:mm')}
                    </Text>

                </View>


            </Collapsible>
        </View>
    )
}

export default observer(AvailabilitySlider);

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    slider: {
        width: 250,
        height: 80,
        marginTop: -20,
    },
    sliderContainer: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 20,
        marginTop: 20,
        alignSelf: 'flex-start',
    },
    input: {
        fontSize: 20,
        flex: 1,
        borderWidth: 1,
        borderColor: "gray"
    },
    timeDescription: {
        position: 'absolute',
        fontSize: 24,
        top: 24,
    },
    startTimeDescription: {
        left: -65,
    },
    endTimeDescription: {
        right: -65,
    },
    pickerContainer: {
        flex: 1,
        paddingTop: 40,
        alignItems: "center"
    },
    fromToWrapper: {
        flexDirection: "row",
    },
    fromToText: {
        marginRight: 10,
        fontSize: 22,
    },
    typeOfDaysHeader: {
        fontWeight: "bold",
        fontSize: 24,
        flex: 1,
    },
    iAmAvailableBetween: {
        fontSize: 18,
    },
    daysAheadContainer: {
        flexDirection: "row",
        flex: 1,

        alignItems: "stretch"
    },
    forDaysText: {
        flex: 1,
        fontSize: 24,
    },
    headerWrapper: {
        flexDirection: 'row'
    },
    notAvailableWrapper: {
        flex: 1,
        flexDirection: 'row'
    },
    notAvailableText: {
        flex: 3
    },
    notAvailableCheckBox: {

    }
})