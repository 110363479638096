import React, { FunctionComponent, useState } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { CurrentItemNavigationParams } from "../pages/Map.page";
import { Text, View, Button, StyleSheet } from 'react-native';
import { observer } from "mobx-react";
import Request from "../services/axios.service";
import { Stores, useStore } from "../stores";
// @ts-ignore
import { IPostMeetingRespose, IPostMeetingRequest } from 'go2c-shared';
import moment from "moment";
import Slider from "@react-native-community/slider";
import { dateToValue, valueToDate } from "../services/utils.service";



type SendInvitationNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'send_invitation'>;

type SendInvitationRouteProp = RouteProp<CurrentItemNavigationParams, 'send_invitation'>;

interface SendInvitationProps {
    navigation: SendInvitationNavigationProp;
    route: SendInvitationRouteProp;
}

const SendInvitation: FunctionComponent<SendInvitationProps> = function (props: SendInvitationProps) {
    const store: Stores = useStore().store;
    const [meetingTime, setMeetingTime] = useState<Date>(
        // moment(new Date(props.route.params.time_started))
        //     .set({ hours: props.route.params.hours, minutes: 0, seconds: 0, milliseconds: 0 })
        //     .toDate()
        moment(store.items.current_day)
            .set({ hours: props.route.params.hours, minutes: 0, seconds: 0, milliseconds: 0 })
            .toDate()

    );

    const sendInvitation = async () => {
        const curDay: Date = meetingTime;
        const curItemId: string = props.route.params.meeting && props.route.params.meeting.event.item ? props.route.params.meeting.event.item._id : store.items.current_item._id;
        const data: IPostMeetingRequest = {
            type: props.route.params.type,
            start: moment(curDay).set({ hours: props.route.params.hours, minutes: props.route.params.minutes }).toDate(),
            end: moment(curDay).set({ hours: props.route.params.hours, minutes: props.route.params.minutes }).add(1, 'hour').toDate(),
            title: "I would like to see the item",
        };
        if (props.route.params.meeting) {
            data.meeting_id = props.route.params.meeting._id;
        }
        try {
            const fromSend: IPostMeetingRespose = await Request({
                method: "POST",
                url: "items/" + curItemId + "/meeting",
                data
            });
            if (fromSend.success) {
                store.dialog.openPopup(store.translations.translations.invitation_sent, store.translations.translations.invitation_sent_body);
                if (!props.route.params.meeting) {
                    props.navigation.navigate('item');
                    store.items.setCurrentDay(data.start);
                } else {
                    // @ts-ignore
                    props.navigation.navigate('my_items');
                }
            }
        } catch (e) {
            console.log('e is: ', e);
        }
    }



    return (
        <View style={[styles.container]}>
            <Text style={[styles.text]}>{store.translations.translations.i_would_like_to_meet_you_at}</Text>
            <Text style={[styles.text]}>{store.translations.translations[props.route.params.type]}</Text>
            <Text style={[styles.text, styles.date]}>{moment(meetingTime).format('LL')}</Text>
            <Text style={[styles.text]}>{store.translations.translations.at_time}</Text>
            <Text style={[styles.text, styles.date]}>{moment(meetingTime).format('HH:mm')}</Text>

            <View style={[styles.sliderContainer]}>
                <Slider
                    style={[styles.slider]}
                    minimumValue={6 * 4}
                    maximumValue={24 * 4}
                    step={1}
                    value={dateToValue(meetingTime)}
                    minimumTrackTintColor="#FFFFFF"
                    maximumTrackTintColor="#000000"
                    onValueChange={(val: number) => { setMeetingTime(valueToDate(val, meetingTime)); }}
                />
            </View>
            <View style={[styles.buttonContainer]}>
                <Button title={store.translations.translations.send_invitation} onPress={sendInvitation} />
            </View>

        </View>
    )
}

export default observer(SendInvitation);


const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
    },
    text: {
        fontSize: 26,
        textAlign: 'center',
        flex: 1,
        alignSelf: 'center',
        paddingTop: 30,
    },
    slider: {
        width: 350,
        height: 40,
        alignSelf: "center"
    },
    date: {
        fontSize: 22,
    },
    sliderContainer: {
        flex: 1,
    },
    buttonContainer: {
        flex: 1,
    }
})