import React, { FunctionComponent } from "react";
import { Picker as RNPickerSelect } from '@react-native-picker/picker';
import { PickerProps, ViewStyle } from "react-native";
import { ItemValue } from "@react-native-picker/picker/typings/Picker";

interface PickerItem {
    label: string;
    value: string | number;
    color?: string;
}

interface PickerComponentProps {
    items: PickerItem[];
    style?: ViewStyle;
    value: string | number;
    onValueChange?: (itemValue: ItemValue, itemIndex: number) => void;
    placeholder?: PickerItem;
    pickerProps: PickerProps;
}

const Picker: FunctionComponent<PickerComponentProps> = (props: PickerComponentProps) => {
    return (
        <RNPickerSelect
            selectedValue={props.value}
            style={props.style}
            onValueChange={props.onValueChange}
            {...props.pickerProps}
        >
            <RNPickerSelect.Item label={props.placeholder?.label || '...'} color={props.placeholder?.color || 'grey'} value={props.placeholder?.value} key={0} />

            {
                props.items.map((item, i) => (
                    <RNPickerSelect.Item label={item.label} value={item.value} color={item.color} key={i + 1} />
                ))
            }

        </RNPickerSelect>
    )
}
export default Picker;