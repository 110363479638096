import React, { FunctionComponent, useState } from 'react';
// @ts-ignore
import { StackNavigationProp } from "@react-navigation/stack";
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { RouteProp } from "@react-navigation/native";
import { observer } from 'mobx-react';
import { Stores, useStore } from '../../stores';
import AddressAutocomplete from '../google_autocomplete/AddressAutocomplete';
import { ScrollView } from 'react-native';
import NextButton from '../forms/NextButton';


export interface SelectAddressProps {

}

type SelectAddressScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'select_address'>;

type SelectAddressScreenRouteProp = RouteProp<NewItemNavigationParams, 'select_address'>;

interface SelectAddressFormPage {
    navigation: SelectAddressScreenNavigationProp;
    route: SelectAddressScreenRouteProp;
}

const SelectAddress: FunctionComponent<SelectAddressFormPage> = (props: SelectAddressFormPage) => {
    const store: Stores = useStore().store;
    return (
        <>
            <ScrollView>
                <AddressAutocomplete />
            </ScrollView>
            <NextButton
                title={store.translations.translations.select_address}
                onNext={() => {
                    props.navigation.navigate('new_item')
                }}
                active={store.new_apartment.address_selected}
            />
        </>
    )
}
export default observer(SelectAddress);