import React, { FC } from "react";
import { StyleSheet, View } from "react-native";
import { FormError } from "./Error.interface";
import OneError from "./OneError";

interface ErrorListProps {
    errors: FormError[];
}

const ErrorList: FC<ErrorListProps> = (props: ErrorListProps) => {
    return (
        <View style={styles.errorListWrapper}>
            {
                props.errors.map((oneError,i) => <OneError key={i} {...oneError} />)
            }
        </View>
    )
}
export default ErrorList;

const styles = StyleSheet.create({
    errorListWrapper: {
        
    }
})