import React, { FunctionComponent } from 'react';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { observer } from "mobx-react";
import moment from 'moment-timezone';
import AvailabilityDay, { AvailabilityDayParams } from '../components/calendar/AvailabilityDay';
import Calendar from '../components/calendar/Calendar';
import { MainNavigationParams } from '../components/Navigation';
import AddManualAvailability, { AddManualAvailabilityParams } from '../components/new_item/AddManualAvailability';
import AddMedia, { AddMediaProps } from '../components/new_item/AddMedia';
import NewItemForm from '../components/new_item/NewItemForm';
import PhoneAvailability, { PhoneAvailabilityProps } from "../components/new_item/PhoneAvailability";
import SelectAddress, { SelectAddressProps } from '../components/new_item/SelectAddress';
import SelectPhoneCountry, { SelectCountryPhoneProps } from '../components/new_item/SelectPhoneCountry';
import SelectType, { SelectTypeProps } from '../components/new_item/SelectType';
import ShowingThePlaceAvailability, { ShowingThePlaceAvailabilityProps } from "../components/new_item/ShowingThePlaceAvailability";
import TakeVideo, { TakeVideoProps } from '../components/new_item/take_video/TakeVideo';
import OpenDrawer from '../components/OpenDrawer';
import UserVerfiedPage from '../components/profile/UserVerfiedPage';
import { useStores } from '../stores';
import { View, Text } from 'react-native';
const Stack = createStackNavigator();

type NewItemNavigationProp = DrawerNavigationProp<MainNavigationParams, 'new_item'>;

type NewItemRouteProp = RouteProp<MainNavigationParams, 'new_item'>;

interface NewItemProps {
    navigation: NewItemNavigationProp;
    route: NewItemRouteProp;
}


export type NewItemParams = {
}


export type AvailabilityParams = {
    num_of_days_in_week?: number;
    selected_date?: Date;
    week_starts_at?: string;
    months_to_load?: number;
}

export type NewItemNavigationParams = {
    new_item: NewItemParams;
    select_type: SelectTypeProps;
    phone_availability: PhoneAvailabilityProps;
    availability: AvailabilityParams;
    availability_day: AvailabilityDayParams;
    add_availability_manual: AddManualAvailabilityParams;
    select_country_phone: SelectCountryPhoneProps;
    showing_the_place_availability: ShowingThePlaceAvailabilityProps;
    select_address: SelectAddressProps;
    add_media: AddMediaProps;
    take_video: TakeVideoProps;
}


const NewApartmetPage: FunctionComponent<NewItemProps> = (props: NewItemProps) => {
    const store = useStores();
    return (
        <UserVerfiedPage>
            <Stack.Navigator>
                <Stack.Screen
                    component={SelectType}
                    name='select_type'
                    options={{
                        title: store.translations.translations.deal_type,
                        headerLeft: () => (<OpenDrawer navigation={props.navigation} />),
                    }}
                />
                <Stack.Screen
                    name='phone_availability'
                    component={PhoneAvailability}
                    options={{ title: store.translations.translations.contact_details }}
                />
                <Stack.Screen
                    component={NewItemForm}
                    name='new_item'
                    options={{ title: store.translations.translations.new_item }}
                />
                <Stack.Screen
                    name='showing_the_place_availability'
                    component={ShowingThePlaceAvailability}
                    options={{ title: store.translations.translations.at_the_location }}
                />

                <Stack.Screen
                    name='add_media'
                    component={AddMedia}
                    options={{ title: store.translations.translations.add_media }}
                />
                <Stack.Screen
                    component={Calendar}
                    initialParams={{
                        week_starts_at: "SUN",
                        num_of_days_in_week: 7
                    }}
                    name={"availability"}
                    options={{
                        title: store.translations.translations.availability
                    }}
                />

                <Stack.Screen
                    name={"availability_day"}
                    component={AvailabilityDay}
                    options={{
                        headerTitle: store.translations.translations.day
                    }}
                    initialParams={{
                        events: [],
                        start: 6,
                        end: 24
                    }}
                />
                <Stack.Screen
                    name={"add_availability_manual"}
                    component={AddManualAvailability}
                    options={{
                        headerTitle: moment(store.new_apartment.selected_date).format('ll')
                    }}
                />
                <Stack.Screen
                    name='select_country_phone'
                    options={{
                        headerTitle: store.translations.translations.select_country
                    }}
                    component={SelectPhoneCountry}
                />
                <Stack.Screen
                    name='select_address'
                    component={SelectAddress}
                    options={{
                        headerTitle: store.translations.translations.select_address
                    }}
                />
                <Stack.Screen
                    name="take_video"
                    component={TakeVideo}
                    options={{ headerTitle: store.translations.translations.add_video }}
                />
            </Stack.Navigator>
        </UserVerfiedPage>
    );
}
export default observer(NewApartmetPage);