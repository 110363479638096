import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react';
import { View, Text, StyleSheet, TouchableOpacity, Platform, ScrollView } from 'react-native';
import { Icon } from 'react-native-elements';
import Collapsible from 'react-native-collapsible';
import { useStore, Stores } from '../stores';
// @ts-ignore
import { INotification } from 'go2c-shared';
import Request from '../services/axios.service';
import MKColor from "../services/palette.service";
import moment from 'moment-timezone';



interface NotificationsProps {
    navigation: any;
}

const Notifications: FunctionComponent<NotificationsProps> = function (props: NotificationsProps) {
    const store: Stores = useStore().store;
    const [listCollapsed, setListCollapsed] = useState<boolean>(true);
    const resetNotifications = async () => {
        const fromRequest = await Request({
            method: "PUT",
            url: "notifications",

        });
        if (fromRequest.success && fromRequest.notifications) {
            store.user.setNotifications(fromRequest.notifications);
        }
    };

    const toggleListCollapse = () => {
        setListCollapsed(listCollapsed === true ? false : true);
    }
    return (
        <View style={styles.container}>

            <View style={styles.iconContainer}>
                <Icon
                    color="rgba(255, 255, 255, 0.7)"
                    name="bell"
                    reverse
                    reverseColor="blue"
                    type="font-awesome"
                    style={styles.icon}
                    onPress={async () => {
                        toggleListCollapse();
                    }}
                />
                {
                    store.user.unseen > 0 &&
                    <Text style={styles.text}>{store.user.unseen}</Text>
                }
            </View>

            <View>
                <Collapsible
                    collapsed={listCollapsed}
                    style={styles.notificationsList}
                    duration={300}
                >
                    <ScrollView>

                        {
                            store.user.notifications.length > 0 ?
                                store.user.notifications.map((n: INotification, i: number) => {
                                    return (
                                        <TouchableOpacity
                                            key={i}
                                            style={{
                                                ...styles.notificationContainer,
                                                backgroundColor: n.seen ? '#ffe6bd' : '#cce0e0'
                                            }}
                                            onPress={() => {
                                                if (n.type === 'meeting_request') {
                                                    store.user.setCurrentMeeting(n);
                                                    // props.navigation.navigate('my_calendar', { 
                                                    //     screen: 'accept_meeting', 
                                                    //     params: { meeting: toJS(n) } 
                                                    // });
                                                    props.navigation.navigate('accept_meeting', { meeting: n })
                                                }
                                                resetNotifications();
                                                toggleListCollapse();
                                            }}
                                        >
                                            <Text>{store.translations.translations[n.type]}</Text>
                                            <Text>{n.initiator.username || store.translations.translations.guest}</Text>
                                            <Text>{n.event.title}</Text>
                                            <View style={styles.fromToWrapper}>

                                                <Text style={[styles.fromToText, { flex: 4 }]}>{moment(n.event.time_started).format('LL')}</Text>
                                                <Text style={[styles.fromToText, { flex: 2 }]}>{moment(n.event.time_started).format('HH:mm')}</Text>
                                                <Text>-</Text>
                                                <Text style={[styles.fromToText, { flex: 2 }]}>{moment(n.event.time_ended).format('HH:mm')}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    );
                                })
                                :
                                <View style={styles.noNotifiacationWrapper}>
                                    <TouchableOpacity>
                                        <Text style={styles.noNotifiacationText}>{store.translations.translations.you_have_no_notifications}</Text>
                                    </TouchableOpacity>
                                </View>
                        }
                    </ScrollView>
                </Collapsible>
            </View>

        </View>
    );
}

export default observer(Notifications);

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        zIndex: 16,
        alignSelf: 'flex-end',
        width: 200,
        alignItems: 'flex-start',
        marginTop: Platform.select({
            android: 0,
            ios: 25,
        })
    },
    icon: {

    },
    text: {
        backgroundColor: MKColor.Red,
        height: 20,
        width: 20,
        lineHeight: 20,
        textAlign: 'center',
        position: 'absolute',
        borderRadius: 50,
        color: 'white',
    },
    notificationsList: {
        // position: 'absolute',
        alignSelf: 'flex-start',
        width: 200,

    },
    notificationContainer: {
        borderWidth: 1,
        borderColor: '#dcebfc',
        width: 200,
        zIndex: 18,
        alignSelf: 'flex-start',
        padding: 5,

    },
    iconContainer: {
        alignSelf: 'flex-end'
    },
    noNotifiacationWrapper: {
        backgroundColor: '#dedede',
        height: 100,
    },
    noNotifiacationText: {
        alignSelf: 'center',
        color: '#b8c8e0',
        lineHeight: 100,
        height: 100,

    },
    fromToWrapper: {
        flexDirection: 'row',
    },
    fromToText: {
        flex: 1,
        textAlign: 'center'
    }
})