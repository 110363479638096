import { StyleSheet } from 'react-native';

const textFieldStyles = StyleSheet.create({
    editableWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        flex: 1,
        maxWidth: 500,
        padding: 20,
        borderBottomColor: 'grey',
        borderBottomWidth: 1,
    },
});

export default textFieldStyles;
