import { useRoute } from '@react-navigation/native';
import { useHeaderHeight } from "@react-navigation/stack";
import { Video } from "expo-av";
import React, { FunctionComponent, useEffect } from "react";
import { Dimensions, StyleSheet } from "react-native";
import { Stores, useStore } from "../../stores";

const ShowVideo: FunctionComponent = () => {
    const store: Stores = useStore().store;
    const route = useRoute();
    const source = route.params['source'];
    const headerHeight = useHeaderHeight();
    useEffect(() => {
        if (!store.media.current_item_video) {
            store.media.setCurrentItemVideo({
                source: source,
                is_playing: true
            })
        }
    }, [store, route])
    return (
        <Video
            source={{ uri: store.media.current_item_video?.source || source }}
            rate={1}
            volume={1.0}
            isMuted={false}
            resizeMode="contain"
            useNativeControls={true}
            shouldPlay
            isLooping
            style={[styles.slide, { height: Dimensions.get('window').height - headerHeight }]}

        />

    )
}
export default ShowVideo;

const styles = StyleSheet.create({
    activityIndicator: {
        position: 'absolute',
        top: 70,
        left: 70,
        right: 70,
        height: 50,
    },
    loadingText: {
        color: 'white',
        position: 'absolute',
        top: 180,
        left: 70,
        right: 70,
        textAlign: 'center',
        fontSize: 26,

    },
    slide: {
        // height: Dimensions.get('screen').height - 135,
        // justifyContent: 'center',
        // alignItems: 'center',
        backgroundColor: 'black',
    }
})