import React from 'react';
import { StyleSheet, Image, View } from 'react-native';


class CustomMarker extends React.Component {
    render() {
        return (
            <View
                style={styles.marker}
            ></View>
        );
    }
}

const styles = StyleSheet.create({
    marker: {
        height: 40,
        width: 40,
        backgroundColor: 'red',
        borderRadius: 50,
    },
});

export default CustomMarker;