import React, { FunctionComponent, useState } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { View, Text, StyleSheet, ScrollView, TextInput } from 'react-native';
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { observer } from "mobx-react";
import { Stores, useStore } from "../../stores";
// import RangeSlider from "rn-range-slider";
import moment from "moment";
import MKColor from "../../services/palette.service";
// @ts-ignore
import { ICalendarEvent } from 'go2c-shared';

import NextButton from "../forms/NextButton";
import RangeSlider from "../forms/range_slider/RangeSlider";

export interface AddManualAvailabilityParams {

}


type AddManualAvailabilityNavigationProp = StackNavigationProp<NewItemNavigationParams, 'add_availability_manual'>;

type AddManualAvailabilityRouteProp = RouteProp<NewItemNavigationParams, 'add_availability_manual'>;

interface AddManualAvailabilityProps {
    navigation: AddManualAvailabilityNavigationProp;
    route: AddManualAvailabilityRouteProp;
}


// const eventTypes = [
//     {
//         name: 'location',
//         color: '#f2f2f2',
//         defaultTitle: 'i_am_available_for_location',
//         defaultDescription: 'please_send_a_message_ahead',
//         type: 'availability' || 'meeting_request',

//     }
// ]

const AddManualAvailability: FunctionComponent<AddManualAvailabilityProps> = function (props: AddManualAvailabilityProps) {
    const store: Stores = useStore().store;
    const [description, setDescription] = useState<string>('');

    return (
        <ScrollView style={{ flex: 1 }}>
            <Text style={styles.label}>{store.translations.translations.add_availability_manually}</Text>
            <View style={styles.typeWrapper}>

                <Text style={styles.text}>{store.translations.translations.type}</Text>
                <Text style={styles.text}>{store.translations.translations[store.new_apartment.manual_availability_data.type]}</Text>
            </View>
            <View style={styles.typeWrapper}>
                <Text style={styles.text}>{store.translations.translations.time_frame}</Text>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                    <Text style={styles.text}>{store.new_apartment.showing_the_place_time_frame}</Text>
                    <Text style={styles.text}>{store.translations.translations.minutes}</Text>

                </View>
            </View>
            <View style={styles.typeWrapper}>
                <Text style={styles.text}>{store.translations.translations.from_an_hour}</Text>
                <Text style={styles.text}>{moment(store.new_apartment.manual_availability_data.time_started).format('HH:mm')}</Text>
            </View>
            <View style={styles.typeWrapper}>
                <Text style={styles.text}>{store.translations.translations.to_an_hour}</Text>
                <Text style={styles.text}>{moment(store.new_apartment.manual_availability_data.time_ended).format('HH:mm')}</Text>
            </View>
            <View style={styles.sliderWrapper}>
                <Text style={styles.sliderMinMaxDesc}>06:00</Text>
                <RangeSlider
                    style={styles.slider}
                    gravity={'center'}
                    step={1000 * 60 * store.new_apartment.showing_the_place_time_frame}
                    valueType={"time"}
                    min={moment(store.new_apartment.manual_availability_data.time_started).set({ hours: 6, minutes: 0, seconds: 0, milliseconds: 0 }).toDate().getTime()}
                    initialLowValue={moment(store.new_apartment.manual_availability_data.time_started).toDate().getTime()}
                    max={moment(store.new_apartment.manual_availability_data.time_ended).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 }).toDate().getTime()}
                    initialHighValue={moment(store.new_apartment.manual_availability_data.time_ended).toDate().getTime()}
                    selectionColor={MKColor.Blue}
                    blankColor={MKColor.Orange}
                    onValueChanged={(low: number, high: number, fromUser: boolean) => {
                        if (fromUser === true) {
                            store.new_apartment.setManulalAvailabiliyData({
                                time_started: new Date(low),
                                time_ended: new Date(high),
                                hours: new Date(low).getHours(),
                                minutes: new Date(low).getMinutes(),
                                type: 'at_the_location',
                                event: null,
                            })
                        }
                    }}
                    textFormat="HH : mm"
                />
                <Text style={styles.sliderMinMaxDesc}>00:00</Text>

            </View>

            <View style={[styles.typeWrapper, { flex: 3 }]}>

                <TextInput
                    multiline={true}
                    numberOfLines={4}
                    style={styles.textarea}
                    placeholder={store.translations.translations.remarks}
                    value={description}
                    onChangeText={(val: string) => {
                        setDescription(val);
                    }}
                />
            </View>
            <NextButton
                title={store.translations.translations.add}
                onNext={(() => {

                    const numOfMeetings = Math.ceil((
                        Number(store.new_apartment.manual_availability_data.time_ended) -
                        Number(store.new_apartment.manual_availability_data.time_started)
                    ) / 1000 / 60 / store.new_apartment.showing_the_place_time_frame);
                    const events: ICalendarEvent[] = [];
                    let curTime: Date = store.new_apartment.manual_availability_data.time_started;
                    for (let i = 0; i < numOfMeetings; i++) {
                        const timeStarted: Date = curTime;
                        const timeEnded: Date = i === numOfMeetings - 1 ?
                            store.new_apartment.manual_availability_data.time_ended
                            :
                            moment(curTime).add(store.new_apartment.showing_the_place_time_frame, 'minutes').toDate();
                        events.push({
                            time_started: timeStarted,
                            time_ended: timeEnded,
                            type: store.new_apartment.manual_availability_data.type,
                            title: store.translations.translations[store.new_apartment.manual_availability_data.type],
                            description,
                        });
                        curTime = timeEnded;
                    }
                    store.new_apartment.addEvents(events);
                    props.navigation.navigate('showing_the_place_availability');
                })}
                active={true}
            />

        </ScrollView>
    )
}
export default observer(AddManualAvailability);


const styles = StyleSheet.create({
    slider: {
        width: 300,
        height: 80,
        alignSelf: 'center',
        marginBottom: 40,
    },
    submitText: {
        backgroundColor: MKColor.Blue,
        width: 250,
        alignSelf: 'center',
        textAlign: 'center',
        height: 40,
        lineHeight: 40,
        borderRadius: 10,
        marginTop: 50,
        color: 'white',
    },
    submitButton: {
        bottom: 0,
    },
    label: {
        fontSize: 24,
        textAlign: 'center',
        flex: 1,
        marginBottom: 15,
    },
    typeWrapper: {
        flex: 1,
        flexDirection: 'row',
        margin: 15,
    },
    text: {
        fontSize: 20,
        flex: 1,
    },
    sliderWrapper: {
        flex: 2,
        flexDirection: 'row',
    },
    sliderMinMaxDesc: {
        alignSelf: 'center',
        fontSize: 18,
    },
    textarea: {
        flex: 1,
    }
})