import LottieView from 'lottie-react-native';
import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { Image, Platform, StyleSheet, Text, View } from 'react-native';
// import AnimatedLoader from "react-native-animated-loader";
import { WebView } from 'react-native-webview';
import { baseURL } from '../services/axios.service';
import { Stores, useStore } from '../stores';


export interface LoaderProps {

}

const Loader: FunctionComponent<LoaderProps> = function (props: LoaderProps) {
    const store: Stores = useStore().store;

    return (

        <View style={styles.loaderWrapper}>
            <Text style={styles.appName}>Go2c</Text>
            <Text style={styles.slogan}>{store.translations.init_texts.slogan}</Text>
            <Image style={styles.logo} source={require('../assets/logo.png')} />
            <Text style={styles.loadingText}>{store.translations.loading_status}</Text>
			<Text>{store.translations.loading_status_number}</Text>
            {
                Platform.select({
                    ios: <WebView
                        source={{ uri: baseURL + 'public/animation' }}
                        style={styles.loader}
                    />,
                    android: <LottieView
                        source={require('../assets/loader.json')}
                        autoPlay
                        loop
                        style={styles.loader}
                    />,
                    web: <LottieView
                        source={require('../assets/loader.json')}
                        autoPlay
                        loop
                        style={styles.loader}
                    />
                })
            }
        </View>
    )
}

export default observer(Loader);

const styles = StyleSheet.create({
    loader: {
        height: 350,
        width: 350,
        alignSelf: 'center',
        maxHeight: 200,
        position: 'absolute',
        top: Platform.OS === 'ios' ? '10%' : '45%'
    },
    loadingText: {
        flex: 1,
        fontSize: 30,
        top: '45%',
        textAlign: 'center',
    },
    loaderWrapper: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
    },
    logo: {
        height: 80,
        width: 80,
        position: 'absolute',
        top: '15%',
        alignSelf: 'center'
    },
    slogan: {
        position: 'absolute',
        top: '35%',
        alignSelf: 'center',
        fontSize: 24,
    },
    appName: {

        position: 'absolute',
        top: '28%',
        alignSelf: 'center',
        fontSize: 22,
    }
})