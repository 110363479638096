export function getFileExtension(filename: string): string | undefined {
    return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
}

const imagesExtensions: string[] = ['png', 'jpg'];

const videoExtensions: string[] = ['mp4', 'avi'];


export function getFileType(filename: string): 'video' | 'image' | false {
    if (imagesExtensions.indexOf(getFileExtension(filename)) > -1) {
        return 'image';
    } else if (videoExtensions.indexOf(getFileExtension(filename)) > -1) {
        return 'video';
    } else {
        return false;
    }
}


export const buildImagePath = (path: string, apiPath: string, height: number, width: number) => {
    const [bucket, itemId, imageName] = path.split('/');
    const fullPath: string = apiPath + '?' + 'path=' + itemId + '/' + imageName + '&height=' + String(height) + '&width=' + String(width) + '&bucket=' + bucket;
    return fullPath as unknown as URL;
}