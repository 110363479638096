import React from 'react';
import { View, TouchableOpacity } from 'react-native';

const TouchableView = (props) => {
    return (
        <View
            style={{
                ...props.style,
                backgroundColor: null,
                flexDirection: "row"
            }}
        >
            <TouchableOpacity
                style={{ flex: 1, backgroundColor: props.style.backgroundColor }}
                onPress={props.onPress}
                disabled={props.disabled || false}
            >
                {props.children}
            </TouchableOpacity>
        </View>
    );
}
export default TouchableView;