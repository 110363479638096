import { observable, action } from 'mobx';
import { IItem } from 'go2c-shared';
import { persist } from 'mobx-persist';

export type IVideo = {
    source: string;
    is_playing?: boolean;

}

export interface IMediaStoreMethods {
    setCurrentItemVideo(video: IVideo): void;

}

export interface IMediaStore extends IMediaStoreMethods {
    current_item_video: IVideo;
}


class MediaStore {
    @persist('object') @observable current_item_video: IVideo;

    @action
    setCurrentItemVideo(video: IVideo) {
        this.current_item_video = video;
    }


}

export default new MediaStore();