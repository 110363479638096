import { observable, action } from 'mobx';
import { IItem, ICalendarEvent, IPhone, FilterRequestParams } from 'go2c-shared';
import { numberWithCommas, removeCommas } from '../services/utils.service';
import Request from "../services/axios.service";
import { mapEvents } from '../services/events.service';
import { persist } from 'mobx-persist';
import { Region } from 'react-native-maps';

export interface IItemsStoreMethods {
    setItemsList(apartments: IItem[]): void;
    setLocation(latitude: number, longitude: number): void;
    setCurrentItem(item: IItem): void;
    setCurrentItemCallout(item: IItem): void;
    setAvailabilities(availabilities: ICalendarEvent[]): void;
    setCurrentAvailabilityBlock(availability: ICalendarEvent): void;
    setHasMeetingWithCurrentItem(meeting: ICalendarEvent): void;
    setCurrentDay(date: Date): void;
    setPriceMin(min: number): void;
    setPriceMax(max: number): void;
    setNumOfRoomsMin(min: number): void;
    setNumOfRoomsMax(max: number): void;
    setFloorMinMax(min?: string, max?: string): void;
    setTotalFloorsInBuildingMinMax(min?: string, max?: string): void;
    setCity(city: string): void;
    setRegion(region?: Region): void;
    setCurrentPhone(phone: IPhone): void;
    setFreeTextSearch(text: string): void;
    setSizeFilter(min?: string, max?: string): void;
    clearFilters(): void;
    setCalendarLoading(loading: boolean): void;
    clearAvailabilities(): void;
    clearCurrentItem(): void;
    setImagesApi(apiPath: string): void;

}

export interface IItemsStore extends IItemsStoreMethods {
    items: IItem[];
    latitude: number;
    longitude: number;
    latitude_delta: number;
    longitude_delta: number;
    current_item: IItem;
    current_item_callout: IItem;
    images: string[];
    current_item_availabilities: ICalendarEvent[];
    current_availability_block: ICalendarEvent;
    has_meeting_with_current_item: boolean;
    meeting_with_current_item: ICalendarEvent;
    mapped_events: any;
    current_day: Date;
    price_min: string;
    price_max: string;
    num_of_rooms_min: number;
    num_of_rooms_max: number;
    floor_min: number;
    floor_max: number;
    total_floors_in_building_min: number;
    total_floors_in_building_max: number;
    free_text_search: string;
    has_filters: boolean;
    city: string;
    current_phone: IPhone;
    size_min: number;
    size_max: number;
    calendar_loading: boolean;
    images_api: string;
}

class ItemsStore {
    @observable items: IItem[] = [];
    @observable latitude: number = 32.0853;
    @observable latitude_delta: number = 0.0922;
    @observable longitude: number = 34.7818;
    @observable longitude_delta: number = 0.0421;
    @persist('object') @observable current_item: IItem;
    @observable current_item_callout: IItem;
    @persist('list') @observable images: string[] = [];
    @observable current_item_availabilities: ICalendarEvent[] = [];
    @observable current_availability_block: ICalendarEvent;
    @observable mapped_events: any = {};
    @observable current_day: Date;
    @observable price_min: string;
    @observable price_max: string;
    @observable num_of_rooms_min: number;
    @observable num_of_rooms_max: number;
    @observable floor_min: number;
    @observable floor_max: number;
    @observable total_floors_in_building_min: number;
    @observable total_floors_in_building_max: number;
    @observable size_min: number;
    @observable size_max: number;
    @observable free_text_search: string;
    @observable has_filters: boolean = false;
    @observable city: string;
    @observable current_phone: IPhone;
    @observable has_meeting_with_current_item: boolean = false;
    @observable meeting_with_current_item: ICalendarEvent;
    @observable calendar_loading: boolean;
    @observable images_api: string;

    @action
    public setItemsList(items: IItem[]) {
        this.items = items;
    }

    @action
    public setLocation(latitude: number, longitude: number): void {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    @action
    public setCurrentItem(item: IItem) {
        this.current_item = item;
        this.images = item.images;
    }

    @action
    public setCurrentItemCallout(item: IItem) {
        this.current_item_callout = item;
    }


    @action
    public setAvailabilities(availabilities: ICalendarEvent[]) {
        if (availabilities && availabilities !== null) {
            this.current_item_availabilities = availabilities;
            this.mapped_events = mapEvents(availabilities);
        } else {
            this.mapped_events = {};
            this.current_item_availabilities = [];
        }
    }

    @action
    public setCurrentAvailabilityBlock(availability: ICalendarEvent) {
        this.current_availability_block = availability;
    }

    @action
    public setHasMeetingWithCurrentItem(meeting: ICalendarEvent) {
        if (meeting !== null) {
            this.has_meeting_with_current_item = true;
            this.meeting_with_current_item = meeting;
        } else {
            this.has_meeting_with_current_item = false;
            this.meeting_with_current_item = null;
        }
    }

    @action
    public setCurrentDay(date: Date) {
        this.current_day = date;
    }
    @action
    public setPriceMin(min: number) {
        this.price_min = numberWithCommas(min);
        this.resetHasFilters();
    }
    @action
    public setPriceMax(max: number) {
        this.price_max = numberWithCommas(max);
        this.resetHasFilters();
    }

    @action
    public setNumOfRoomsMin(min: number) {
        this.num_of_rooms_min = min;
        this.resetHasFilters();
    }

    @action
    public setNumOfRoomsMax(max: number) {
        this.num_of_rooms_max = max;
        this.resetHasFilters();
    }

    @action
    public setFloorMinMax(min?: string, max?: string) {
        if (min === '') {
            this.floor_min = null;
        } else if (min) {
            this.floor_min = Number(min);
        }
        if (max === '') {
            this.floor_max = null;
        } else if (max) {
            this.floor_max = Number(max);
        }
        this.resetHasFilters();
    }


    @action
    public setTotalFloorsInBuildingMinMax(min?: string, max?: string) {
        if (min === '') {
            this.total_floors_in_building_min = null;
        } else if (min) {
            this.total_floors_in_building_min = Number(min);
        }
        if (max === '') {
            this.total_floors_in_building_max = null;
        } else if (max) {
            this.total_floors_in_building_max = Number(max);
        }
        this.resetHasFilters();
    }

    @action
    public setSizeFilter(min?: string, max?: string) {
        if (min === '') {
            this.size_min = null;
        } else if (min) {
            this.size_min = Number(min);
        }
        if (max === '') {
            this.size_max = null;
        } else if (max) {
            this.size_max = Number(max);
        }
        this.resetHasFilters();
    }

    @action
    public setCity(city: string) {
        this.city = city;
    }

    @action
    public async setRegion(region?: Region) {
        // TODO: Optimize requusts to not bombard server
        try {
            if (region) {
                this.latitude = region.latitude;
                this.latitude_delta = region.latitudeDelta;
                this.longitude = region.longitude;
                this.longitude_delta = region.longitudeDelta;

            }
            const requestBody: FilterRequestParams = {
                price_min: removeCommas(this.price_min),
                price_max: removeCommas(this.price_max),
                num_of_rooms_min: this.num_of_rooms_min,
                num_of_rooms_max: this.num_of_rooms_max,
                floor_min: this.floor_min,
                floor_max: this.floor_max,
                total_floors_in_building_min: this.total_floors_in_building_min,
                total_floors_in_building_max: this.total_floors_in_building_max,
                location: region,
                free_text: this.free_text_search || null,
                size_min: this.size_min,
                size_max: this.size_max,
            }
            const newItems = await Request({
                method: 'POST',
                url: 'items/filter',
                data: requestBody
            });
            if (newItems && newItems.success) {
                this.setItemsList(newItems.items)
            } else {
                alert('error in getting new items, returned ' + JSON.stringify(newItems) + ' items');
                // userStore.unInit()
            }
        } catch (error) {
            alert(JSON.stringify(error));
        }
    }

    @action
    public setCurrentPhone(phone: IPhone) {
        this.current_phone = phone;
    }

    @action
    public setFreeTextSearch(text: string) {
        if (text === '' || !text) {
            this.free_text_search = null;
        } else {
            this.free_text_search = text;
        }
        this.resetHasFilters();
    }

    @action
    public resetHasFilters() {
        if (
            this.price_min !== null ||
            this.price_max !== null ||
            this.num_of_rooms_min !== null ||
            this.num_of_rooms_max !== null ||
            this.floor_min !== null ||
            this.floor_max !== null ||
            this.total_floors_in_building_min !== null ||
            this.total_floors_in_building_max !== null ||
            this.free_text_search !== null ||
            this.size_min !== null ||
            this.size_max !== null
        ) {
            this.has_filters = true;
        } else {
            this.has_filters = false;
        }
    }

    @action
    public clearFilters() {
        this.price_min = null;
        this.price_max = null;
        this.num_of_rooms_min = null;
        this.num_of_rooms_max = null;
        this.floor_min = null;
        this.floor_max = null;
        this.total_floors_in_building_min = null;
        this.total_floors_in_building_max = null;
        this.free_text_search = null;
        this.size_min = null;
        this.size_max = null;
        this.resetHasFilters();
    }

    @action
    public setCalendarLoading(loading: boolean) {
        this.calendar_loading = loading;
    }

    @action
    public clearAvailabilities() {
        this.setCalendarLoading(false);
        this.setAvailabilities(null);
        this.setHasMeetingWithCurrentItem(null);
        this.setCurrentAvailabilityBlock(null);
        this.setCalendarLoading(false);
    }

    @action
    public clearCurrentItem() {
        this.current_item = null;
        this.images = null;
        this.current_day = null;
    }

    @action
    setImagesApi(apiPath: string) {
        this.images_api = apiPath;
    }
}

export default new ItemsStore();