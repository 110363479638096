import { StackNavigationProp } from "@react-navigation/stack";
import { CurrentItemNavigationParams, SendInvitationProps } from "../../pages/Map.page";
import { RouteProp } from "@react-navigation/native";
import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { View, Text, ScrollView, StyleSheet } from "react-native";
import { Stores, useStore } from "../../stores";
import { ICalendarEvent } from "go2c-shared";
import moment from "moment";
import MKColor from "../../services/palette.service";
import Request from "../../services/axios.service";
import { toJS } from "mobx";
import RippleButton from "../forms/RippleButton";

export interface ScheduleAtTheLocationParams extends SendInvitationProps {
    item_id: string;
}

type ScheduleAtTheLocationNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'schedule_at_the_location'>;

type ScheduleAtTheLocationRouteProp = RouteProp<CurrentItemNavigationParams, 'schedule_at_the_location'>;

interface ScheduleAtTheLocationProps {
    navigation: ScheduleAtTheLocationNavigationProp;
    route: ScheduleAtTheLocationRouteProp;
}


const ScheduleAtTheLocation: FunctionComponent<ScheduleAtTheLocationProps> = function (props: ScheduleAtTheLocationProps) {
    const store: Stores = useStore().store;

    const takeMeeting = async (event: ICalendarEvent): Promise<boolean> => {
        const fromRequest = await Request({
            method: 'POST',
            url: 'events/' + event._id + '/take'
        });
        store.dialog.closeFunction();
        if (fromRequest.success == true) {
            const desc: string = [
                store.translations.translations.meeting_successfully_scheduled,
                store.translations.translations.at_time,
                moment(event.time_started).format('llll'),
                store.translations.translations.in_address,
                store.items.current_item.raw_address
            ].join('\n');
            store.dialog.openPopup(store.translations.translations.meeting_successfully_scheduled, desc);
        }
        return fromRequest.success as boolean;
    }
    const askTakeMeeting = async (event: ICalendarEvent) => {
        if (!store.items.has_meeting_with_current_item && !store.items.meeting_with_current_item) {
            store.dialog.askTakeMeeting({
                onOk: takeMeeting,
                event,
                title: store.translations.translations.accept_meeting,
                description: [
                    store.translations.translations.schedule_a_meeting_with,
                    store.items.current_item.user.username,
                    store.translations.translations.at_time,
                    moment(event.time_started).format('llll'),
                    store.translations.translations.in_address,
                    store.items.current_item.raw_address
                ].join('\n'),
                okButtonText: 'OK'
            });

        }
    }

    if (!store.items.current_availability_block || !store.items.current_availability_block.merged) {
        props.navigation.navigate('map');
    }
    return (
        <ScrollView>
            {
                store.items.has_meeting_with_current_item ?
                    <View style={styles.youHaveMeetingContainer}>
                        <Text style={styles.explanationText}>{store.translations.translations.you_already_have_a_meeting_here}</Text>
                        <Text style={styles.explanationText}>{store.translations.translations.at_time}</Text>
                        <Text style={styles.explanationText}>{moment(store.items.meeting_with_current_item.time_started).format('llll')}</Text>
                    </View>
                    :
                    <View>
                        <Text style={styles.explanationText}>{store.translations.translations.take_meeting_explanation}</Text>
                        {
                            toJS(store.items.current_availability_block.merged).sort((a: ICalendarEvent, b: ICalendarEvent) => {
                                return new Date(a.time_started).getTime() - new Date(b.time_started).getTime();
                            }).map((val: ICalendarEvent, i: number) => {
                                return (

                                    <View
                                        key={i}
                                        style={[
                                            styles.timeBlock,
                                        ]}
                                    >
                                        <View style={styles.fromToContainer}>
                                            <Text style={styles.fromToText}>{String(moment(val.time_started).format('HH:mm'))}</Text>
                                            {/* <Text style={styles.fromToText}>-</Text>
                                <Text style={styles.fromToText}>{String(moment(val.time_ended).format('HH:mm'))}</Text> */}
                                        </View>
                                        <RippleButton
                                            style={[
                                                styles.takeMeetingBlock,
                                                val.approved && val.meet_with ? styles.takenBlock : styles.freeBlock

                                            ]}
                                            onPress={() => { askTakeMeeting(val) }}
                                            disabled={val.approved === true || store.items.has_meeting_with_current_item === true ? true : false}

                                        >
                                            {
                                                val.approved ?
                                                    <Text style={styles.takenOrFreeText}>{store.translations.translations.taken}</Text>
                                                    :
                                                    <Text style={styles.takenOrFreeText}>{store.translations.translations.schedule_a_meeting}</Text>
                                            }
                                        </RippleButton>
                                    </View>

                                )
                            })
                        }

                    </View>

            }
        </ScrollView>
    )
}

export default observer(ScheduleAtTheLocation);

const styles = StyleSheet.create({
    timeBlock: {
        flexDirection: 'row',
        borderBottomColor: MKColor.Grey,
        borderBottomWidth: 1,
        width: '80%',
        alignSelf: 'center',
        marginBottom: 5,
    },
    freeBlock: {

    },
    takenBlock: {
        backgroundColor: 'rgba(252, 30, 30, 0.05)',
    },
    fromToContainer: {
        flex: 1,
        backgroundColor: 'rgba(153, 153, 153, 0.5)',
        justifyContent: 'center'
    },
    fromToText: {
        flex: 1,
        textAlign: 'center',
        alignSelf: 'center',
        // textAlignVertical: 'center',
        fontSize: 20,
        justifyContent: 'center'

    },
    takeMeetingBlock: {
        flex: 3,
        borderStartColor: MKColor.Grey,
    },
    explanationText: {
        fontSize: 24,
        textAlign: 'center',
        width: '90%',
        alignSelf: 'center'
    },
    takenOrFreeText: {
        fontSize: 20,
        flex: 1,
        alignSelf: 'center',
        // textAlignVertical: 'center',

    },
    youHaveMeetingContainer: {
        marginVertical: '40%'
    }
})