import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View, Text, StyleSheet, Animated, Easing } from 'react-native';
import Collapsible from 'react-native-collapsible';
import RippleButton from './forms/RippleButton';

export interface CollapsibleTitleParams {
    title: string;
    add_seperator?: boolean;
}

type ICollapsibleTitle = PropsWithChildren<CollapsibleTitleParams>;

const CollapsibleTitle: FunctionComponent<ICollapsibleTitle> = (props: ICollapsibleTitle) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [spinValue, setSpinValue] = useState(new Animated.Value(0));
    const spinned = spinValue.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '90deg']
    });
    const spin = () => {
        spinValue.setValue(isCollapsed === true ? 0 : 1);
        Animated.timing(
            spinValue,
            {
                toValue: isCollapsed === true ? 1 : 0,
                duration: 200,
                easing: Easing.linear
            }
        ).start();
    }
    return (
        <View style={styles.container}>
            <View style={[styles.headerWrapper, props.add_seperator && { borderBottomColor: 'rgba(207, 204, 204,1)', borderBottomWidth: 2 }]}>
                <Text style={styles.title}>{props.title}</Text>
                <RippleButton
                    style={styles.titleOpenerWrapper}
                    onPress={() => {
                        spin();
                        setIsCollapsed(!isCollapsed);
                    }}
                >
                    <Animated.Text style={[styles.titleOpener, { transform: [{ rotate: spinned }] }]}>+</Animated.Text>
                </RippleButton>
            </View>
            <Collapsible
                collapsed={isCollapsed}
                style={styles.collapsable}
            >

                {props.children}
            </Collapsible>
        </View>
    )
}

export default CollapsibleTitle;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: 15,
        marginBottom: 15,
    },
    headerWrapper: {
        flexDirection: 'row',
        alignContent: 'space-between',
        justifyContent: "space-between",
        paddingBottom: 15
    },
    title: {
        flex: 1,
        fontSize: 24,
    },
    collapsable: {
        flex: 1,
        paddingBottom: 15,
        paddingTop: 15,
    },
    titleOpener: {
        fontSize: 30,
        alignSelf: 'flex-end',
        textAlign: 'center',
        lineHeight: 36,
        marginEnd:1,
        color: "#787880",

    },
    titleOpenerWrapper: {
        backgroundColor: "#f2f2f2",
        borderRadius: 50,
        width: 36,
        height: 36,
    },
})