import { ICountry, ILanguage, ITranslationDictionary } from 'go2c-shared';
import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { Dimensions } from 'react-native';

type BreakPointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type BreakPointWidth = 0 | 600 | 960 | 1280 | 1920;

type BreakPoint = { name: BreakPointName, min_width: BreakPointWidth };

const allBreakPoints: BreakPoint[] = [
    { name: 'xs', min_width: 0 },
    { name: 'sm', min_width: 600 },
    { name: 'md', min_width: 960 },
    { name: 'lg', min_width: 1280 },
    { name: 'xl', min_width: 1920 },
]


const ActiveLanguages: ILanguage[] = [
    { name: "hebrew", direction: "rtl", country_codes: ["he", "iw_IL"] },
    { name: "english", direction: "ltr", country_codes: ["en", "en_US", "en_UK"] },
    { name: "spanish", direction: "ltr", country_codes: ["es"] },
    { name: "french", direction: "ltr", country_codes: ["fr"] },
    { name: "chinese", direction: "ltr", country_codes: ["cn"] },
    { name: "japanese", direction: "ltr", country_codes: ["jp"] },
    { name: "russian", direction: "ltr", country_codes: ["ru"] },
    { name: "german", direction: "ltr", country_codes: ["de"] },
    { name: "arabic", direction: "rtl", country_codes: ["sa"] },
];


export function getLanguageByCountryCode(country_code: string): ILanguage {
    const allMatches = ActiveLanguages.filter((lang: ILanguage) => {
        return lang.country_codes.indexOf(country_code) > -1;
    });
    return allMatches[0];
}
export const initTexts = {
    english: {
        loading: 'Loading',
        fetching_data: 'Fetching data',
        loading_media: 'Loading media',
        finalizing: 'Finalizing',
        slogan: 'Find home... efficently',
        location_needed: 'Location',
        location_needed_description: 'For the best experience, please enable location service',
        go_to_settings: 'Go to settings',
        cancel: 'Cancel',
    },
    hebrew: {
        loading: 'טוען',
        fetching_data: 'משיג מידע',
        loading_media: 'טוען מדיה',
        finalizing: 'מסיים',
        slogan: 'מצא בית ביעילות',
        location_needed: 'מיקום',
        location_needed_description: 'לקבלת החוויה הטובה ביותר, אנא אפשר שירות מיקום',
        go_to_settings: 'הגדרות',
        cancel: 'בטל',
    },
    spanish: {
        loading: 'cargando',
        fetching_data: 'Recuperacion de datos',
        loading_media: 'Cargando medios',
        finalizing: 'Finalizando',
        slogan: 'Encuentra tu hogar ... eficientemente',
        location_needed: 'Ubicación',
        location_needed_description: 'Para la mejor experiencia, habilite el servicio de ubicación',
        go_to_settings: 'Ir a la configuración',
        cancel: 'Cancelar',
    },
    french: {
        loading: 'chargement',
        fetching_data: 'Récupération des données',
        loading_media: 'Chargement des supports',
        finalizing: 'Finaliser',
        slogan: 'Trouvez la maison ... efficacement',
        location_needed: 'Emplacement',
        location_needed_description: 'Pour la meilleure expérience, veuillez activer le service de localisation',
        go_to_settings: 'Aller aux paramètres',
        cancel: 'Annuler',
    },
    chinese: {
        loading: '装货',
        fetching_data: '取得资料',
        loading_media: '载入媒体',
        finalizing: '定稿',
        slogan: '高效地找到家',
        location_needed: '位置',
        location_needed_description: '为了获得最佳体验，请启用位置服务',
        go_to_settings: '前往设置',
        cancel: '取消',
    },
    japanese: {
        loading: 'ローディング',
        fetching_data: 'データを取得しています',
        loading_media: 'メディアを読み込んでいます',
        finalizing: 'ファイナライズ',
        slogan: '家を見つける...効率的に',
        location_needed: 'ロケーション',
        location_needed_description: '最適なエクスペリエンスを得るには、位置情報サービスを有効にしてください',
        go_to_settings: '設定に移動',
        cancel: 'キャンセル',
    },
    russian: {
        loading: 'погрузка',
        fetching_data: 'Получение данных',
        loading_media: 'Загрузка медиа',
        finalizing: 'Доработка',
        slogan: 'Найти дом ... эффективно',
        location_needed: 'Расположение',
        location_needed_description: 'Для лучшего опыта, пожалуйста, включите службу определения местоположения',
        go_to_settings: 'Перейдите в настройки',
        cancel: 'Отмена',
    },
    german: {
        loading: 'Wird geladen',
        fetching_data: 'Daten abrufen',
        loading_media: 'Medien laden',
        finalizing: 'Finalisieren',
        slogan: 'Finden Sie Ihr Zuhause ... effizient',
        location_needed: 'Ort',
        location_needed_description: 'Für die beste Erfahrung aktivieren Sie bitte den Ortungsservice',
        go_to_settings: 'Gehe zu den Einstellungen',
        cancel: 'Stornieren',
    },
    arabic: {
        loading: 'جار التحميل',
        fetching_data: 'جلب البيانات',
        loading_media: 'تحميل الوسائط',
        finalizing: 'إنهاء',
        slogan: 'ابحث عن المنزل ... بكفاءة',
        location_needed: 'موقعك',
        location_needed_description: 'للحصول على أفضل تجربة ، يرجى تمكين خدمة الموقع',
        go_to_settings: 'اذهب للاعدادات',
        cancel: 'إلغاء',
    },
}



export interface IInitTexts {
    loading: string;
    fetching_data: string;
    loading_media: string;
    finalizing: string;
    slogan: string;
    location_needed: string;
    location_needed_description: string;
    go_to_settings: string;
    cancel: string;
}
export interface ITranslationsStoreMethods {
    setTranslations(translations: ITranslationDictionary): void;
    setLanguageCode(code: string): void;
    setUuid(uuid: string): void;
    setCurrency(currency: string): void;
    setLoadingStatus(status: string): void;
    setLoadingNumber(status: string): void;
    setCountryCode(code: string): void;
    setAllCountries(coutries: ICountry[]): void;
    setUserCountry(coutry: ICountry): void;
    setDidInitRouter(did_init: boolean): void;
    setWindowDimensions(): void;

}

export interface ITranslationsStore extends ITranslationsStoreMethods {
    translations: ITranslationDictionary;
    loading: boolean;
    language_code: string;
    uuid: string;
    currency: string;
    init_texts: IInitTexts;
    loading_status: string;
    loading_status_number: string;
    country_code: string;
    user_country: ICountry;
    all_countries: ICountry[];
    did_init_router: boolean;
    window_width: number;
    window_height: number;
}


class TranslationStore {
    @observable translations: ITranslationDictionary = {} as ITranslationDictionary;
    @observable loading: boolean = true;
    @persist @observable did_init_router: boolean = false;
    @observable language_code: string;
    @observable country_code: string;
    @observable uuid: string;
    @observable currency: string;
    @observable init_texts: IInitTexts;
    @observable loading_status: string;
    @observable loading_status_number: string;
    @observable user_country: ICountry;
    @observable all_countries: ICountry[];
    @observable window_width: number;
    @observable window_height: number;
    @observable breakpoint: BreakPoint;



    @action
    public setTranslations(translations: ITranslationDictionary) {
        this.translations = translations;
        this.loading = false;
    }

    @action
    public setLanguageCode(code: string) {
        this.language_code = code;
        this.init_texts = initTexts[getLanguageByCountryCode(code).name];
        this.loading_status = this.init_texts.loading;
    }

    @action
    public setUuid(uuid: string) {
        this.uuid = uuid;
    }

    @action
    public setCurrency(currency: string) {
        this.currency = currency;
    }

    @action
    public setLoadingStatus(status: string) {
        this.loading_status = this.init_texts[status];
    }

    @action
    public setLoadingNumber(status: string) {
        this.loading_status_number = status;
    }

    @action
    public setCountryCode(code: string) {
        this.country_code = code;
    }

    @action
    public setAllCountries(coutries: ICountry[]) {
        this.all_countries = coutries;
    }


    @action
    public setUserCountry(coutry: ICountry) {
        this.user_country = coutry;
    }

    @action
    public setDidInitRouter(did_init: boolean) {
        this.did_init_router = did_init;
    }

    @action
    setBreakpoint() {
        for (let i = 0; i < allBreakPoints.length; i++) {
            const breakPoint = allBreakPoints[i];
            const nextBreakPoint = allBreakPoints[i + 1];

        }
    }

    @action
    setWindowDimensions() {
        const windowWidth = Dimensions.get('window').width;
        const windowHeight = Dimensions.get('window').height;
        this.window_width = windowWidth;
        this.window_height = windowHeight;

    }
}

export default new TranslationStore();