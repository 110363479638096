import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { View, Text, StyleSheet, Image, TouchableOpacity } from "react-native";
import { Stores, useStore } from "../../stores";
// import * as ImagePicker from 'expo-image-picker';
// @ts-ignore
import ImagePicker, { ImagePickerOptions, ImagePickerResponse, launchCamera, launchImageLibrary } from 'react-native-image-picker';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFolderUpload, faCameraRetro } from '@fortawesome/pro-regular-svg-icons';
import RippleButton from "./RippleButton";

export interface ItemImageEditProps {

}

const ItemImageEdit: FunctionComponent<ItemImageEditProps> = (props: ItemImageEditProps) => {
    const store: Stores = useStore().store;

    const addImage = async (action: (options: ImagePickerOptions, callback: (callback: ImagePickerResponse) => void) => void) => {
        const options = { storageOptions: { skipBackup: true, path: 'images', } };
        action(options, (response) => {
            if (response.didCancel) {
                console.info('User cancelled image picker');
            } else {
                store.new_apartment.addImage(response.uri);
            }
        });
    }
    return (
        <View
        >
            <View style={styles.container}>
                <View style={styles.buttonsWrapper}>
                    <Text style={styles.header}>{store.translations.translations.add_images}</Text>
                    <View>
                        <TouchableOpacity style={styles.iconWrapper} onPress={() => addImage(launchImageLibrary)}>
                            <FontAwesomeIcon icon={faFolderUpload} style={styles.icon} size={28} />
                        </TouchableOpacity>
                    </View>
                    <View>
                        <TouchableOpacity style={styles.iconWrapper} onPress={() => addImage(launchCamera)}>
                            <FontAwesomeIcon icon={faCameraRetro} style={styles.icon} size={28} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.imagesContainer}>
                    {
                        store.new_apartment.images.length === 0 ?
                            <View style={styles.noPhotosWrapper}>
                                <Text style={styles.noPhotosText}>{store.translations.translations.items_with_photos_get_more_attention}</Text>
                            </View>
                            :
                            store.new_apartment.images.map((x, i) => {
                                return (
                                    <View key={i} style={styles.oneImageContainer}>
                                        <Image style={styles.oneImage} source={{ uri: x }} />

                                        <RippleButton
                                            style={styles.imageCloserWrapper}
                                            onPress={() => { store.new_apartment.removeImage(i); }}
                                        >
                                            <Text style={styles.imageCloser}>X</Text>
                                        </RippleButton>

                                    </View>
                                )
                            })
                    }
                </View>
            </View>
        </View>
    )
}
export default observer(ItemImageEdit);

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 12,
        borderColor: '#dcebfc',
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 150
    },

    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    iconWrapper: {
        flex: 1,
        width: 100,
        paddingBottom: 5,
        paddingTop: 5,
        alignItems: 'center',
        backgroundColor: '#66adfa',
    },
    icon: {
        flex: 1,
        color: '#e0efff'
    },
    header: {
        fontSize: 24,
    },
    oneImage: {
        height: 100,
        width: 100,
        alignSelf: 'center',
    },
    oneImageContainer: {
        width: 120,
        height: 150,
        marginEnd: 15,
        marginTop: 20,
    },
    imageCloserWrapper: {
        backgroundColor: 'red',
        height: 25,
        width: 25,
        borderRadius: 20,
        position: 'absolute',
        end: 0,
        top: -15
    },
    imageCloser: {
        color: 'white',
        alignSelf: 'center',
        lineHeight: 25,
        fontSize: 16,
        marginTop: 14,
        position: 'absolute'
    },
    imagesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    noPhotosWrapper: {
        flex: 1,
        height: 80
    },
    noPhotosText: {
        alignSelf: 'center',
        lineHeight: 80,
        fontSize: 18,
        color: '#b8c8e0'
    },
})