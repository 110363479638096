import React, { FunctionComponent } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { RouteProp } from "@react-navigation/native";
import { View } from 'react-native';
import Day, { TimeInDay } from "./Day";
import { MyCalendarNavigationParams } from "../../pages/MyCalendar.page";
import { Stores, useStore } from "../../stores";
import { observer } from "mobx-react";



type MyCalendarDayNavigationProp = StackNavigationProp<MyCalendarNavigationParams, 'my_calendar_day'>;

type MyCalendarDayRouteProp = RouteProp<MyCalendarNavigationParams, 'my_calendar_day'>;

interface MyCalendarDayProps {
    navigation: MyCalendarDayNavigationProp;
    route: MyCalendarDayRouteProp;
}


const MyCalendarDay: FunctionComponent<MyCalendarDayProps> = function (props: MyCalendarDayProps) {
    const store: Stores = useStore().store;

    return (
        <View style={{ flex: 1 }}>
            <Day
                events={store.user.calendar_selected_date_events}
                start={props.route.params.start}
                end={props.route.params.end}
                isBig={true}
                timeClick={(fromClick: TimeInDay) => {
                    // console.log('fromClick is: ', fromClick);
                    // props.navigation.navigate("my_calendar_day", fromClick);
                }}
            />
        </View>
    )
}
export default observer(MyCalendarDay);