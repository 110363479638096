import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { View, StyleSheet, TextInput } from "react-native";
import CountryPicker from "../forms/CountryPicker";
import MKColor from "../../services/palette.service";
import { Stores, useStore } from "../../stores";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";


export interface PhonePickerProps {
    onValueChange: (val: string) => void;
    phone: string;
    
}


const PhonePicker: FunctionComponent<PhonePickerProps> = (props: PhonePickerProps) => {
    const store: Stores = useStore().store;

    return (
        <View style={styles.container}>
            <CountryPicker value={store.new_apartment.country_code} />
            <TextInput
                style={styles.phoneInput}
                // keyboardType='number-pad'
                placeholder={store.translations.translations.phone}
                value={props.phone}
                onChangeText={(val: string) => {
                    props.onValueChange(val);
                }}
            />
            {
                store.new_apartment.phone_validated === true && store.new_apartment.country_code_selected ?
                    <FontAwesomeIcon
                        icon={faCheck}
                        color={MKColor.Green}
                        size={30}
                        style={styles.checkMark}
                    />
                    :
                    store.new_apartment.phone_validated === false ?
                        <FontAwesomeIcon
                            icon={faTimes}
                            color={MKColor.Red}
                            size={30}
                            style={styles.checkMark}
                        />
                        :
                        null
            }
        </View>
    )
}

export default observer(PhonePicker);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        direction: 'ltr',
    },

    phoneInput: {
        flex: 3,
        direction: 'ltr',
    },
    checkMark: {
        alignSelf: 'center',
        flex: 1,
        direction: 'ltr',

    }
});