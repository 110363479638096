import { GoogleLocationResult } from "go2c-shared";
import { observer } from "mobx-react";
import React from 'react';
import { StyleSheet } from "react-native";
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { Stores, useStore } from '../../stores';

// Google Places API key
// AIzaSyAVelI0unlQntDvCyRVypfIgZyh0LU5nPk

// <GoogleAutoComplete
//      language={NativeModules.I18nManager.localeIdentifier}
//      apiKey="AIzaSyAVelI0unlQntDvCyRVypfIgZyh0LU5nPk"
//      debounce={300}
//      components={"country:IL"}
//      queryTypes={'(cities)'}
// >

const AddressAutocomplete: React.FC = () => {
    const store: Stores = useStore().store;
    return (
        <GooglePlacesAutocomplete
            placeholder='Search'
            onPress={(data, details = null) => {
                store.new_apartment.setRawAddress(data.description);
                store.new_apartment.setAddress(data as unknown as GoogleLocationResult);
                store.new_apartment.setAddressSelected(true);
            }}
            query={{
                key: "react-native-google-autocomplete",
                language: 'en',
            }}
            requestUrl={{
                useOnPlatform: 'web', // or "all"
                url:'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api',
            }}
            key='AIzaSyAZFwnpu359WVLq59J6ljqkFvVitOpjHAQ'
        />
    )
}

export default observer(AddressAutocomplete);

const styles = StyleSheet.create({
    item: {
        borderStartColor: "gray",
        borderStartWidth: 1,
        paddingBottom: 5,
        padding: 2,
        marginVertical: 8,
        marginHorizontal: 16,
    },
    title: {
        fontSize: 15,
    },
    addressInput: {
        flex: 1,
        fontSize: 22,
    },
    itemsWrapper: {
        flex: 1,
        marginLeft: 5,
    },
    noResultsWrapper: {
        marginTop: 5
    },
    noResultsText: {
        color: '#bdbdbd'
    },
    clearButton: {
        alignSelf: 'center',
        height: 25,
        width: 25,
        borderRadius: 15,
        backgroundColor: "#e1e3ed",
        top: 5,

    },
    clearButtonText: {
        alignSelf: 'center',
        textAlign: 'center',
        padding: 11,
        color: '#f4f4f4',
    },
    inputWrapper: {
        flexDirection: 'row'
    }
});