import React, { FC } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { EditableTextFieldProps } from './TextField.interface';
import { useState } from 'react';
import TextField from './TextField';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import textFieldStyles from './TextField.styles';

const EditableTextField: FC<EditableTextFieldProps> = (props) => {
    const [editing, setEditing] = useState<boolean>(false);
    return (
        <View style={textFieldStyles.editableWrapper}>
            <Text>{props.label}</Text>
            {editing ? <TextField {...props} /> : <Text>{props.value}</Text>}
            {props.editable && (
                <TouchableOpacity
                    onPress={() => {
                        setEditing(!editing);
                    }}>
                    <FontAwesomeIcon color={'green'} icon={faEdit} />
                </TouchableOpacity>
            )}
        </View>
    );
};

export default EditableTextField;
