import React, { FunctionComponent, PropsWithChildren } from "react";
import { TouchableNativeFeedback, View, StyleProp, ViewStyle, StyleSheet, Platform, TouchableOpacity } from "react-native";

export interface RippleButtonParams {
    style: StyleProp<ViewStyle>;
    onPress(): void;
    rippleColor?: string;
    disabled?: boolean;
}

type IRippleButton = PropsWithChildren<RippleButtonParams>;


const RippleButton: FunctionComponent<IRippleButton> = (props: IRippleButton) => {
    return (
        <View style={[styles.touchable, props.style]}>
            {
                Platform.OS === 'web' ?

                    <TouchableOpacity
                        onPress={props.onPress}
                        style={props.style}
                        disabled={props.disabled || false}
                    >
                        {props.children}
                    </TouchableOpacity>
                    :
                    Platform.OS === 'android' ?

                        <TouchableNativeFeedback
                            background={TouchableNativeFeedback.Ripple(props.rippleColor || '#EEE', false)}
                            onPress={props.onPress}
                            useForeground={true}
                            style={styles.touchable}
                            disabled={props.disabled || false}
                        >
                            <View
                                style={props.style}
                            >
                                {props.children}
                            </View>
                        </TouchableNativeFeedback>
                        :
                        <TouchableOpacity
                            onPress={props.onPress}
                            style={props.style}
                            disabled={props.disabled || false}
                        >
                            {props.children}
                        </TouchableOpacity>
            }
        </View>
    )
}

export default RippleButton;

const styles = StyleSheet.create({
    touchable: {
        overflow: 'hidden',

    },
})