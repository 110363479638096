import React, { FunctionComponent } from 'react';
import { View, StyleSheet, Text, TextStyle } from 'react-native';
import { numberWithCommas } from '../services/utils.service';
import { useStore, Stores } from '../stores';
import { observer } from 'mobx-react';

interface PriceProps {
    price: number;
    style?: TextStyle;
    endText?: string;
}

const Price: FunctionComponent<PriceProps> = (props: PriceProps) => {
    const store: Stores = useStore().store;
    const price = props.price * (store.user.user.currency ? store.user.user.currency.usd_multiplier : 1);
    if (!store.user.user) {
        return;
    }
    return (
        <View style={styles.container}>
            <Text style={{ ...styles.priceText, ...props.style }}>{numberWithCommas(price)}</Text>

            {props.endText ? (
                <Text style={props.style}>{props.endText}</Text>
            ) : (
                <Text style={{ ...props.style }}>{store.user.user.currency.symbol}</Text>
            )}
        </View>
    );
};

export default observer(Price);

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        flex: 1,
    },
    priceText: {
        marginEnd: 2,
    },
});
