import React, { FunctionComponent, useState } from 'react';
import { ScrollView, StyleSheet } from 'react-native';
import MonthCalendar from './calendar/MonthCalendar';
import { StackNavigationProp } from '@react-navigation/stack';
import { RouteProp } from '@react-navigation/native';
import { observer } from 'mobx-react';
import { Stores, useStore } from '../stores';
import { MyCalendarNavigationParams } from '../pages/MyCalendar.page';

export interface MyCalendarParams {
    num_of_days_in_week?: number;
    selected_date?: Date;
    week_starts_at?: string;
    months_to_load?: number;
}

type MyCalendarScreenNavigationProp = StackNavigationProp<MyCalendarNavigationParams, 'my_calendar_component'>;

type MyCalendarScreenRouteProp = RouteProp<MyCalendarNavigationParams, 'my_calendar_component'>;

interface INewItemFormPage {
    navigation: MyCalendarScreenNavigationProp;
    route: MyCalendarScreenRouteProp;
}
const MyCalendar: FunctionComponent<INewItemFormPage> = function (props: INewItemFormPage) {
    const store: Stores = useStore().store;
    const num_of_days_in_week: number = props.route.params.num_of_days_in_week || 7;
    const week_starts_at: string = props.route.params.week_starts_at || "MON";
    const selected_date: Date = store.user.calendar_selected_date || new Date();
    const [selectedDate, setSelectedDate] = useState(selected_date);

    return (
        <ScrollView style={styles.container}>
            <MonthCalendar
                num_of_days_in_week={num_of_days_in_week}
                selected_date={selectedDate}
                week_starts_at={week_starts_at}
                onSelect={(date: Date) => {
                    setSelectedDate(date);
                    store.user.setCalendarSelectedDate(date);
                    props.navigation.navigate('my_calendar_day')
                }}
                mappedEvents={store.user.mapped_events}
            />

        </ScrollView>
    );
}
export default observer(MyCalendar);

const styles = StyleSheet.create({
    container: {

    },
    buttons: {
        flex: 1,
        backgroundColor: "green",
        height: 50,
        padding: 2,
    },
    oneView: {
        flex: 1,
    },
    buttonsWrapper: {
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
        flex: 1,
    },
    icon: {
        flex: 1,
    },
    innerButtonsContainer: {
        height: 50,
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
});