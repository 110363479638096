'use strict';
import React, { FunctionComponent, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { observer } from 'mobx-react';
import { Stores, useStore } from '../../stores';
import { useNavigation } from '@react-navigation/native';
import { styles as imagestyle } from './ItemImagesEdit';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFolderUpload, faVideoPlus } from '@fortawesome/pro-regular-svg-icons';
import * as ImagePicker from 'expo-image-picker';

import RippleButton from './RippleButton';
import { Video } from 'expo-av';

const PendingView = () => (
    <View
        style={{
            flex: 1,
            backgroundColor: 'lightgreen',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <Text>Waiting</Text>
    </View>
);

const ItemVideosEdit: FunctionComponent = () => {
    const store: Stores = useStore().store;
    const navigation = useNavigation();

    const addVideo = async (action: (options, callback: (callback) => void) => void) => {
        const options = { mediaType: 'video' };
        action(options, (response) => {
            if (response.didCancel) {
                console.log('User cancelled image picker');
            } else if (response.error) {
                console.log('ImagePicker Error: ', response);
            } else if (response.customButton) {
                console.log('User tapped custom button: ', response.customButton);
            } else {
                // store.new_apartment.addImage(response.uri);
                // console.log('response.uri is: ', response.uri);
                store.new_apartment.addVideo(response.uri);
            }
        });
    }
    return (
        <View
        >
            <View style={imagestyle.container}>
                <View style={imagestyle.buttonsWrapper}>
                    <Text style={imagestyle.header}>{store.translations.translations.add_video}</Text>
                    <View>
                        <TouchableOpacity
                            style={imagestyle.iconWrapper}
                            onPress={() => {
                                addVideo(ImagePicker.launchImageLibraryAsync);
                            }}
                        >
                            <FontAwesomeIcon icon={faFolderUpload} style={imagestyle.icon} size={28} />
                        </TouchableOpacity>
                    </View>
                    <View>
                        <TouchableOpacity
                            style={imagestyle.iconWrapper}
                            onPress={() => { navigation.navigate('take_video'); }}
                        >
                            <FontAwesomeIcon icon={faVideoPlus} style={imagestyle.icon} size={28} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={imagestyle.imagesContainer}>
                    {
                        store.new_apartment.videos.length === 0 ?
                            <View style={imagestyle.noPhotosWrapper}>
                                <Text style={imagestyle.noPhotosText}>{store.translations.translations.items_with_videos_get_more_attention}</Text>
                            </View>
                            :
                            store.new_apartment.videos.map((video, index) => {
                                return (
                                    <View key={index} style={imagestyle.oneImageContainer}>

                                        <Video
                                            source={{ uri: video.source }}
                                            isMuted={store.new_apartment.videos[index].muted}
                                            onLoad={() => {
                                                store.new_apartment.setMuted(index, true);
                                            }}
                                            volume={0}
                                            style={[imagestyle.oneImage]}
                                            rate={1}
                                            resizeMode="contain"
                                            useNativeControls={false}
                                            shouldPlay
                                            isLooping
                                        />
                                        <RippleButton
                                            style={imagestyle.imageCloserWrapper}
                                            onPress={() => {
                                                store.new_apartment.removeVideo(index);
                                            }}
                                        >
                                            <Text style={imagestyle.imageCloser}>X</Text>
                                        </RippleButton>

                                    </View>
                                )
                            })
                    }
                </View>

            </View>
        </View>
    );

}

export default observer(ItemVideosEdit);

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'black',
        height: 250,
    },
    preview: {
    },
    capture: {
        flex: 0,
        backgroundColor: '#fff',
        borderRadius: 5,
        padding: 15,
        paddingHorizontal: 20,
        alignSelf: 'center',
        margin: 20,
    },
    backgroundVideo: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    },
});
