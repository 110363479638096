import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { StyleSheet, Text, View, TextInput as Input, ListView, FlatList } from 'react-native';
import { useStores } from "../../stores";
import ErrorList from "../forms/errors/ErrorList";
import NextButton from "../forms/NextButton";
import TextField from "../forms/text_field/TextField";
import validateEmail from './ValidateEmail.service';
import profileStyles from './Profile.styles';
import OneError from "../forms/errors/OneError";


const ValidateEmail: FunctionComponent = () => {
    const store = useStores();
    const formErrors = store.profile.getValidateEmailFormErrors
    const onSubmitEmail = async () => {
        const emailValidated = await validateEmail(store);
        store.profile.setEmailSentResponse(emailValidated.success, emailValidated.message);
    }

    return (
        <View style={profileStyles.emailValidationWrapper}>
            <Text style={profileStyles.header}>{store.translations.translations.please_validate_email}</Text>
            <TextField
                value={store.profile.email_input}
                onChangeText={(val: string) => {
                    store.profile.setEmailInput(val)
                }}
                label={store.translations.translations.email}
                tintColor={store.profile.email_validated === true ? 'blue' : 'red'}
                autoCompleteType="email"
            />
            <TextField
                value={store.profile.username_input}
                onChangeText={(val: string) => {
                    store.profile.setUsernameInput(val)
                }}
                label={store.translations.translations.username}
                tintColor={store.profile.username_input !== '' ? 'blue' : 'red'}
            />
            <TextField
                value={store.profile.password_input}
                onChangeText={(val: string) => {
                    store.profile.setPasswordInput(val);
                }}
                label={store.translations.translations.password}
                tintColor={store.profile.password_validated === true ? 'blue' : 'red'}
                password
            />
            <TextField
                value={store.profile.repeat_password_input}
                onChangeText={(val: string) => {
                    store.profile.setPasswordRepeatInput(val);
                }}
                label={store.translations.translations.repeat_password}
                tintColor={store.profile.passwords_match === true ? 'blue' : 'red'}
                password
            />
            {
				!store.profile.validate_email_sent ?
                <ErrorList
                    errors={formErrors.map(err => { return { text: store.translations.translations[err] } })}
                />
				: 
				<OneError
					text={store.translations.translations[store.profile.validate_email_sent_message]} 
					isOk={store.profile.validate_email_success} />
            }
            <NextButton
                title={store.translations.translations.submit}
                active={formErrors.length === 0}
                onNext={onSubmitEmail}
            />



        </View>
    )
}

export default observer(ValidateEmail);
