import React, { FunctionComponent } from 'react';
// @ts-ignore
import { ITranslationDictionary } from 'go2c-shared';
import { StackNavigationProp } from "@react-navigation/stack";
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { RouteProp } from "@react-navigation/native";
import { observer } from 'mobx-react';
import { StyleSheet, View, Text, Platform, ScrollView } from 'react-native';
import { Stores, useStore } from '../../stores';
import RNPickerSelect from "../forms/picker/Picker";
import { styles as newItremStyles } from './styles';
import NextButton from '../forms/NextButton';
import Seperator from '../forms/Seperator';

export interface SelectTypeProps {

}

type SelectTypeScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'select_type'>;

type SelectTypeScreenRouteProp = RouteProp<NewItemNavigationParams, 'select_type'>;

interface SelectTypeFormPage {
    navigation: SelectTypeScreenNavigationProp;
    route: SelectTypeScreenRouteProp;
}

const SelectType: FunctionComponent<SelectTypeFormPage> = (props: SelectTypeFormPage) => {
    const store: Stores = useStore().store;
    const translations: ITranslationDictionary = store.translations.translations;
    const itemTypes = ["appartments", "second_hand"];
    const dealTypes = ["buy", "rent"];

    return (
        <ScrollView style={newItremStyles.page}>
            <View style={styles.pleaseSelectWrapper}>
                <Text style={styles.pleaseSelectText}>{translations.please_select_item_type_and_deal_type}</Text>
            </View>
            <View style={styles.middlePickerWrapper}>
                <RNPickerSelect
                    items={itemTypes.map(x => { return { label: translations[x], value: x, color: 'black' } })}
                    // @ts-ignore
                    style={styles.picker}
                    // useNativeAndroidPickerStyle={false}
                    value={store.new_apartment.type}
                    placeholder={{ label: translations.select_item_type + "...", value: '' }}
                    pickerProps={{ mode: 'dropdown' }}
                    onValueChange={(itemValue) => { store.new_apartment.setFormByKey(itemValue as string, 'type'); }}
                />

            </View>
            <View>
                <RNPickerSelect
                    items={dealTypes.map(x => { return { label: translations[x], value: x } })}
                    // @ts-ignore
                    style={styles.picker}
                    // useNativeAndroidPickerStyle={false}
                    value={store.new_apartment.deal_type}
                    placeholder={{ label: translations.select_deal_type + "...", value: '' }}
                    pickerProps={{ mode: 'dropdown' }}
                    onValueChange={(itemValue) => { store.new_apartment.setFormByKey(itemValue as string, 'deal_type'); }}
                />

            </View>
            {
                Platform.OS === 'ios' &&
                <Seperator />
            }
            <NextButton 
                title={translations.continue}
                active={store.new_apartment.deal_type !== null && store.new_apartment.type !== null}
                onNext={()=>{
                    props.navigation.navigate('phone_availability')
                }}
            />
            {/* <View style={styles.footerButtonsWrapper}>
                <CardView
                    cardElevation={5}
                    cardMaxElevation={8}
                    cornerRadius={7}
                >
                    {
                        store.new_apartment.deal_type && store.new_apartment.type ?
                            <TouchableOpacity style={styles.footerButton} onPress={() => props.navigation.navigate('phone_availability')}>
                                <Text style={styles.footerButtonText}>{translations.continue}</Text>
                            </TouchableOpacity>
                            :

                            <View style={[styles.footerButton, { backgroundColor: '#adbac4' }]}>
                                <Text style={[styles.footerButtonText, { color: '#6a7680' }]}>{translations.continue}</Text>
                            </View>
                    }
                </CardView>
            </View> */}
        </ScrollView>
    )
}

export default observer(SelectType);


const styles = StyleSheet.create({
    pleaseSelectWrapper: {
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        marginBottom: 25,
        marginTop: 25,
    },
    pleaseSelectText: {
        alignSelf: 'center',
        fontSize: 20,
        marginTop: 35,
    },
    middlePickerWrapper: {
        marginTop: Platform.OS === 'ios' ? 60 : 30,
        marginBottom: Platform.OS === 'ios' ? 60 : 30,
    },
    footerButtonsWrapper: {
        flex: 1,
        marginTop: Platform.OS === 'ios' ? 40 : 20,
        marginLeft: 10,
        marginRight: 10,
    },
    footerButton: {
        padding: 15,
        backgroundColor: '#5ba7fc',
    },
    footerButtonText: {
        alignSelf: 'center',
        fontSize: 20,
        color: 'white'
    },

})