import React, { FunctionComponent, useState } from 'react';
import { StackNavigationProp } from "@react-navigation/stack";
import { NewItemNavigationParams } from "../../pages/NewItem.page";
import { RouteProp } from "@react-navigation/native";
import { observer } from 'mobx-react';
import { StyleSheet, Text, ScrollView, View, TouchableOpacity, NativeSyntheticEvent, NativeTouchEvent, ActivityIndicator } from 'react-native';
import ItemImagesEdit from '../forms/ItemImagesEdit';
import ItemVideosEdit from '../forms/ItemVideosEdit';
import { Stores, useStore } from '../../stores';
import { styles as newItemStyles } from './NewItemForm';
import Request, { baseURL } from '../../services/axios.service';
import Upload, { UploadOptions } from 'react-native-background-upload';
import { ITranslationDictionary, GoogleLocationDetailResult, ICreateItemRequest, ICreateItemResponse } from 'go2c-shared';
import { styles as newItemProcessStyles } from './styles'

export interface AddMediaProps {

}

type AddMediaScreenNavigationProp = StackNavigationProp<NewItemNavigationParams, 'phone_availability'>;

type AddMediaScreenRouteProp = RouteProp<NewItemNavigationParams, 'phone_availability'>;

interface AddMediaFormPage {
    navigation: AddMediaScreenNavigationProp;
    route: AddMediaScreenRouteProp;
}

const AddMedia: FunctionComponent<AddMediaFormPage> = (props: AddMediaFormPage) => {
    const store: Stores = useStore().store;
    const translations: ITranslationDictionary = store.translations.translations;
    const [isLoadingMessage, setIsLoadingMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imageProgess, setImageProgess] = useState<string>("");

    const submitForm = async (el: NativeSyntheticEvent<NativeTouchEvent>) => {
        if (!store.new_apartment.address_selected) {
            return store.dialog.openPopup(translations.parameter_missing, translations.please_select_address);
        }
        if (!store.new_apartment.type) {
            return store.dialog.openPopup(translations.parameter_missing, translations.please_select_item_type);
        }
        // store.new_apartment.setLoading('Creating request');
        setIsLoadingMessage(translations.creating_request);
        setIsLoading(true);
        const detailedLocation: GoogleLocationDetailResult = await store.new_apartment.fetchDetails() as GoogleLocationDetailResult;
        let requestData: ICreateItemRequest = {
            raw_address: store.new_apartment.raw_address,
            floor: store.new_apartment.floor,
            total_floors_in_building: store.new_apartment.total_floors_in_building,
            price: store.new_apartment.price,
            email: store.new_apartment.email,
            location: detailedLocation,
            type: store.new_apartment.type,
            availabilities: store.new_apartment.events,
            description: store.new_apartment.description,
            has_livingroom: store.new_apartment.has_livingroom,
            has_air_conditioning: store.new_apartment.has_air_conditioning,
            deal_type: store.new_apartment.deal_type,
            rent_type: store.new_apartment.rent_type,
            rooms: store.new_apartment.rooms,
            bedrooms: store.new_apartment.bedrooms,
            furniture: store.new_apartment.furniture,
            size: store.new_apartment.size,
        };

        if (store.new_apartment.phone &&
            store.new_apartment.phone.area_code &&
            store.new_apartment.phone_validated === true &&
            store.translations.user_country.phone_code &&
            store.new_apartment.do_not_show_phone === false
        ) {
            requestData.phone = {
                phone: store.new_apartment.phone.formatted.split('-')[1],
                area_code: store.new_apartment.phone.area_code,
                country_code: String(store.translations.user_country.phone_code),
            }
        }
        setIsLoadingMessage(translations.sending_request);

        const fromSubmit: ICreateItemResponse = await Request({
            method: "POST",
            url: 'items',
            data: requestData
        });
        setIsLoadingMessage(translations.item_created_uploding_photos);

        if (!fromSubmit.success) {
            return store.dialog.openPopup('Server Error', fromSubmit.message);
        }
        for (let i = 0; i < store.new_apartment.images.length; i++) {
            setIsLoadingMessage(translations.item_created_uploding_photos + ' ' + String(i + 1) + ' ' + translations.out_of + ' ' + String(store.new_apartment.images.length));
            const imageUri: string = store.new_apartment.images[i];
            await uploadFiles(imageUri, fromSubmit.image_token);
        }
        for (let i = 0; i < store.new_apartment.videos.length; i++) {
            setIsLoadingMessage(translations.item_created_uploding_videos + ' ' + String(i + 1) + ' ' + translations.out_of + ' ' + String(store.new_apartment.videos.length));
            const videoUri: string = store.new_apartment.videos[i].source.replace('file://', '');
            await uploadFiles(videoUri, fromSubmit.image_token, true);
        }
        // store.new_apartment.closeLoader();
        setIsLoading(false);
        store.dialog.openPopup(translations.created_item_successfully, translations.created_item_successfully);
        setIsLoadingMessage('');
        setImageProgess('');
        setTimeout(() => { store.new_apartment.clearForm(); }, 50);
    }



    const uploadFiles = (uri: string, token: string, is_video?: boolean): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const options = {
                url: baseURL + 'items/postImages' + (is_video ? '?is_video=true' : ''),
                path: uri,
                method: 'POST',
                field: 'uploaded_media',
                type: 'multipart',
                maxRetries: 3,
                headers: {
                    'content-type': 'application/octet-stream',
                    // 'content-type': 'multipart/form-data',
                    'appartment-image-token': token,
                },
                notification: {
                    enabled: true
                }
            } as UploadOptions;
            Upload.startUpload(options).then((uploadId) => {
                Upload.addListener('progress', uploadId, (data) => {
                    setImageProgess(data.progress + '%');
                })
                Upload.addListener('error', uploadId, (data) => {
                    reject(false);
                })
                Upload.addListener('cancelled', uploadId, (data) => {
                    resolve(true);
                })
                Upload.addListener('completed', uploadId, (data) => {
                    resolve(true);
                })
            }).catch((err) => {
                reject(false);
            })
        })
    }



    return (
        <ScrollView style={newItemProcessStyles.page}>
            <View style={styles.padder}></View>
            <ItemImagesEdit />
            <View style={styles.smallPadder}></View>

            <View style={newItemStyles.seperator}></View>
            <View style={styles.smallPadder}></View>

            <ItemVideosEdit />
            {
                isLoading &&
                <View style={{ ...newItemStyles.textWithLabel, marginTop: 40 }}>
                    <Text style={{ ...newItemStyles.label, flex: 3 }}>{isLoadingMessage} {imageProgess}</Text>
                    <View style={{ flex: 1, alignSelf: 'center' }}>
                        <ActivityIndicator size="large" color="#5ba7fc" />
                    </View>
                </View>
            }
            <View style={newItemStyles.textWithLabel}>

                <View style={newItemStyles.footerButtonsWrapper}>
                    <View>
                        <TouchableOpacity style={newItemStyles.footerButton} onPress={submitForm}>
                            <Text style={newItemStyles.footerButtonText}>{store.translations.translations.create}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>

        </ScrollView>
    )

}

export default observer(AddMedia);

const styles = StyleSheet.create({
    padder: {
        height: 40
    },
    smallPadder: {
        height: 25,
    }
})