import React, { FunctionComponent } from "react";
import { View, StyleSheet } from "react-native";

const Seperator:FunctionComponent = ()=>{
    return (
        <View style={styles.seperator}></View>
    )
}

export default Seperator;

const styles = StyleSheet.create({
    
    seperator: {
        height: 1,
        backgroundColor: '#bdbdbd',
        marginTop: 15,
        marginBottom: 15,
    },
})