import { Dimensions, StyleSheet } from 'react-native';
import ScreenSize from '../../services/screen.service';

const profileStyles = StyleSheet.create({
    emailValidationWrapper: {
        padding: 25,
    },
    header: {
        fontSize: 28,
        textAlign: 'center',
        marginTop: 50,
    },
    emailConfirmedMessageWrapper: {
        margin: 'auto',
        marginTop: 150,
        marginBottom: 150,
    },
    profilePageWrapper: {
        width: '80%',
        margin: 'auto',
        marginTop: ScreenSize.up('md') ? 200 : 0,
    },
});

export default profileStyles;
