import { Dimensions } from 'react-native';

export type ScreenSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export interface BreakPoint {
    maxWidth: number;
    name: ScreenSizes;
    index?: number;
}

export const breakPoints: BreakPoint[] = [
    { maxWidth: 0, name: 'xs' },
    { maxWidth: 600, name: 'sm' },
    { maxWidth: 960, name: 'md' },
    { maxWidth: 1280, name: 'lg' },
    { maxWidth: 1960, name: 'xl' },
];

class ScreenSize {
    width: number;
    screenSize: BreakPoint;
    constructor() {
        this.width = Dimensions.get('screen').width;
        this.screenSize = this.getScreenSize();
    }

    getScreenSize(): BreakPoint {
        for (let i = 0; i < breakPoints.length; i++) {
            const point = breakPoints[i];
            if (this.width >= point.maxWidth && this.width <= breakPoints[i + 1].maxWidth) {
                return { ...point, index: i };
            }
        }
        return { ...breakPoints[breakPoints.length - 1], index: breakPoints.length - 1 };
    }

    getScreenSizeIndex(size: ScreenSizes) {
        for (let i = 0; i < breakPoints.length; i++) {
            if (breakPoints[i].name == size) {
                return i;
            }
        }
    }

    up(size: ScreenSizes): boolean {
        return this.screenSize.index >= this.getScreenSizeIndex(size);
    }

    down(size: ScreenSizes): boolean {
        return this.screenSize.index <= this.getScreenSizeIndex(size);
    }
}

export default new ScreenSize();
