import axios from 'axios';
import { action } from 'mobx';
import publicIp from 'public-ip';
import { I18nManager, Platform } from 'react-native';
import { getDevice, getManufacturer, getUniqueId, getUserAgent } from 'react-native-device-info';
import { GeoPosition } from 'react-native-geolocation-service';
import { getCurrencies, getLocales, Locale } from 'react-native-localize';
import { hydrateState, Stores } from '../stores';
import { init } from './axios.service';
import { initFirebase } from './firebase.service';
import { setMomentLocale, setMomentTimeZone } from './moment.service';
import { getStorage, randomId } from './utils.service';
import Request from './axios.service';
import { ILoginRequest, IItem } from 'go2c-shared';

import moment from 'moment';
import { IUserStore } from '../stores/user.store';

async function initialProcess(stores: Stores) {
    // let userStateInStorage = await getStorage().getItem('user');
    // if (userStateInStorage) {
    //     try {
    //         const userState: IUserStore = JSON.parse(userStateInStorage);
    //         if (userState?.did_init === true && moment(new Date(userState.did_init_time)).isAfter(moment(new Date()).subtract(3, 'hours'))) {
    //             // stores.user.setInit(false);
    //             stores.user.setUser(userState.user);
    //             stores.user.setInit();

    //         }
    //     } catch (e) {
    //         console.log('e in app js is: ', e);
    //     }
    // }
    try {
        window.onresize = (ev: UIEvent) => {
            stores.translations.setWindowDimensions();
        };
        const locale: Locale = getLocales()[0];
        // const i18locale = I18nManager.forceRTL(true);
        // console.log('i18locale is: ', i18locale);
        stores.translations.setLoadingNumber('2');
        // const countryCode:string = .countryCode;
        stores.translations.setCountryCode(locale.countryCode);
        stores.new_apartment.setcountryCode(locale.countryCode);
        stores.translations.setLanguageCode(locale.languageCode);

        stores.translations.setLoadingNumber('3');

        stores.translations.setUuid(Platform.OS === 'web' ? randomId() : getUniqueId());
        stores.translations.setLoadingNumber('4');

        stores.translations.setCurrency(getCurrencies()[0]);
        stores.translations.setLoadingNumber('5');
        // console.log('getCurrencies()[0] is: ', getCurrencies()[0]);
        setMomentLocale(stores.translations.language_code);
        stores.translations.setLoadingNumber('6');

        setMomentTimeZone();
        stores.translations.setLoadingNumber('7');
        if (Platform.OS === 'web') {
            const links = [
                {
                    src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAVelI0unlQntDvCyRVypfIgZyh0LU5nPk',
                    async: true,
                },
                {
                    src: 'https://www.gstatic.com/firebasejs/8.2.1/firebase-app.js',
                    async: false,
                },
                {
                    src: 'https://www.gstatic.com/firebasejs/8.2.1/firebase-analytics.js',
                    async: false,
                },
            ];

            for (let j = 0; j < links.length; j++) {
                // const element = links[j];

                const script = document.createElement('script');

                script.src = links[j].src;
                script.async = links[j].async;

                document.body.appendChild(script);
            }
        }

        if (Platform.OS === 'web') {
            const ipAddress = await publicIp.v4();
            const ipData = await axios.get(
                `https://api.ipstack.com/${ipAddress}?access_key=b47f1113c59769967cee53fa5f570f41&format=1`,
                { withCredentials: false }
            );
            stores.translations.setCountryCode(locale.countryCode);
            stores.new_apartment.setcountryCode(locale.countryCode);
            stores.translations.setLanguageCode(locale.languageCode);
            stores.translations.setCurrency(getCurrencies()[0]);
            setMomentLocale(stores.translations.language_code);
        }
        //     setMomentTimeZone();

        // 1. Get device data
        stores.translations.setLoadingNumber('8');

        const deviceData = await getDevice();
        stores.translations.setLoadingNumber('9');

        const manufacturer = await getManufacturer();
        const userAgent = await getUserAgent();
        stores.translations.setLoadingNumber('10');

        // const deviceId = getDeviceId();
        // console.log('deviceId is: ', deviceId);
        // 2. get and set location
        stores.translations.setLoadingNumber('11');
        // let location: GeoPosition = Platform.OS === 'web' ? null : await getLocation();
        let location: GeoPosition;
        stores.translations.setLoadingNumber('12' + JSON.stringify(location));

        // console.log('location outside is: ',location);
        const lat: number = (location && location.coords.latitude) || 32.0853;
        stores.translations.setLoadingNumber('13');

        const lng: number = (location && location.coords.longitude) || 34.7818;
        stores.translations.setLoadingNumber('14');

        if (Platform.OS === 'web') {
            // stores.items.setLocation(ipData.data.latitude, ipData.data.longitude);
            // stores.translations.setLanguageCode(ipData.data.location.languages[0].code);
        } else {
            stores.translations.setLanguageCode(locale.languageCode);

            // location = await getLocation();
            stores.translations.setLoadingNumber('12' + JSON.stringify(location));

            // console.log('location outside is: ',location);
            const lat: number = (location && location.coords.latitude) || 32.0853;
            stores.translations.setLoadingNumber('13');

            const lng: number = (location && location.coords.longitude) || 34.7818;
            stores.items.setLocation(lat, lng);
        }

        stores.translations.setLoadingNumber('15');

        // 3. get firebase data and register listeners
        stores.translations.setLoadingStatus('fetching_data');
        stores.translations.setLoadingNumber('16');

        const firebaseToken = await initFirebase();
        stores.translations.setLoadingNumber('17' + JSON.stringify(firebaseToken));

        // 4. Init axios service and fetch data from server
        init();
        stores.translations.setLoadingNumber('18');
        const loginRequestData: ILoginRequest = {
            device: {
                uuid: stores.translations.uuid,
                industrial_design: deviceData,
                manufacturer,
                browser: Platform.OS === 'web',
            },
            location: {
                latitude: stores.items.latitude,
                longitude: stores.items.longitude,
                latitudeDelta: stores.items.latitude_delta,
                longitudeDelta: stores.items.latitude_delta,
            },
            language_code: stores.translations.language_code,
            currency: stores.translations.currency,
            firebase_id: firebaseToken,
            country_code: stores.translations.country_code,
        };
        stores.translations.setLoadingNumber('19');

        const loginResponse = await Request({
            method: 'POST',
            url: 'auth/login/device',
            data: loginRequestData,
            withCredentials: true,
        });
        stores.translations.setLoadingNumber('20');
        // console.log('loginResponse is: ', loginResponse);
        // 5. Set data from server to state
        if (loginResponse.success) {
            stores.translations.setTranslations(loginResponse.texts);
            stores.translations.setLoadingNumber('21');

            stores.items.setItemsList(loginResponse.items);
            stores.translations.setLoadingNumber('22');

            stores.items.setImagesApi(loginResponse.images_api);

            stores.my_items.setItems(loginResponse.my_items as IItem[]);
            stores.translations.setLoadingNumber('23');
            // console.log('loginResponse.user is: ' ,loginResponse.user);
            stores.user.setUser(loginResponse.user);
            stores.profile.setUsernameInput(loginResponse.user.username);
            stores.translations.setLoadingNumber('24');

            stores.user.setNotifications(loginResponse.notifications);
            stores.translations.setLoadingNumber('25');

            stores.user.setEvents(loginResponse.events);
            stores.translations.setLoadingNumber('26');

            stores.translations.setLoadingStatus('loading_media');
            stores.translations.setLoadingNumber('27');

            stores.translations.setAllCountries(loginResponse.countries);
            stores.translations.setLoadingNumber('28');

            stores.translations.setUserCountry(loginResponse.user_country);
            stores.translations.setLoadingNumber('29');
        }
        setTimeout(() => {
            stores.translations.setLoadingNumber('30');

            stores.translations.setLoadingStatus('finalizing');
            stores.translations.setLoadingNumber('31');
            stores.user.setInit(true);
            hydrateState().then(() => {
                // console.log('hydrated');
            });
            stores.translations.setLoadingNumber('32');
        }, 1000);
    } catch (error) {
        alert(JSON.stringify(error));
    }
}

export const initApp = action(async (state: Stores) => {
    try {
        state.user.unInit();
        await initialProcess(state);
        return;
    } catch (error) {
        alert(JSON.stringify(error));
    }
});
