import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import { StyleSheet, ScrollView, View, Text } from 'react-native';
import { Stores, useStore } from '../../stores';
import { ProfilePageProps } from './Profile.interface';
import ValidateEmail from './ValidateEmail';
import EditableTextField from '../forms/text_field/EditableTextField';
import profileStyles from './Profile.styles';

const ProfilePage: FunctionComponent<ProfilePageProps> = function (props: ProfilePageProps) {
    const store: Stores = useStore().store;
    return (
        <ScrollView style={profileStyles.profilePageWrapper}>
            {!store.user.user.email_validated ? (
                <ValidateEmail />
            ) : (
                <View>
                    <Text>Edit profile</Text>
                    <EditableTextField
                        value={store.user.user.username}
                        label={store.translations.translations.user}
                        onChangeText={(text) => {
                            console.log('text is: ', text);
                        }}
                        editable
                    />
                    <EditableTextField
                        value={store.user.user.password}
                        label={store.translations.translations.password}
                        onChangeText={(text) => {
                            console.log('text is: ', text);
                        }}
                        editable
                    />
                    <EditableTextField
                        value={store.user.user.email}
                        label={store.translations.translations.email}
                        onChangeText={(text) => {
                            console.log('text is: ', text);
                        }}
                        editable
                    />
                </View>
            )}
            {/* <ValidateEmail /> */}
        </ScrollView>
    );
};

export default observer(ProfilePage);

const styles = StyleSheet.create({});
