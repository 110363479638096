import React, { FC } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import MKColor from '../../../services/palette.service';
import MkColor from '../../../services/palette.service';
import { FormError } from './Error.interface';

const OneError: FC<FormError> = (props: FormError) => {
    return (
        <View style={styles.wrapper}>
            <FontAwesomeIcon
                icon={!props.isOk ? faTimes : faCheck}
                color={!props.isOk ? MkColor.Red : MKColor.Green}
                size={30}
                style={styles.errorIcon}
            />
            <Text style={[!props.isOk ? styles.error : styles.checked, styles.text]}>{props.text}</Text>
        </View>
    );
};
export default OneError;

const styles = StyleSheet.create({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    errorIcon: {},
    checked: {
        color: MkColor.Green,
    },
    error: {
        color: MkColor.Red,
    },
    text: {},
});
