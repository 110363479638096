import { DrawerNavigationProp } from '@react-navigation/drawer';
import { RouteProp } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { INotification } from 'go2c-shared';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import AcceptMeeting, { AcceptMeetingParams } from '../components/AcceptMeeting';
import BigDay from '../components/calendar/BigDay';
import { DayProps } from '../components/calendar/Day';
import Item from '../components/item/Item';
import CurrentItemCalendar from '../components/item/ItemCalendar';
import ScheduleAtTheLocation, { ScheduleAtTheLocationParams } from '../components/item/SechduleAtTheLocation';
import Map from '../components/map/Map';
import ShowVideo from '../components/media/ShowVideo';
import { MainNavigationParams } from '../components/Navigation';
import { OpenDrawerParams } from '../components/OpenDrawer';
import RescheduleMeetingDay, { RescheduleMeetingDayParams } from '../components/RescheduleMeetingDay';
import SearchFilters, { SearchFilterParams } from '../components/SearchFilters';
import SendInvitation from '../components/SendInvitation';
import { Stores, useStore } from '../stores';

const Stack = createStackNavigator();

type MapPageNavigationProp = DrawerNavigationProp<MainNavigationParams, 'map_view'>;

type MapPageRouteProp = RouteProp<MainNavigationParams, 'map_view'>;

interface MapPageProps {
    navigation: MapPageNavigationProp;
    route: MapPageRouteProp;
}


export type CurrentItemAvailabilityParams = {
    num_of_days_in_week?: number;
    selected_date?: Date;
    week_starts_at?: string;
    months_to_load?: number;
}

export interface BigDayProps extends DayProps {
    action?: (page: string, navParams: SendInvitationProps) => void;
    event_types: string[];
    item_id?: string;
}

export interface SendInvitationProps {
    hours: number;
    minutes: number;
    type: string;
    time_started: string;
    time_ended: string;
    meeting?: INotification;
}

export interface MapParams {

}

export interface ItemParams {
    
}

export type CurrentItemNavigationParams = {
    availability: CurrentItemAvailabilityParams;
    day: BigDayProps;
    send_invitation: SendInvitationProps;
    map: MapParams;
    item: ItemParams;
    search_filters: SearchFilterParams;
    accept_meeting: AcceptMeetingParams;
    reschedule_meeting_day: RescheduleMeetingDayParams;
    schedule_at_the_location: ScheduleAtTheLocationParams;
    openDrawer: OpenDrawerParams;
}



const MapPage: FunctionComponent<MapPageProps> = (props: MapPageProps) => {
    const stores: Stores = useStore().store;

    return (
        <Stack.Navigator>
            <Stack.Screen
                name="map"
                component={Map}
                options={{ headerShown: false }}
            />
            <Stack.Screen
                name="item"
                component={Item}
                options={{ headerTitle: stores.items.current_item?.raw_address }}
            />
            <Stack.Screen
                name="availability"
                component={CurrentItemCalendar}
                options={{
                    headerTitle: stores.translations.translations.availabilities
                }}
            />
            <Stack.Screen
                name='search_filters'
                component={SearchFilters}
                options={{
                    headerTitle: stores.translations.translations.search_filters
                }}
            />
            <Stack.Screen
                name="day"
                component={BigDay}
                options={{
                    headerTitle: moment(stores.items.current_day).format('dddd, MMMM Do YYYY')
                }}
                initialParams={{
                    start: 6,
                    end: 24
                }}
            />
            <Stack.Screen
                name="send_invitation"
                component={SendInvitation}
                initialParams={{ hours: 6, minutes: 0 }}
                options={{
                    headerTitle: stores.translations.translations.send_invitation
                }}
            />
            <Stack.Screen
                name="accept_meeting"
                component={AcceptMeeting}
                initialParams={{
                }}
                options={{
                    headerTitle: stores.translations.translations.accept_meeting
                }}
            />
            <Stack.Screen
                component={RescheduleMeetingDay}
                name='reschedule_meeting_day'
                options={{
                    headerTitle: stores.translations.translations.day
                }}
                initialParams={{
                    start: 6,
                    end: 24,
                    events: []
                }}
            />
            <Stack.Screen
                component={ScheduleAtTheLocation}
                name='schedule_at_the_location'
                options={{
                    headerTitle: stores.translations.translations.day
                }}
            />

            <Stack.Screen
                component={ShowVideo}
                name='video'
                options={{
                    headerTitle: stores.items.current_item?.raw_address || stores.translations.translations.loading_video
                }}
            />
        </Stack.Navigator>
    );
}
export default MapPage


