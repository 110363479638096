import React, { FunctionComponent } from "react"
import MKColor from "../../services/palette.service";

import { Text, StyleSheet, ViewStyle } from "react-native";
import RippleButton from "./RippleButton";

export interface NextButtonProps {
    title: string;
    active: boolean;
    containerStyle?: ViewStyle;
    onNext: () => void;
}


const NextButton: FunctionComponent<NextButtonProps> = (props: NextButtonProps) => {

    return (
        <RippleButton
            onPress={()=>{
                if (props.active) {
                    props.onNext();
                }
            }}
            style={[style.container, { backgroundColor: props.active ? MKColor.Blue : MKColor.Grey }, props.containerStyle]}
            disabled={!props.active}
        >
            <Text style={style.buttonText}>{props.title}</Text>
        </RippleButton>
    )
}

export default NextButton;

const style = StyleSheet.create({
    container: {
        bottom: 0,
        width: '100%',
    },
    buttonText: {
        fontSize: 24,
        color: 'white',
        textAlign: 'center',
        padding: 15,
    }
})