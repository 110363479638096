import { MainNavigationParams } from "../components/Navigation";
import { DrawerNavigationProp } from "@react-navigation/drawer";
import { RouteProp } from "@react-navigation/native";
import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { createStackNavigator } from "@react-navigation/stack";
import MyCalendar, { MyCalendarParams } from "../components/MyCalendar";
import { Stores, useStore, stores } from "../stores";
import { DayProps } from "../components/calendar/Day";
import MyCalendarDay from "../components/calendar/MyCalendarDay";
import OpenDrawer from "../components/OpenDrawer";

const Stack = createStackNavigator();


type MyCalendarPageNavigationProp = DrawerNavigationProp<MainNavigationParams, 'my_calendar'>;

type MyCalendarPageRouteProp = RouteProp<MainNavigationParams, 'my_calendar'>;

interface MyCalendarProps {
    navigation: MyCalendarPageNavigationProp;
    route: MyCalendarPageRouteProp;
}

interface MyCalendarDayParams extends DayProps {
}



export type MyCalendarNavigationParams = {
    my_calendar_component: MyCalendarParams;
    my_calendar_day: MyCalendarDayParams;
}


const MyCalendarPage: FunctionComponent<MyCalendarProps> = function (props: MyCalendarProps) {
    const store: Stores = useStore().store;
    return (
        <Stack.Navigator>
            <Stack.Screen
                component={MyCalendar}
                initialParams={{
                    week_starts_at: "SUN",
                    num_of_days_in_week: 7,
                }}
                name="my_calendar_component"
                options={{
                    headerTitle: store.translations.translations.my_calendar,
                    headerLeft: () => (<OpenDrawer navigation={props.navigation} />)
                }}
            />
            <Stack.Screen
                name="my_calendar_day"
                component={MyCalendarDay}
                options={{
                    headerTitle: store.translations.translations.day
                }}
                initialParams={{
                    start: 6,
                    end: 24
                }}
            />
            {/**
            
            <Stack.Screen
                name="accept_meeting"
                component={AcceptMeeting}
                initialParams={{
                }}
                options={{
                    headerTitle: store.translations.translations.accept_meeting
                }}
            />
            <Stack.Screen
                component={RescheduleMeetingDay}
                name='reschedule_meeting_day'
                options={{
                    headerTitle: store.translations.translations.day
                }}
                initialParams={{
                    start: 6,
                    end: 24,
                    events: []
                }}
            />
            
            */}

        </Stack.Navigator>
    )
}
export default observer(MyCalendarPage);
