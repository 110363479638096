import { Stores } from '../../stores/index';
import Request from '../../services/axios.service';
import { ITranslationName } from 'go2c-types';

const validateEmail = async (store: Stores): Promise<{ success: boolean; message: ITranslationName }> => {
    console.log('store.profile.password_input is: ', store.profile.password_input);
    return await Request({
        method: 'POST',
        url: 'users/email',
        data: {
            email: store.profile.email_input,
            username: store.profile.username_input,
            password: store.profile.password_input,
        },
    });
};

export const validateToken = async (token: string): Promise<boolean> => {
    const fromValidation = await Request({
        method: 'GET',
        url: 'users/email/' + token,
    });
    return fromValidation.success;
};

export default validateEmail;
