import React, { useEffect, useRef, useState } from 'react';
import { Text, StyleSheet, Image, TouchableOpacity, Platform } from 'react-native';
import { observer } from "mobx-react";
import { NavigationContainer, useLinking } from "@react-navigation/native";
import { Stores, useStore } from "../stores";
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from "@react-navigation/drawer";
import MapPage from "../pages/Map.page";
import NewApartmentPage from "../pages/NewItem.page";
import MyItems from '../pages/MyItems.page';
import { INotification, ICalendarEvent } from 'go2c-shared';
import MyCalendarPage from '../pages/MyCalendar.page';
import Loader from './Loader';
import { version } from '../package.json';
import { getStorage } from '../services/utils.service';
import { FunctionComponent } from 'react';
import { ProfileProps } from './profile/Profile.interface';
import ProfilePage from '../pages/profile.page';



const Drawer = createDrawerNavigator();

export interface MapViewParams {

}

export interface NewItemParams {

}

export interface MyItemsParams {
    current_event?: INotification;

}

export interface MyCalendarProps {
    current_event?: ICalendarEvent;
}


export type MainNavigationParams = {
    map_view: MapViewParams;
    new_item: NewItemParams;
    my_items: MyItemsParams;
    my_calendar: MyCalendarProps;
    profile: ProfileProps;
}


function CustomDrawerContent(props: any) {

    return (
        <DrawerContentScrollView {...props}>
            <TouchableOpacity style={styles.navigationHeader} onPress={() => { console.log('header pressed') }}>
                <Image
                    source={require('../assets/featured_image_no_alpha.png')}
                    style={styles.headerLogo}
                />

                <Text style={styles.versionText}>3.4.5</Text>

            </TouchableOpacity>

            <DrawerItemList {...props} />
        </DrawerContentScrollView>
    );
}
const config = {
    screens: {
        map: {
            screens: {
                item: 'item/:item_id',
                map: 'map',
                availability: 'item/:item_id/availability',
                day: 'item/:item_id/availability/day',
                send_invitation: 'item/:item_id/send_invitation',
                accept_meeting: 'item/:item_id/accepet_meeting',
                reschedule_meeting_day: 'item/:item_id/reschedule_meeting_day',
                schedule_at_the_location: 'item/:item_id/schedule_at_the_location',
                video: 'item/:item_id/video/:source'
            }
        },
        new_item: 'new_item',
        my_items: {
            screens: {
                my_items_list: 'my_items/my_items_list',
            }
        },
        my_calendar: 'my_calendar',
        profile: {
            screens: {
                profile_page: 'profile_page',
                email_confirmation: 'email_confirmation/:token'
            }
        },
        
    },
};


const linking = {
    prefixes: ['https://go2c.io', 'go2c://', 'http://localhost:19006'],
    config,
};



const PERSISTENCE_KEY = 'NAVIGATION_STATE';

const Navigation: FunctionComponent = () => {
    const store: Stores = useStore().store;
    const [isReady, setIsReady] = useState(false);
    const [initialState, setInitialState] = useState<any>();
    const ref = useRef();

    const { getInitialState } = useLinking(ref, linking);
    useEffect(() => {
        getInitialState()
            .then(state => {
                if (state !== undefined) {
                    setInitialState(state);
                }

                setIsReady(true);
            });

    }, [getInitialState, setIsReady]);

    if (!isReady) {
        return null;
    }

    return (
        store.user.did_init
            ?
            <NavigationContainer
                // linking={linking}
                ref={ref}
                initialState={initialState}
                onStateChange={(state) => {
                    getStorage().setItem(PERSISTENCE_KEY, JSON.stringify(state))
                }}

            >
                <Drawer.Navigator
                    drawerType={Platform.OS == 'web' ? 'front' : 'slide'}
                    drawerContent={(props: any) => <CustomDrawerContent {...props} slogan={store.translations.init_texts.slogan} />}
                >
                    <Drawer.Screen name='map' component={MapPage} options={{ title: store.translations.translations.find_items }} />
                    <Drawer.Screen name="new_item" component={NewApartmentPage} options={{ title: store.translations.translations.new_item }} />
                    <Drawer.Screen name="my_items" component={MyItems} options={{ title: store.translations.translations.my_items }} />
                    <Drawer.Screen name="my_calendar" component={MyCalendarPage} options={{ title: store.translations.translations.my_calendar }} />
                    <Drawer.Screen name="profile" component={ProfilePage} options={{ title: store.translations.translations.profile }} />

                </Drawer.Navigator>
            </NavigationContainer>
            :

            <Loader />
    )
}

export default observer(Navigation);


const styles = StyleSheet.create({
    navigationHeader: {
        height: 100,
        backgroundColor: 'rgba(224, 239, 255, 0.5)',
        flexDirection: 'row',
    },
    headerLogo: {
        height: 100,
        width: 200,
        marginStart: 15
    },
    slogan: {
        flex: 1,

    },
    nameAndSloganWrapper: {
        height: 100,
    },
    appName: {
        flex: 1,
        fontSize: 22,
        // textAlignVertical: 'bottom'
    },
    versionText: {
        fontSize: 10,
        alignSelf: 'flex-end',
    }
})