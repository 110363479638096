import moment from "moment";
// @ts-ignore
import { PhoneNumberParticles } from 'go2c-shared';
import { Platform } from 'react-native';
import AsyncStorage from "@react-native-async-storage/async-storage";

export function numberWithCommas(x: number): string {
    if (!x) {
        return null;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeCommas(x: string): number | null {
    if (!x) {
        return null;
    }
    return Number(x.replace(/,/g, ''))
}

export const valueToDate = (val: number, date: Date): Date => {
    const hours = Math.floor(val / 4);
    const minutes = (val - (hours * 4)) * 15;
    return moment(date).set({ hours, minutes }).toDate();

};

export const dateToValue = (date: Date) => {
    const hours = Number(moment(date).format('HH'));
    const minutes = Number(moment(date).format('mm'));
    return (hours * 4) + minutes / 15;
}



export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return match[1] + '-' + match[2] + match[3]
    }
    return null
}


export interface ValidatePhoneParams {
    raw_phone: string;
    coutry_code: string;
    area_codes?: string[];
}


export function validatePhone(params: ValidatePhoneParams): PhoneNumberParticles {
    const cleaned: string = ('' + params.raw_phone).replace(/\D/g, '').replace(/[^0-9]/g, '');
    const match: string[] = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    const isAreaCode = match && params.area_codes && params.area_codes.indexOf(match[1]) > -1 ? true : false;
    const valid: boolean = match && isAreaCode;
    let toReturn: PhoneNumberParticles = { cleaned, valid };
    if (match) {
        toReturn.area_code = match[1];
        toReturn.formatted = match[1] + '-' + match[2] + match[3];
    }
    return toReturn;
}


export const getStorage = () => {
    return Platform.OS === 'web' ? localStorage : AsyncStorage;
}

export function randomId(): string {
    let fromLocal = localStorage.getItem('go2c_uuid');
    if (!fromLocal) {
        localStorage.setItem('go2c_uuid', window.crypto.getRandomValues(new Uint32Array(1))[0].toString(16));
    }
    return localStorage.getItem('go2c_uuid')
}