import { faHome, faHouseUser, faMapMarkerExclamation, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { EventArg, RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { FunctionComponent } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import MapView from 'react-native-web-google-maps';
import { CurrentItemNavigationParams } from '../../pages/Map.page';
import { Stores, useStore } from '../../stores';
import Notifications from '../Notifications';
import OpenDrawer from '../OpenDrawer';
import SearchBar from '../SearchBar';
import MarkerCallout from './MarkerCallout';
import markerStyles from "./styles";

type CurrentItemMapNavigationProp = StackNavigationProp<CurrentItemNavigationParams, 'map'>;

type CurrentItemMapRouteProp = RouteProp<CurrentItemNavigationParams, 'map'>;


interface CurrentItemMapParams {
    navigation: CurrentItemMapNavigationProp;
    route: CurrentItemMapRouteProp;
}

const Map: FunctionComponent<CurrentItemMapParams> = (props: CurrentItemMapParams) => {

    const stores: Stores = useStore().store;
    const items = toJS(stores.items.items);
    props.navigation.addListener('focus', (ev: EventArg<"focus", undefined>) => {
        stores.items.clearAvailabilities();
    })
    return (
        <View style={styles.map}>
            <MapView
                initialRegion={{
                    latitude: stores.items.latitude,
                    longitude: stores.items.longitude,
                    latitudeDelta: stores.items.latitude_delta,
                    longitudeDelta: stores.items.longitude_delta,

                }}
                key='AIzaSyAVelI0unlQntDvCyRVypfIgZyh0LU5nPk'

                onRegionChangeComplete={(region: MapView.Region) => {
                    stores.items.setRegion(region);
                }}
            >

                {
                    items.map((item, i) => {
                        return (
                            <MapView.Marker
                                coordinate={{
                                    latitude: item.place.geometry.location.lat,
                                    longitude: item.place.geometry.location.lng
                                }}
                                key={i}
                                title={item.raw_address}
                                description={item.description}
                                onCalloutPress={() => {
                                }}

                            >
                                {/* <MapMarker
                                    item={item}
                                    onCallOutPress={() => {
                                        stores.items.setCurrentItem(item);
                                        props.navigation.navigate('item');
                                    }}
                                    on_marker_press={()=>{
                                        stores.items.setCurrentItemCallout(item);
                                    }}
                                    open={stores.items.current_item_callout && (item._id === stores.items.current_item_callout._id)}
                                /> */}
                                <TouchableOpacity
                                    style={markerStyles.homeMarkerIcon}
                                    onPress={() => {
                                        stores.items.setCurrentItemCallout(item);
                                        stores.items.setLocation(item.place.geometry.location.lat, item.place.geometry.location.lng)

                                    }}

                                >
                                    <View>
                                        <FontAwesomeIcon
                                            icon={faHouseUser}
                                            size={20}
                                            color={'blue'}
                                            style={markerStyles.markerIcon}
                                        />
                                        {
                                            item.available_for_phone &&
                                            <View style={[markerStyles.markerIconWrapper, markerStyles.phoneMarkerIconWrapper]}>
                                                <FontAwesomeIcon
                                                    icon={faPhone}
                                                    size={5}
                                                    color={'#343deb'}
                                                    style={markerStyles.markerIconSmall}
                                                />
                                            </View>

                                        }
                                        {
                                            item.available_for_at_the_location &&
                                            <View style={[markerStyles.markerIconWrapper, markerStyles.locationMarkerIconWrapper]}>
                                                <FontAwesomeIcon
                                                    icon={faMapMarkerExclamation}
                                                    size={5}
                                                    color={'#d4b804'}
                                                    style={markerStyles.markerIconSmall}
                                                />
                                            </View>

                                        }
                                        {
                                            item.available_for_home &&
                                            <View style={[markerStyles.markerIconWrapper, markerStyles.homeMarkerIconWrapper]}>
                                                <FontAwesomeIcon
                                                    icon={faHome}
                                                    size={5}
                                                    color={'#04d43f'}
                                                    style={[markerStyles.markerIconSmall, markerStyles.homeMarkerIcon]}
                                                />
                                            </View>

                                        }
                                    </View>

                                </TouchableOpacity>
                                <MarkerCallout
                                    open={stores.items.current_item_callout && (item._id === stores.items.current_item_callout._id)}
                                    item={item}
                                    onpress={() => {
                                        stores.items.setCurrentItem(item);
                                        props.navigation.navigate('item', { item_id: item._id });
                                    }}
                                />

                            </MapView.Marker>
                        )
                    })
                }
            </MapView>
            <Notifications navigation={props.navigation} />
            <SearchBar navigation={props.navigation} />
            <OpenDrawer navigation={props.navigation} />
        </View>
    )
}

export default observer(Map);


export const styles = StyleSheet.create({
    map: {
        flex: 1,
        zIndex: 1,
        height: '100vh',
        width: '100vw',
    },
});
