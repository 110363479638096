import { observer } from "mobx-react";
import React, { FunctionComponent } from 'react';
import {
    Alert,
    Modal,
    StyleSheet,
    Text,
    View
} from 'react-native';
import MKColor from "../../services/palette.service";
import { Stores, useStore } from '../../stores';
import RippleButton from '../forms/RippleButton';


interface DialogProps {
    app_element?: any;
}

const Dialog: FunctionComponent<DialogProps> = (props: DialogProps) => {
    const store: Stores = useStore().store;

    return (
        <View style={[styles.centeredView, { zIndex: store.dialog.open ? 5 : 0 }]}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={store.dialog.open}

                onRequestClose={() => {
                    Alert.alert("Modal has been closed.");
                }}
            >
                <View style={[styles.centeredView, { zIndex: store.dialog.open ? 5 : 0 }]}>
                    <View style={styles.modalView}>
                        <Text style={styles.title}>{store.dialog.title}</Text>
                        <View style={styles.descriptionContainer}>
                            {
                                store.dialog.description.map((oneDesc: string, i: number) => {
                                    return (
                                        <Text style={styles.descriptionText} key={i}>{oneDesc}</Text>
                                    )
                                })
                            }
                        </View>
                        {/* <Text>{store.dialog.description}</Text> */}
                        <View style={styles.buttonsWrapper}>
                            {
                                store.dialog.okButton &&
                                <RippleButton
                                    onPress={() => { store.dialog.okFunction(); }}
                                    style={styles.button}
                                >
                                    <Text style={styles.buttonsText}>{store.dialog.okButtonText}</Text>
                                </RippleButton>


                            }
                            {
                                <RippleButton
                                    onPress={() => { store.dialog.closeFunction(); }}
                                    style={styles.button}
                                    disabled={false}
                                >
                                    <Text style={styles.buttonsText}>{store.translations.translations.close}</Text>
                                </RippleButton>
                            }

                            {/* <Button
                        title={store.translations.translations.close}
                        onPress={() => { store.dialog.closeFunction(); }}
                    /> */}
                        </View>

                    </View>
                </View>
            </Modal>

        </View>
    )
}

export default observer(Dialog);
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        position: 'absolute',
        // zIndex: 5,
        alignSelf: 'center',
        // textAlignVertical: 'center',
        bottom: 0,
        top: 0,
        // right: 0,
        // left: 0
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5
    },
    openButton: {
        backgroundColor: "#F194FF",
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    button: {
        flex: 1,
        backgroundColor: MKColor.Blue,
        height: 35,
        // margin: 15
    },
    descriptionContainer: {
        marginBottom: 15
    },
    descriptionText: {
        textAlign: 'center',
        fontSize: 22,
    },
    title: {
        fontSize: 26,
        textAlign: 'center',
        marginBottom: 10
    },
    buttonsText: {
        textAlign: 'center',
        fontSize: 24,
        color: 'white'
    }
});