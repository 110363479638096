import * as firebase from 'firebase';
import { Alert } from 'react-native';

import Request from '../services/axios.service';
import { stores } from '../stores';

export const initFirebase = async (): Promise<string | null> => {
    let firebaseToken = null;
    const firebaseConfig = {
        apiKey: "AIzaSyB2yPT2s0qqplV3gQaEUS5sndW_tfdNnFc",
        authDomain: "go2c-f000d.firebaseapp.com",
        databaseURL: "https://go2c-f000d.firebaseio.com",
        projectId: "go2c-f000d",
        storageBucket: "go2c-f000d.appspot.com",
        messagingSenderId: "666575761103",
        appId: "1:666575761103:web:b6cb9fb9ad6532141f91ba",
        measurementId: "G-5PBC269T4M"
    };
    // Initialize Firebase
    firebase.default.initializeApp(firebaseConfig);
    const messaging = firebase.default.messaging();
    try {
        const token = await messaging.getToken({ vapidKey: 'BC4m1avJCQr6YOcy4TM6cxnT7s3vD5d1qG4Nb2B6tzSkN7jWOlhlxUmWS4aErdB6d7D2mtLBfDlwfTOvRAlcuz8' });
        messaging.onMessage(async remoteMessage => {
            const newNotifications = await Request({ method: 'GET', url: 'notifications' });
            stores.user.setNotifications(newNotifications.notifications);
            if (!remoteMessage.notification) {
                remoteMessage = remoteMessage.data;
            }

            // PushNotification.localNotification({
            //     title: remoteMessage.notification.title,
            //     message: remoteMessage.notification.body,
            //     "sound": "default",
            //     "show_in_foreground": true,
            //     "content_available": true,
            //     "priority": "max",
            // });
            stores.dialog.openPopup(remoteMessage.notification.title, remoteMessage.notification.body);
        });

        messaging.onTokenRefresh(async (token: string) => {
            const fromTokenRefresh = await Request({
                method: 'POST',
                url: 'users/firebase_id',
                data: { firebase_id: token }
            });
            console.log('fromTokenRefresh is: ', fromTokenRefresh);
            return fromTokenRefresh;
        });

        // messaging.setBackgroundMessageHandler(async (fromMessage) => {
        //     console.log('fromMessage is: ', fromMessage);
        // })
        // messaging.setBackgroundMessageHandler((payload)=>{
        //     console.log('payload is: ', payload);
        //     alert(JSON.stringify(payload))

        // })
        // messaging.onTokenRefresh((token) => {
        //     console.log('token is: ', token);
        // })
        // messaging.useServiceWorker
        // await messaging.requestPermission();



        // const messaging = firebase.messaging();
        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker.register('../firebase-messaging-sw.js')
        //         .then(function (registration) {
        //             console.log("Service Worker Registered");
        //             messaging.useServiceWorker(registration);
        //         });
        // }
        // const token = await messaging.getToken();
        // return token;
        return token;
        // const messaging = messaging();
        // const enabled = await messaging.hasPermission();
        // let firebaseToken: string;
        // if (enabled) {
        //     firebaseToken = await messaging.getToken();
        //     if (!firebaseToken) {
        //         await messaging.requestPermission();
        //         await messaging.hasPermission();
        //         firebaseToken = await messaging.getToken();
        //     }

        // } 
        // // else {
        // //     await messaging.requestPermission();
        // //     await messaging.hasPermission();
        // //     firebaseToken = await messaging.getToken();
        // //     if (!firebaseToken) {
        // //         await messaging.requestPermission();
        // //         await messaging.hasPermission();

        // //         firebaseToken = await messaging.getToken();
        // //     }

        // // }

        // if (!firebaseToken) {
        //     // throw 'No firebase token provided';
        //     return null;
        // } else {

        //     stores.user.setFirebaseId(firebaseToken);
        //     messaging.onMessage(async remoteMessage => {
        //         // Alert.alert('A new FCM message arrived!', JSON.stringify(remoteMessage));
        //         const newNotifications = await Request({ method: 'GET', url: 'notifications' });
        //         stores.user.setNotifications(newNotifications.notifications);
        //         if (!remoteMessage.notification) {
        //             remoteMessage = remoteMessage.data;
        //         }
        //         PushNotification.localNotification({
        //             title: remoteMessage.notification.title,
        //             message: remoteMessage.notification.body,
        //             "sound": "default",
        //             "show_in_foreground": true,
        //             "content_available": true,
        //             "priority": "max",
        //         });
        //         stores.dialog.openPopup(remoteMessage.notification.title, remoteMessage.notification.body);
        //     });

        //     messaging.onTokenRefresh(async (token: string) => {
        //         console.log('token on onTokenRefresh is: ', token);
        //         const fromTokenRefresh = await Request({
        //             method: 'POST',
        //             url: 'users/firebase_id',
        //             data: { firebase_id: token }
        //         });
        //         console.log('fromTokenRefresh is: ', fromTokenRefresh);
        //     });

        //     messaging.setBackgroundMessageHandler(async (fromMessage) => {
        //         console.log('fromMessage is: ', fromMessage);
        //     })
        //     return firebaseToken;
        // }

    } catch (error) {
        console.log('error is: ', error);
        return null;
    }

}